<template>
  <div class="rounded-md bg-blue-50 p-4 flex">
    <div class="flex-shrink-0">
      <svg class="mt-0.5 h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
      </svg>
    </div>

    <div class="ml-3 flex-1 md:flex md:justify-between">
      <div>
        Your <b>public</b> search page address:
        <a :href="publicPageLink" class="text-gray-500 dark:text-gray-400 font-mono" target="_blank">
          <span class="underline">{{ publicPageLink }}</span><ph-arrow-square-out class="size-4 inline ml-1" />
        </a>
        <span class="cursor-pointer underline decoration-dashed ml-5 text-slate-500" @click.prevent="copy">Copy to clipboard</span>
      </div>
      <p class="mt-3 text-sm md:ml-6 md:mt-0">
        <router-link to="/design" class="btn-outline text-xs">Edit page design</router-link>
      </p>
    </div>
  </div>
</template>

<script setup>
import { PhArrowSquareOut } from '@phosphor-icons/vue';
import { publicPageLink } from '../useDesign';
import { $success } from '../notify';

function copy() {
  navigator.clipboard.writeText(publicPageLink.value);
  $success("Public link copied to clipboard.");
}
</script>
