import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { plugin, defaultConfig } from '@formkit/vue';
import { generateClasses } from '@formkit/tailwindcss';
import theme from './formkit-theme';
import { configureApi } from './useApi';
import { $error } from './notify';
import './useDark';
import { clerkPlugin, clerkInit } from './clerk';

import './assets/css/main.scss';

async function init() {
  await clerkInit({
    publishableKey: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
  });

  configureApi({
    prefix: '',

    onException: () => {
      $error("Request failed", "Couldn't perform request. Try again later.");
    },

    onNoSuccess: json => {
      $error("Failed", json.error || "Something is wrong.");
    },

    onServerError: response => {
      $error("Server-side error", "Couldn't perform request. Try again later.");
    },

    onAuthRequired: response => {
      $error("Auth required", "Please sign in to FactsLove to use it.");
    }
  });
}

const app = createApp(App)
  .use(
    plugin,
    defaultConfig({
      config: {
        classes: generateClasses(theme)
      }
    })
  )
  .directive('focus', {
    mounted(el) {
      if (el.classList.contains('formkit-outer')) {
        el.querySelector('input, textarea')?.focus();
        return;
      }
      el.focus();
    }
  });

await init();

app
  .use(clerkPlugin)
  .use(router())
  .mount('#app');
