<template>
  <div class="flex flex-col min-h-full">
    <the-header :page />

    <div class="py-10 grow container">
      <search-form ref="searchFormRef" :disabled :placeholder="page.searchPlaceholder" @submit="submit" @reset="reset" />

      <div v-if="!isLoading && isInitialState" class="mt-10">
        <div v-if="page.textTop" class="mt-10 text-sm" v-html="page.textTop" />

        <ul v-if="page.sampleQueries?.length" class="mt-4 mb-8">
          <li
            v-for="(query, key) in page.sampleQueries"
            :key="key"
            class="pl-8 mb-2 cursor-pointer underline decoration-dashed"
            @click="searchFormRef.searchByExample(query)"
          >
            {{ query }}
          </li>
        </ul>

        <div v-else-if="page.sampleQueriesStatic" class="mt-4 mb-8 pl-8 cursor-pointer underline decoration-dashed whitespace-pre" v-html="page.sampleQueriesStatic" />

        <div v-if="page.textBottom" class="mt-4 text-sm" v-html="page.textBottom" />
      </div>
      <slot />
    </div>
    <the-footer :page />
  </div>
</template>

<script setup>
import { shallowRef, ref } from 'vue';
import TheHeader from './TheHeader.vue';
import TheFooter from './TheFooter.vue';
import SearchForm from './SearchForm.vue';

defineProps({
  page: {
    type: Object,
    default: () => ({})
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

const searchFormRef = ref(null);

const emit = defineEmits([ 'search', 'reset' ]);

const isInitialState = shallowRef(true);

function reset() {
  emit('reset');
  isInitialState.value = true;
}

function submit(query) {
  isInitialState.value = false;
  emit('search', query);
}
</script>
