<template>
  <div class="mt-1">
    <button class="btn-primary" @click="router.push('/add/')">
      Add new video
    </button>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
</script>
