export function hexColorToRGB(str) {
  return str.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))
    .join(', ');
}

export function rgbColorToHex(str) {
  return '#' + str
    .split(', ')
    .map(x => {
      const hex = parseInt(x, 10).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    })
    .join('');
}
