<template>
  <div class="relative">
    <button
      type="button"
      class="absolute right-0 -top-10 btn-outline -mt-1.5 p-2"
      @click="arrayAdd"
    >
      <ph-plus-circle class="size-6" />
    </button>

    <div class="flex flex-col mt-5 space-y-1">
      <div v-for="(item, index) in textArray" :key="index" class="flex space-x-2">
        <input
          :value="item"
          class="grow formkit-inner border border-slate-300 shadow-sm formkit-invalid:border-red-500 rounded-md overflow-hidden focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500 formkit-invalid:focus-within:ring-red-500/50"
          @input="arrayUpdate($event, index)"
        >
        <button
          type="button"
          class="btn-outline px-2"
          @click="arrayRemove(index)"
        >
          <ph-minus-circle class="size-6" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { PhPlusCircle, PhMinusCircle } from '@phosphor-icons/vue';

const props = defineProps({
  context: {
    type: Object,
    required: true
  }
});

const textArray = computed({
  get: () => props.context._value,
  set: value => {
    props.context.node.input(value);
  }
});

function arrayUpdate(event, index) {
  const _textArray = [ ...textArray.value ];
  _textArray[index] = event.target.value;
  textArray.value = [ ..._textArray ];
}

function arrayRemove(index) {
  const _textArray = [ ...textArray.value ];
  _textArray.splice(index, 1);
  textArray.value = [ ..._textArray ];
}

function arrayAdd() {
  textArray.value = [ ...textArray.value, '' ];
}
</script>
