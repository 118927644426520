// see also Video.model.mjs
export const VIDEO_STATUS_PENDING = 'pending';
export const VIDEO_STATUS_ERROR = 'error';
export const VIDEO_STATUS_ONLINE = 'online';

export const VIDEO_STATUS_DOWNLOADING = 'downloading';
export const VIDEO_STATUS_DOWNLOADED = 'downloaded';
export const VIDEO_STATUS_CONVERTING = 'converting';
export const VIDEO_STATUS_CONVERTED = 'converted';
export const VIDEO_STATUS_TRANSCRIBING = 'transcribing';
export const VIDEO_STATUS_TRANSCRIBED = 'transcribed';
export const VIDEO_STATUS_INDEXING = 'indexing';
