<template>
  <div>
    <FormKit
      type="form"
      submit-label="Proceed"
      :config="{ validationVisibility: 'submit' }"
      :classes="{ actions: 'text-right' }"
      @submit="submit"
    >
      <FormKit
        v-model="url"
        v-focus
        type="url"
        name="url"
        placeholder="https://..."
        validation="required|url"
        help="Paste your YouTube or Vimeo link. The video has to be public or unlisted."
      />
    </FormKit>
  </div>
</template>

<script setup>
import { shallowRef } from 'vue';
import { post } from '../useApi';

const emit = defineEmits([ 'nextStep' ]);

const url = shallowRef('');

async function submit() {
  const result = await post('/api/video/step1', { url: url.value });
  if (!result?.success) {
    return;
  }

  const videoMeta = {
    provider: result.provider,
    serviceVideoId: result.serviceVideoId,
    title: result.title,
    description: result.description,
    language: result.language,
    thumbnailUrl: result.thumbnailUrl,
    durationSeconds: result.durationSeconds
  };

  emit('nextStep', { url: url.value, videoMeta });
}
</script>
