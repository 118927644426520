<template>
  <div>
    <page-header title="Customize your design">
      <button class="btn-primary mr-2" @click="submit">Save</button>
      <button class="btn-outline" @click="router.push('/')">Cancel</button>
    </page-header>

    <div v-if="isEmpty" class="flex flex-col space-y-10 sm:flex-row sm:space-x-10 sm:space-y-0">
      No videos yet. Please upload a video first.
    </div>

    <div v-else class="flex flex-col space-y-10 sm:flex-row sm:space-x-10 sm:space-y-0">
      <div class="sm:w-1/2">
        <FormKit
          type="form"
          submit-label="Save and return"
          :config="{ validationVisibility: 'submit' }"
          :classes="{ actions: 'text-right' }"
          :disabled="isLoading || isUploading"
          @submit="submit"
          :submit-attrs="{
            inputClass: 'hidden'
          }"
        >
          <FormKit
            v-model="title"
            type="text"
            label="Title"
            help="Pick a title people will remember!"
          />

          <FormKit
            v-model="description"
            type="textarea"
            label="Description"
            help="Describe what and who this page is for in a few words"
          />

          <template v-if="isUploading">
            <div class="text-center">
              uploading...
            </div>
          </template>

          <div v-else class="flex flex-col md:flex-row md:space-x-5">
            <div class="mb-5 w-full">
              <label class="formkit-label text-sm font-medium text-slate-700 block mb-1">Header image</label>
              <div class="formkit-inner block border border-slate-300 rounded-md p-2 cursor-pointer relative">
                <input
                  type="file"
                  data-what="header"
                  accept="image/jpeg,image/png,image/webp"
                  class="formkit-input appearance-none selection:text-neutral-700 group-data-[has-overlay]:selection:!text-transparent text-base text-neutral-700 min-w-0 min-h-[1.5em] grow outline-none bg-transparent selection:bg-blue-100 placeholder:text-neutral-400 group-data-[disabled]:!cursor-not-allowed dark:placeholder-neutral-400/50 dark:text-neutral-300 border-none p-0 focus:ring-0 cursor-pointer text-transparent absolute inset-0 opacity-0 z-10 file:pointer-events-none file:w-0 file:h-0 file:overflow-hidden"
                  @change="upload"
                >
                <span class="flex w-full items-center text-neutral-400 dark:text-neutral-500">
                  <span class="w-[1em] mr-2 formkit-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16"><path d="M12.5,16H2.5c-.83,0-1.5-.67-1.5-1.5V1.5c0-.83,.67-1.5,1.5-1.5h7.09c.4,0,.78,.16,1.06,.44l2.91,2.91c.28,.28,.44,.66,.44,1.06V14.5c0,.83-.67,1.5-1.5,1.5ZM2.5,1c-.28,0-.5,.22-.5,.5V14.5c0,.28,.22,.5,.5,.5H12.5c.28,0,.5-.22,.5-.5V4.41c0-.13-.05-.26-.15-.35l-2.91-2.91c-.09-.09-.22-.15-.35-.15H2.5Z" fill="currentColor" /><path d="M13.38,5h-2.91c-.81,0-1.47-.66-1.47-1.47V.62c0-.28,.22-.5,.5-.5s.5,.22,.5,.5V3.53c0,.26,.21,.47,.47,.47h2.91c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" fill="currentColor" /><path d="M10,13H5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h5c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" fill="currentColor" /><path d="M10,10H5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h5c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" fill="currentColor" /><path d="M7,7h-2c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h2c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" fill="currentColor" /></svg>
                  </span>
                  <template v-if="headerImageUrl">
                    Change selected image
                  </template>
                  <template v-else>
                    No file chosen
                  </template>
                </span>
              </div>
              <div class="formkit-help mt-2 text-sm text-slate-500">
                Header image must be jpeg, png or webp strictly less than {{ HEADER_SIZE_MB }}MB and {{ HEADER_WIDTH }}x{{ HEADER_HEIGHT }}px exactly
              </div>
            </div>

            <div class="mb-5 w-full">
              <label class="formkit-label text-sm font-medium text-slate-700 block mb-1">Logo image</label>
              <div class="formkit-inner block border border-slate-300 rounded-md p-2 cursor-pointer relative">
                <input
                  type="file"
                  data-what="logo"
                  accept="image/jpeg,image/png,image/webp"
                  class="formkit-input appearance-none selection:text-neutral-700 group-data-[has-overlay]:selection:!text-transparent text-base text-neutral-700 min-w-0 min-h-[1.5em] grow outline-none bg-transparent selection:bg-blue-100 placeholder:text-neutral-400 group-data-[disabled]:!cursor-not-allowed dark:placeholder-neutral-400/50 dark:text-neutral-300 border-none p-0 focus:ring-0 cursor-pointer text-transparent absolute inset-0 opacity-0 z-10 file:pointer-events-none file:w-0 file:h-0 file:overflow-hidden"
                  @change="upload"
                >
                <span class="flex w-full items-center text-neutral-400 dark:text-neutral-500">
                  <span class="w-[1em] mr-2 formkit-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16"><path d="M12.5,16H2.5c-.83,0-1.5-.67-1.5-1.5V1.5c0-.83,.67-1.5,1.5-1.5h7.09c.4,0,.78,.16,1.06,.44l2.91,2.91c.28,.28,.44,.66,.44,1.06V14.5c0,.83-.67,1.5-1.5,1.5ZM2.5,1c-.28,0-.5,.22-.5,.5V14.5c0,.28,.22,.5,.5,.5H12.5c.28,0,.5-.22,.5-.5V4.41c0-.13-.05-.26-.15-.35l-2.91-2.91c-.09-.09-.22-.15-.35-.15H2.5Z" fill="currentColor" /><path d="M13.38,5h-2.91c-.81,0-1.47-.66-1.47-1.47V.62c0-.28,.22-.5,.5-.5s.5,.22,.5,.5V3.53c0,.26,.21,.47,.47,.47h2.91c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" fill="currentColor" /><path d="M10,13H5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h5c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" fill="currentColor" /><path d="M10,10H5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h5c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" fill="currentColor" /><path d="M7,7h-2c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h2c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" fill="currentColor" /></svg>
                  </span>
                  <template v-if="logoImageUrl">
                    Change selected image
                  </template>
                  <template v-else>
                    No file chosen
                  </template>
                </span>
              </div>
              <div class="formkit-help mt-2 text-sm text-slate-500">
                Logo image must be jpeg, png or webp strictly less than {{ LOGO_SIZE_MB }}MB and {{ LOGO_WIDTH }}x{{ LOGO_HEIGHT }}px exactly
              </div>
            </div>
          </div>

          <div class="flex flex-col md:flex-row md:space-x-5">
            <FormKit
              v-model="colorRgbHeader"
              :classes="{ outer: 'w-full' }"
              type="color"
              label="Header color"
              help="Select your header color"
            />

            <FormKit
              v-model="colorRgbPrimary"
              :classes="{ outer: 'w-full' }"
              type="color"
              label="Primary color"
              help="Select your page main color"
            />
          </div>

          <div class="flex flex-col md:flex-row md:space-x-5">
            <FormKit
              v-model="colorRgbBackground"
              :classes="{ outer: 'w-full' }"
              type="color"
              label="Background color"
              help="Select your page background color"
            />

            <FormKit
              v-model="colorRgbText"
              :classes="{ outer: 'w-full' }"
              type="color"
              label="Text color"
              help="Select your page text color"
            />
          </div>

          <FormKit
            v-model="textTop"
            :classes="{ outer: 'w-full' }"
            type="textarea"
            label="Top text"
            help="Input the things people are likely to search for in your videos"
          />

          <FormKit
            v-model="sampleQueries"
            :classes="{ outer: 'w-full' }"
            :type="textArray"
            label="Sample queries"
            help="Type something clever here"
          />

          <FormKit
            v-model="textBottom"
            :classes="{ outer: 'w-full' }"
            type="textarea"
            label="Bottom text"
            help="Type something clever here"
          />

          <FormKit
            v-model="textFooter"
            :classes="{ outer: 'w-full' }"
            type="text"
            label="Footer text"
            help="Type something clever here"
          />

          <FormKit
            v-model="searchPlaceholder"
            :classes="{ outer: 'w-full' }"
            type="text"
            label="Search input placeholder"
            help="Ask your question here, would you?"
          />

          <FormKit
            v-model="email"
            :classes="{ outer: 'w-full' }"
            type="email"
            label="Footer email"
            help="Your email goes here. Leave blank if you don't want to use it."
          />
        </FormKit>
      </div>

      <div class="sm:w-1/2 relative min-h-[100vw]">
        <div class="w-[200%] absolute top-0 right-0 scale-50 origin-top-right">
          <div class="mt-2 mb-4">
            <label class="formkit-label text-sm font-medium text-slate-700 block mb-1 scale-[2] origin-left">Preview</label>
          </div>
          <div class="overflow-hidden border border-slate-400 rounded-lg bg-app-background text-app-text" :style="`--color-header: ${headerColor};--color-main: ${mainColor};--color-background: ${backgroundColor};--color-text: ${textColor};--tw-text-opacity: 1;`">
            <search-component :page="previewPage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { shallowRef, computed } from 'vue';
import { useRouter } from 'vue-router';
import PageHeader from '../components/PageHeader.vue';
import SearchComponent from '../../../frontend-public-search/src/components/SearchComponent.vue';
import FormkitTextArray from '../components/FormkitTextArray.vue';
import { createInput } from '@formkit/vue';
import { post } from '../useApi';
import { $success, $error } from '../notify';
import { marked } from 'marked';
import { hexColorToRGB, rgbColorToHex } from '../colorTools';
import {
  isEmpty,

  saveDesign,

  title,
  description,
  mainColor,
  headerColor,
  backgroundColor,
  textColor,
  headerImageKey,
  headerImageUrl,
  logoImageKey,
  logoImageUrl,
  textTop,
  textBottom,
  textFooter,
  searchPlaceholder,
  email,
  sampleQueries
} from '../useDesign';

// FIXME validate all fields

const router = useRouter();

const HEADER_SIZE_MB = 2;
const HEADER_WIDTH = 1920;
const HEADER_HEIGHT = 1080;

const LOGO_SIZE_MB = 2;
const LOGO_WIDTH = 512;
const LOGO_HEIGHT = 512;

const isUploading = shallowRef(false);
const isLoading = shallowRef(false);

const previewPage = computed(() => ({
  title: title.value,
  description: marked(description.value),
  mainColor: mainColor.value,
  headerColor: headerColor.value,
  backgroundColor: backgroundColor.value,
  textColor: textColor.value,
  headerImageUrl: headerImageUrl.value,
  logoImageUrl: logoImageUrl.value,
  textTop: marked(textTop.value),
  textBottom: marked(textBottom.value),
  textFooter: textFooter.value,
  searchPlaceholder: searchPlaceholder.value,
  email: email.value,
  sampleQueries: sampleQueries.value
}));

const textArray = createInput(FormkitTextArray);

const colorRgbHeader = computed({
  get: () => rgbColorToHex(headerColor.value),
  set: value => headerColor.value = hexColorToRGB(value)
});

const colorRgbPrimary = computed({
  get: () => rgbColorToHex(mainColor.value),
  set: value => mainColor.value = hexColorToRGB(value)
});

const colorRgbBackground = computed({
  get: () => rgbColorToHex(backgroundColor.value),
  set: value => backgroundColor.value = hexColorToRGB(value)
});

const colorRgbText = computed({
  get: () => rgbColorToHex(textColor.value),
  set: value => textColor.value = hexColorToRGB(value)
});

/*
async function toggleEnabled() {
  isLoading.value = true;

  const result = await post('/api/settings/state', {
    isEnabled: !isEnabled.value
  });

  isLoading.value = false;

  if (!result?.success) {
    $error("Error saving state", result.error);
    return;
  }

  isEnabled.value = !isEnabled.value;

  $success(isEnabled.value ? 'Page published' : 'Page unpublished');
}
*/

async function submit() {
  isLoading.value = true;

  const result = await saveDesign();

  isLoading.value = false;

  if (result) {
    $error("Error saving settings", result);
    return;
  }

  $success('Settings saved');

  router.push('/');
}

function checkImageSize(file, exactWidth, exactHeight) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = () => resolve(image.height === exactHeight && image.width === exactWidth);
    };
  });
}

async function upload(e) {
  const what = e.target.getAttribute('data-what');

  let maxImageSizeMb;
  let exactWidth;
  let exactHeight;

  if (what === 'header') {
    maxImageSizeMb = HEADER_SIZE_MB;
    exactWidth = HEADER_WIDTH;
    exactHeight = HEADER_HEIGHT;

  } else { // logo
    maxImageSizeMb = LOGO_SIZE_MB;
    exactWidth = LOGO_WIDTH;
    exactHeight = LOGO_HEIGHT;
  }

  const file = e.target.files?.[0];
  if (!file) {
    isUploading.value = false;
    return;
  }

  if (file.size > maxImageSizeMb * 1024 * 1024) {
    $error('file is too big');
    return;
  }

  const isImageSizeOk = await checkImageSize(file, exactWidth, exactHeight);
  if (!isImageSizeOk) {
    e.target.value = '';
    $error(`image must be ${exactWidth}x${exactHeight}px exactly`);
    return;
  }

  isUploading.value = true;

  const json = await post('/api/settings/upload', {
    mimetype: file.type
  });

  const response = await fetch(json.url, {
    method: 'PUT',
    body: file
  });

  if (!response.ok) {
    $error('error uploading image');
    isUploading.value = false;
    return;
  }

  const u = new URL(json.url);
  u.search = '';

  if (what === 'header') {
    headerImageKey.value = json.key;
    headerImageUrl.value = u;

  } else {
    logoImageKey.value = json.key;
    logoImageUrl.value = u;
  }

  isUploading.value = false;
}
</script>
