import { Clerk } from '@clerk/clerk-js';
import { clerkPlugin as _clerkPlugin } from 'vue-clerk';

let _clerk;

export async function clerkInit(options) {
  const { publishableKey, ...loadOptions } = options;
  _clerk = new Clerk(publishableKey, loadOptions);
  await _clerk.load(loadOptions);

  return _clerk;
}

export async function getToken() {
  return await _clerk?.session?.getToken();
}

export function isAuthenticated() {
  // NOTE: possible _clerk.user, but not _clerk.user.id ???
  return _clerk?.session?.id && _clerk.user?.id;
}

export const clerkPlugin = {
  install(app) {
    _clerkPlugin.install(app, { Clerk: _clerk });
  }
};
