import { ref, shallowRef } from 'vue';
import { get } from './useApi';
import { VIDEO_STATUS_ERROR, VIDEO_STATUS_ONLINE } from './videoStatusConstants';
import { Duration } from 'luxon';

export const list = ref(null);
export const totalDurationSeconds = shallowRef(0);

export async function getVideoList() {
  const result = await get('/api/video/', { });

  if (!result) {
    return;
  }

  let _totalDurationSeconds = 0;
  result.rows.forEach(row => {
    row.isEnabled = row.status === VIDEO_STATUS_ONLINE || row.status === VIDEO_STATUS_ERROR;

    if (!row.isEnabled) {
      _totalDurationSeconds += row.durationSeconds;
    }

    const d = Duration.fromMillis(row.durationSeconds * 1000);
    if (row.durationSeconds > 3600) {
      row.durationHr = d.toFormat('hh:mm:ss');
    } else {
      row.durationHr = d.toFormat('mm:ss');
    }
  });

  list.value = result.rows;

  totalDurationSeconds.value = _totalDurationSeconds / 10; // whisper on OpenAI works approx ten times faster than realtime
}
