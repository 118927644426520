<template>
  <form @submit.prevent="submit">
    <label for="search" class="sr-only">Search</label>

    <div class="relative w-full">
      <div class="absolute inset-y-0 left-0 flex items-center ml-3 mr-1 z-10" :class="search ? 'cursor-pointer' : 'pointer-events-none'" @click="reset">
        <div class="w-4 h-4 relative">
          <svg
            class="absolute inset-0 text-gray-400 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white transition-opacity"
            :class="search ? 'opacity-100' : 'opacity-0'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          ><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z" fill="currentColor" /></svg>
          <svg
            class="absolute inset-0 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white transition-opacity"
            :class="search ? 'opacity-0' : 'opacity-100'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          ><path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z" fill="currentColor" /></svg>
        </div>
      </div>
      <input
        id="search"
        v-model.trim="search"
        type="text"
        :disabled
        class="bg-app-text/10 border border-app-text/20 text-sm rounded-lg focus:ring-main focus:outline-none focus:border-main block w-full pl-9 p-2.5 pr-32 placeholder-black/10"
        :placeholder
        autocomplete="off"
        required
      >
      <div class="absolute inset-y-1 right-1 flex items-center mr-[-1px]">
        <button type="submit" :disabled="disabled || !search" class="text-white bg-main hover:brightness-[90%] focus:ring-4 focus:outline-none focus:ring-main/50 font-medium rounded-lg text-sm px-4 py-2 cursor-pointer">
          Search
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue';

defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    required: true
  }
});

const emit = defineEmits([ 'submit', 'reset' ]);

const search = ref('');

function submit() {
  if (!search.value) {
    return;
  }

  emit('submit', search.value);
}

function reset() {
  search.value = '';
  emit('reset');
}

function searchByExample(query) {
  search.value = query;
  emit('submit', search.value);
}

defineExpose({
  searchByExample
});
</script>
