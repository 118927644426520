import { ref, shallowRef, computed } from 'vue';
import { get, post } from './useApi';

export const isEmpty = shallowRef(false);

export const isEnabled = shallowRef(false);
export const title = shallowRef('');
export const slug = shallowRef('');
export const description = shallowRef('');
export const mainColor = shallowRef(null);
export const headerColor = shallowRef(null);
export const backgroundColor = shallowRef(null);
export const textColor = shallowRef(null);
export const headerImageKey = shallowRef(null);
export const headerImageUrl = shallowRef(null);
export const logoImageKey = shallowRef(null);
export const logoImageUrl = shallowRef(null);
export const textTop = shallowRef('');
export const textBottom = shallowRef('');
export const textFooter = shallowRef('');
export const searchPlaceholder = shallowRef('');
export const email = shallowRef('');
export const sampleQueries = ref([]);

export const publicPageLink = computed(() => (slug.value ? `${import.meta.env.VITE_PUBLIC_URL_PREFIX}${slug.value}` : null));

function setPageFromResult(page) {
  isEnabled.value = page.isEnabled;
  title.value = page.title;
  slug.value = page.slug;
  description.value = page.description;
  mainColor.value = page.mainColor;
  headerColor.value = page.headerColor;
  backgroundColor.value = page.backgroundColor;
  textColor.value = page.textColor;
  headerImageKey.value = page.headerImageKey;
  headerImageUrl.value = page.headerImageUrl;
  logoImageKey.value = page.logoImageKey;
  logoImageUrl.value = page.logoImageUrl;
  textTop.value = page.textTop;
  textBottom.value = page.textBottom;
  textFooter.value = page.textFooter;
  searchPlaceholder.value = page.searchPlaceholder;
  email.value = page.email;
  sampleQueries.value = page.sampleQueries;
}

export async function getDesign() {
  const result = await get('/api/settings');

  if (!result?.success) {
    return result?.error || '(unknown error)';
  }

  if (result.isEmpty) {
    isEmpty.value = true;
    return null;
  }

  setPageFromResult(result.page);

  return null;
}

export async function saveDesign() {
  // slug and isEnabled are set elsewhere

  const result = await post('/api/settings', {
    title: title.value,
    description: description.value,
    mainColor: mainColor.value,
    headerColor: headerColor.value,
    backgroundColor: backgroundColor.value,
    textColor: textColor.value,
    headerImageKey: headerImageKey.value,
    logoImageKey: logoImageKey.value,
    textTop: textTop.value,
    textBottom: textBottom.value,
    textFooter: textFooter.value,
    searchPlaceholder: searchPlaceholder.value,
    email: email.value,
    sampleQueries: sampleQueries.value
  });

  if (!result?.success) {
    return result.error;
  }

  setPageFromResult(result.page);

  return null;
}
