import { createMemoryHistory, createRouter } from 'vue-router';
import { isAuthenticated } from './clerk';
import MainPage from './pages/MainPage.vue';
import VideoListPage from './pages/VideoListPage.vue';
import VideoAddPage from './pages/VideoAddPage.vue';
import DesignPage from './pages/DesignPage.vue';
import ErrorPage from './pages/ErrorPage.vue';

function createRoutes() {
  let routes;

  if (isAuthenticated()) {
    routes = [
      {
        path: '/',
        component: VideoListPage,
        name: 'videoListPage'
      },

      {
        path: '/add/',
        component: VideoAddPage,
        name: 'videoAddPage'
      },

      {
        path: '/design/',
        // component: DesignPage,
        // name: 'designPage',
        children: [
          {
            path: '',
            component: DesignPage,
            name: 'designPage'
          }
        ]
      },
      {
        path: '/:catchAll(.*)',
        name: 'ErrorPage',
        component: ErrorPage
      }
    ];

  } else {
    routes = [
      {
        path: '/',
        component: MainPage,
        name: 'mainPage'
      },

      {
        path: '/:catchAll(.*)',
        name: 'ErrorPage',
        component: ErrorPage
      }
    ];
  }

  return routes;
}

const router = () => createRouter({
  history: createMemoryHistory(),
  routes: createRoutes(),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  }
});

export default router;
