<template>
  <div>
    <video-card :metadata="videoMeta" :url="url" />

    <div class="mt-4">
      <FormKit
        type="form"
        submit-label="Add video"
        :config="{ validationVisibility: 'submit' }"
        :classes="{ actions: 'text-right' }"
        @submit="submit"
      >
        <FormKit
          v-model="prompt"
          v-focus
          type="textarea"
          name="Prompt"
          placeholder="This is a lecture about the mulecular cuisine and high GeV proton diet at CERN's LHC experiments including MOEDAL, TOTEM, FASER and ALICE."
          help="Help us transcribe the video better. Explain the content in a few sentences including terms, acronyms and names."
        />
        <div class="text-sm text-slate-500 -mt-2 mb-2">
          Prompts can be very helpful for correcting specific words or acronyms that the model may misrecognize in the audio.
          The model will try to match the style of the prompt, so it will be more likely to use capitalization and punctuation
          if the prompt does too. This is an optional step.
        </div>

        <div class="rounded-md bg-yellow-50 p-4 mt-5 mb-5" v-if="isLongVideo">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-yellow-800">Note</h3>
              <div class="mt-2 text-sm text-yellow-700">
                <p>
                  Due to temporary technical limitations, only the first hour of the video will be available for search. This limitation will be removed soon.
                </p>
              </div>
            </div>
          </div>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script setup>
import { computed, shallowRef } from 'vue';
import { post } from '../useApi';
import VideoCard from './VideoCard.vue';

const props = defineProps({
  url: {
    type: String,
    required: true
  },
  videoMeta: {
    type: Object,
    required: true
  }
});

const isLongVideo = computed(() => props.videoMeta?.durationSeconds > 3600);

const emit = defineEmits([ 'next-step' ]);

const prompt = shallowRef('');

async function submit() {
  const result = await post('/api/video/step2', {
    url: props.url,
    prompt: prompt.value
  });

  if (result?.isPromptTooLong) {
    return;
  }

  if (!result?.success) {
    return;
  }

  emit('next-step', true);
}
</script>
