<template>
  <div class="rounded-md bg-slate-50 p-8">
    <div class="flex">
      <div class="space-y-3">
        <template v-if="step === 1">
          <wizard-widget-faq-item
            :step="1"
            title="Add at least one video to your account."
            subtitle="It will begin processing immediately."
          />

          <wizard-widget-faq-item
            :is-future="true"
            :step="2"
            title="Processing your videos."
            subtitle="The waiting time depends on the video."
          />

          <wizard-widget-faq-item
            :is-future="true"
            :step="3"
            title="Your public search page is published."
            subtitle="And your videos are available for search."
          />
        </template>

        <template v-else-if="step === 2">
          <wizard-widget-faq-item
            :step="1"
            title="Add at least one video to your account."
            subtitle="Well done."
          />

          <wizard-widget-faq-item
            :step="2"
            title="Processing your videos."
            subtitle="You can close this time or navigate away while it lasts."
          />

          <wizard-widget-faq-item
            :is-future="true"
            :step="3"
            title="Your public search page is published."
            subtitle="And your videos are available for search."
          />
        </template>

        <template v-else-if="step === 3">
          <wizard-widget-faq-item
            :step="1"
            title="Add at least one video to your account."
            subtitle="Well done."
          />

          <wizard-widget-faq-item
            :step="2"
            title="Processed your videos."
            subtitle="Excellent!"
          />

          <wizard-widget-faq-item
            :step="3"
            title="Your public search page is published."
            subtitle="And your videos are available for search. Congratulations!"
          />
        </template>
      </div>

      <div class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5" @click="emit('dismiss')">
          <button type="button" class="inline-flex rounded-md bg-slate-200 p-1.5 text-slate-500 hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-slate-600 focus:ring-offset-2 focus:ring-offset-slate-50">
            <span class="sr-only">Dismiss</span>
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import WizardWidgetFaqItem from './WizardWidgetFaqItem.vue';

const props = defineProps({
  isProcessingSingleVideo: {
    type: Boolean,
    required: true
  },
  isSingleVideoOnline: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits([ 'dismiss' ]);

const step = computed(() => {
  if (!props.isProcessingSingleVideo && !props.isSingleVideoOnline) {
    return 1;
  }

  if (props.isProcessingSingleVideo && !props.isSingleVideoOnline) {
    return 2;
  }

  if (props.isSingleVideoOnline) {
    return 3;
  }
});
</script>
