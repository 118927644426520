<template>
  <header class="relative z-1 w-full flex-none text-sm font-semibold leading-6 bg-white text-slate-900">
    <nav aria-label="Global" class="container">
      <div class="relative flex items-center py-[2.125rem] gap-x-4">
        <div class="absolute inset-x-0 bottom-0 h-px bg-slate-900/5" />

        <router-link to="/" class="mr-auto flex-none text-slate-900 relative">
          <the-logo />
        </router-link>

        <div v-if="isSignedIn" class="hidden lg:flex lg:items-center">
          <!--
          <router-link to="/" class="block rounded-md py-2 px-4 mr-4 text-sm text-slate-700" active-class="bg-slate-100">
            Library
          </router-link>

          <router-link to="/design/" class="block rounded-md py-2 px-4 text-sm text-slate-700" active-class="bg-slate-100">
            Design
          </router-link>
          -->
        </div>

        <the-theme-toggler v-if="false" class="mx-4" />

        <UserButton v-if="isSignedIn" after-sign-out-url="/" />

        <SignInButton v-else v-slot="{ handler }" mode="modal">
          <button class="btn-primary" @click="handler">
            Sign In
          </button>
        </SignInButton>
      </div>
    </nav>
  </header>
</template>

<script setup>
import TheLogo from './TheLogo.vue';
import TheThemeToggler from './TheThemeToggler.vue';
import { SignInButton, UserButton, useUser } from 'vue-clerk';

const { isSignedIn } = useUser();
</script>
