<template>
  <article class="relative flex flex-col w-full gap-y-6 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-center">
    <div class="ring-1 ring-gray-200 dark:ring-gray-800 relative overflow-hidden aspect-[16/9] w-full rounded-lg pointer-events-none">
      <img
        :src="metadata.thumbnailUrl"
        alt=""
        class="object-cover object-top w-full h-full pointer-events-none"
      >
    </div>
    <div class="flex flex-col justify-between flex-1">
      <div class="flex-1">
        <div class="flex items-center gap-x-3 mt-4">
          <div>
            <logo-youtube v-if="metadata.provider === 'youtube'" class="h-6" />
            <logo-vimeo v-else-if="metadata.provider === 'vimeo'" class="h-6" />
          </div>
          <div class="text-sm text-gray-500 dark:text-gray-400 font-medium pointer-events-none">
            {{ timeHr(metadata.durationSeconds) }}
          </div>
          <div class="text-sm text-gray-500 dark:text-gray-400 font-medium pointer-events-none italic">
            {{ metadata.language || '(unknown language)' }}
          </div>
        </div>

        <h2 class="text-gray-900 dark:text-white text-xl font-semibold truncate mt-4">
          {{ metadata.title }}
        </h2>

        <p class="text-base text-gray-500 dark:text-gray-400 mt-1 line-clamp-5 mt-4">
          {{ metadata.description }}
        </p>
        <p class="text-base text-gray-500 dark:text-gray-400 mt-1 line-clamp-5 font-mono mt-4">
          {{ url }}
        </p>
      </div>
    </div>
  </article>
</template>

<script setup>
import LogoVimeo from './LogoVimeo.vue';
import LogoYoutube from './LogoYoutube.vue';

defineProps({
  metadata: {
    type: Object,
    required: true
  },
  url: {
    type: String,
    required: true
  }
});

function timeHr(seconds) {
  const hr = Math.floor(seconds / 3600);
  const min = Math.floor((seconds - hr * 3600) / 60);
  const sec = seconds - hr * 3600 - min * 60;
  return `${hr ? `${hr}:` : ''}${min}:${sec < 10 ? `0${sec}` : sec}`;
}
</script>
