<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1280.000000 834.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(800.000000,834.000000) scale(0.100000,-0.100000) rotate(90)" fill="currentColor" stroke="none">
      <path
        d="M2 7153 l3 -1188 33 -3 c39 -4 107 35 117 68 9 27 19 25 33 -5 30
-65 137 -86 205 -40 37 26 72 77 60 89 -4 3 -2 6 3 6 5 0 16 -18 26 -40 33
-78 136 -105 210 -55 20 14 39 34 43 45 8 27 19 25 39 -5 44 -67 141 -85 208
-40 20 14 39 34 43 45 9 27 19 25 33 -5 18 -39 69 -65 127 -65 64 0 110 28
133 79 l18 38 16 -38 c21 -49 69 -79 127 -79 51 0 114 32 130 66 14 31 25 30
39 -1 39 -85 205 -85 244 0 14 31 22 31 42 0 56 -85 200 -85 246 0 17 31 24
31 38 0 28 -62 141 -86 208 -45 18 12 41 38 51 59 l18 37 17 -38 c9 -20 32
-47 50 -58 64 -39 167 -18 202 41 21 36 29 36 44 4 17 -37 70 -65 122 -65 49
0 86 19 121 64 l22 28 24 -31 c70 -89 204 -79 250 19 l18 38 20 -41 c46 -96
206 -104 255 -12 17 31 24 31 38 0 38 -84 207 -84 252 -1 17 32 24 33 38 1 39
-85 190 -85 246 0 21 31 30 31 44 0 17 -37 70 -65 122 -65 58 0 114 36 132 85
l12 36 21 -43 c24 -51 69 -77 135 -78 51 0 114 35 125 70 9 27 19 25 33 -5 30
-65 145 -87 214 -40 20 14 39 34 43 45 9 27 19 25 33 -5 28 -61 134 -85 200
-45 34 21 75 78 66 92 -3 4 0 8 5 8 6 0 11 -7 11 -16 0 -28 42 -76 84 -94 39
-18 39 -19 14 -25 -32 -8 -82 -61 -95 -100 -7 -21 -7 -43 3 -73 15 -54 38 -80
89 -103 38 -17 39 -18 11 -18 -38 -1 -92 -57 -101 -105 -10 -53 18 -122 58
-142 63 -33 142 -19 181 33 20 28 32 89 22 126 -8 33 -53 76 -90 85 l-31 9 47
20 c36 16 52 31 69 64 l21 43 13 -30 c16 -36 50 -68 95 -87 l35 -15 -32 -1
c-67 -2 -124 -88 -104 -158 21 -73 63 -106 139 -109 36 -1 49 4 83 35 35 30
42 44 46 85 4 36 1 58 -11 81 -18 33 -62 65 -90 65 -9 0 -16 5 -16 10 0 6 7
10 15 10 32 0 77 32 91 65 15 37 34 54 34 31 0 -24 51 -77 90 -94 l35 -15 -33
-8 c-113 -24 -132 -186 -29 -247 36 -21 98 -23 132 -4 92 49 100 185 15 235
-15 9 -35 17 -44 17 -28 0 -17 14 27 35 28 13 49 33 64 60 l22 39 11 -29 c16
-42 39 -65 85 -86 37 -16 38 -18 13 -18 -62 -2 -118 -91 -99 -158 25 -88 80
-119 183 -104 15 2 78 63 73 71 -3 5 -1 11 5 15 5 3 10 26 10 50 0 38 -6 50
-38 85 -27 27 -47 40 -66 40 -16 0 -25 4 -21 10 3 6 13 10 22 10 50 0 115 94
106 154 -7 44 -49 95 -97 116 -27 12 -34 19 -21 19 46 2 135 87 119 113 -3 4
0 8 5 8 6 0 11 -8 11 -19 0 -28 39 -72 83 -91 35 -16 37 -19 18 -25 -31 -9
-78 -52 -93 -83 -17 -35 -8 -104 18 -140 11 -15 40 -37 65 -49 41 -21 42 -22
15 -23 -38 0 -92 -55 -101 -103 -11 -56 19 -124 64 -146 75 -38 164 -8 191 64
33 86 -20 183 -102 186 -21 0 -20 2 7 12 56 22 75 38 96 79 l21 42 13 -30 c15
-36 50 -69 90 -85 l30 -12 -35 -8 c-77 -17 -116 -105 -81 -183 22 -51 63 -77
128 -82 36 -2 48 2 84 35 38 34 42 42 47 94 4 56 4 58 -36 97 -27 27 -49 40
-68 41 l-29 1 30 10 c47 17 84 47 91 73 8 30 34 55 34 32 0 -24 51 -78 90 -95
l35 -15 -33 -8 c-45 -9 -80 -44 -93 -92 -23 -87 43 -170 139 -173 35 -1 49 4
84 35 37 34 41 42 46 94 4 56 4 58 -36 97 -27 28 -49 40 -68 40 -29 1 -28 1
10 20 54 27 67 40 88 87 15 34 17 50 9 85 -11 47 -58 98 -91 98 -10 0 -22 5
-25 10 -3 6 2 10 12 10 31 0 81 38 102 77 12 22 20 31 21 21 0 -28 58 -86 92
-94 l32 -6 -34 -18 c-59 -31 -83 -64 -88 -122 -5 -67 23 -113 88 -145 36 -18
40 -22 21 -23 -13 0 -39 -11 -57 -25 -72 -55 -63 -170 15 -220 76 -47 181 -10
206 74 7 22 8 21 25 -17 25 -56 77 -82 142 -72 75 11 118 63 118 141 0 35 -6
48 -39 80 -36 37 -42 39 -99 39 -55 0 -64 -3 -96 -34 -21 -19 -36 -43 -36 -57
-1 -21 -3 -20 -19 11 -26 48 -50 68 -97 78 l-39 9 32 7 c34 8 77 43 104 85
l15 24 21 -36 c58 -97 185 -100 238 -4 11 21 22 37 25 37 3 -1 14 -15 25 -32
33 -51 70 -73 124 -72 55 0 105 32 120 76 12 33 27 36 37 6 16 -51 98 -97 151
-83 103 25 149 149 87 231 -16 20 -39 38 -56 41 -33 6 -48 23 -21 23 33 0 84
40 100 77 l15 37 13 -30 c16 -38 70 -84 98 -84 17 0 14 -5 -18 -23 -111 -63
-113 -204 -5 -260 64 -33 153 -3 182 63 7 17 16 30 19 30 4 0 16 -15 27 -34
57 -96 185 -98 237 -3 11 21 22 37 25 37 3 -1 14 -15 25 -32 33 -51 70 -73
124 -72 81 1 131 57 131 146 0 57 -28 100 -81 126 -29 14 -37 21 -24 22 34 0
83 37 104 77 12 22 20 31 21 21 0 -27 59 -87 93 -96 l32 -8 -40 -20 c-50 -24
-85 -75 -85 -123 1 -64 49 -126 112 -142 40 -11 50 -10 84 8 21 11 50 37 64
57 22 34 26 36 35 21 60 -102 156 -123 230 -49 19 19 35 41 35 49 0 25 17 17
38 -19 39 -67 112 -91 177 -60 32 15 56 42 80 89 3 6 8 3 12 -5 3 -8 22 -32
43 -52 48 -49 94 -57 154 -29 51 25 76 68 76 131 0 71 -27 106 -105 137 -27
10 -27 12 -7 12 39 2 80 29 105 72 24 39 25 40 31 16 8 -35 71 -87 104 -87 24
-1 22 -3 -18 -23 -25 -12 -55 -38 -68 -57 -65 -97 35 -240 149 -215 30 7 84
53 105 90 12 19 12 19 22 -6 12 -31 65 -77 98 -85 58 -15 132 22 154 76 7 17
17 29 22 28 5 -2 9 -7 8 -12 -3 -12 51 -66 82 -82 36 -19 72 -18 115 3 48 22
73 66 73 126 0 67 -24 105 -85 133 -40 18 -45 23 -25 23 38 1 87 36 109 78 11
21 20 31 21 22 0 -27 62 -87 100 -97 l35 -9 -30 -12 c-48 -18 -59 -26 -83 -62
-47 -68 -14 -161 72 -206 55 -29 148 8 175 68 14 31 31 39 31 14 0 -22 49 -69
87 -84 61 -25 138 12 179 84 12 19 12 19 22 -6 12 -31 65 -77 98 -85 58 -15
132 22 154 76 12 29 35 37 35 12 0 -7 13 -26 29 -43 49 -52 101 -62 163 -32
49 23 73 66 73 130 0 60 -31 106 -89 130 l-35 15 37 13 c49 17 67 33 84 74
l15 34 18 -40 c18 -41 66 -73 109 -73 l21 0 0 1190 0 1190 -6400 0 -6400 0 2
-1187z m9471 816 c-17 -17 -37 -1 -22 17 8 10 15 12 23 4 7 -7 7 -13 -1 -21z
m1159 -1 c-9 -9 -15 -9 -24 0 -10 10 -10 15 2 22 20 12 38 -6 22 -22z m870 0
c-9 -9 -15 -9 -24 0 -10 10 -10 15 2 22 20 12 38 -6 22 -22z m1161 1 c-17 -17
-37 -1 -22 17 8 10 15 12 23 4 7 -7 7 -13 -1 -21z m-12199 0 c-3 -5 -10 -7
-15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m876 6 c0 -8 -4 -15
-10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m1160 0 c0 -8 -4
-15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m874 -6 c-3
-5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m1160 0
c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m870
0 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z
m1160 0 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3
-15z m876 6 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7
10 -15z m1170 6 c0 -14 -18 -23 -31 -15 -8 4 -7 9 2 15 18 11 29 11 29 0z
m280 -1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m290 1 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4
10 -9z m590 1 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m280
0 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m288 -3 c-2 -6 -8
-10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m590 0 c-2
-6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m280
0 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z
m590 0 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13
-11z m292 3 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m280 -7
c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z
m-12186 -274 c3 -5 0 -14 -8 -20 -10 -8 -16 -8 -25 3 -9 11 -9 15 1 19 20 8
26 8 32 -2z m296 -5 c0 -7 -6 -19 -14 -25 -12 -10 -17 -8 -25 8 -8 14 -8 21 2
24 23 10 37 7 37 -7z m290 -6 c0 -13 -19 -22 -30 -15 -14 9 0 34 16 28 8 -3
14 -9 14 -13z m279 -7 c-8 -9 -16 -10 -22 -4 -13 13 0 31 19 24 11 -4 12 -8 3
-20z m297 16 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z
m1160 0 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z m872
-2 c7 -8 6 -17 -3 -30 -13 -18 -14 -18 -25 2 -17 33 5 56 28 28z m1160 0 c7
-8 7 -17 -3 -30 -11 -15 -14 -15 -25 -2 -13 15 -7 45 8 45 5 0 14 -6 20 -13z
m877 3 c3 -5 0 -19 -8 -30 -13 -18 -16 -19 -27 -5 -10 12 -10 18 0 30 14 17
26 19 35 5z m1160 0 c3 -5 0 -19 -8 -30 -13 -18 -16 -19 -27 -5 -10 12 -10 18
0 30 14 17 26 19 35 5z m872 -6 c1 -5 -1 -15 -6 -23 -8 -12 -12 -13 -25 -2
-13 11 -14 17 -5 28 13 14 31 13 36 -3z m861 4 c7 -9 6 -17 -3 -25 -10 -10
-16 -9 -25 2 -12 14 -6 35 9 35 5 0 13 -6 19 -12z m299 -3 c1 -5 -2 -17 -7
-25 -8 -13 -11 -13 -26 2 -9 9 -13 21 -9 28 8 13 37 10 42 -5z m289 4 c9 -16
-10 -31 -30 -23 -13 5 -14 9 -5 20 14 17 25 18 35 3z m287 -3 c8 -21 -17 -40
-31 -23 -9 10 -8 17 0 25 16 16 25 15 31 -2z m295 1 c7 -8 7 -17 -2 -29 -12
-17 -14 -17 -31 1 -18 21 -13 41 9 41 8 0 18 -6 24 -13z m289 -3 c9 -23 -11
-38 -26 -20 -13 16 -7 36 10 36 5 0 12 -7 16 -16z m289 5 c10 -17 -13 -36 -27
-22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z m291 -5 c1 -5 -1 -15 -6 -23 -8 -12
-12 -13 -25 -2 -13 11 -14 17 -5 28 13 14 31 13 36 -3z m291 3 c7 -8 7 -17 -2
-29 -12 -17 -14 -17 -31 1 -18 21 -13 41 9 41 8 0 18 -6 24 -13z m289 -3 c9
-23 -11 -38 -26 -20 -13 16 -7 36 10 36 5 0 12 -7 16 -16z m288 6 c3 -5 2 -17
-3 -26 -8 -14 -10 -14 -21 0 -13 15 -8 36 9 36 5 0 12 -4 15 -10z m300 0 c3
-5 0 -18 -7 -29 -11 -19 -14 -19 -30 -4 -14 12 -15 19 -7 30 13 15 35 17 44 3z
m287 -32 c-10 -10 -16 -8 -30 5 -15 16 -15 19 -2 27 21 13 49 -15 32 -32z
m287 28 c8 -10 8 -16 -3 -25 -10 -9 -16 -8 -26 3 -12 15 -6 36 9 36 5 0 14 -6
20 -14z m290 0 c8 -10 8 -16 -3 -25 -10 -9 -16 -8 -26 3 -12 15 -6 36 9 36 5
0 14 -6 20 -14z m297 -1 c4 -8 1 -22 -6 -30 -11 -14 -15 -14 -31 1 -14 13 -16
20 -8 31 15 17 38 17 45 -2z m-11036 -3 c0 -14 -11 -21 -26 -15 -8 3 -11 9 -8
14 7 11 34 12 34 1z m285 -1 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11
-4 15 -9z m289 0 c3 -5 0 -11 -8 -14 -15 -6 -26 1 -26 15 0 11 27 10 34 -1z
m584 -2 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5
13 -11z m287 2 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z
m583 -2 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5
13 -11z m290 -1 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z
m292 3 c0 -5 -6 -14 -14 -20 -16 -13 -32 2 -21 19 8 12 35 13 35 1z m580 0 c0
-14 -18 -23 -30 -16 -6 4 -8 11 -5 16 8 12 35 12 35 0z m290 0 c0 -14 -18 -23
-30 -16 -6 4 -8 11 -5 16 8 12 35 12 35 0z m584 0 c3 -5 -1 -14 -8 -20 -16
-13 -32 2 -21 19 8 12 22 13 29 1z m284 -3 c-6 -18 -28 -21 -28 -4 0 9 7 16
16 16 9 0 14 -5 12 -12z m292 3 c0 -14 -18 -23 -30 -16 -6 4 -8 11 -5 16 8 12
35 12 35 0z m584 0 c3 -5 -1 -14 -8 -20 -16 -13 -32 2 -21 19 8 12 22 13 29 1z
m289 3 c8 -8 -16 -33 -25 -27 -13 7 -9 33 5 33 8 0 16 -3 20 -6z m587 -3 c0
-14 -18 -23 -30 -16 -6 4 -8 11 -5 16 8 12 35 12 35 0z m284 0 c3 -5 -1 -14
-8 -20 -16 -13 -32 2 -21 19 8 12 22 13 29 1z m-7534 -284 c0 -6 -7 -19 -16
-28 -16 -18 -17 -17 -36 7 -19 24 -19 26 -2 45 16 18 18 18 36 3 10 -10 18
-22 18 -27z m2894 -24 l-24 -26 -19 24 c-19 24 -19 24 2 47 l21 22 22 -21 22
-20 -24 -26z m1160 -1 l-25 -26 -21 22 -22 22 24 25 24 25 22 -21 22 -20 -24
-27z m879 4 c-10 -12 -19 -23 -20 -25 -2 -3 -14 6 -28 19 l-25 24 22 23 22 23
23 -22 c22 -21 22 -22 6 -42z m1159 -8 l-22 -22 -25 24 -25 24 22 23 22 24 25
-26 25 -25 -22 -22z m2903 2 l-25 -24 -22 22 -22 22 23 24 24 25 23 -22 23
-22 -24 -25z m1158 0 l-23 -24 -22 22 -22 22 23 24 22 24 23 -22 23 -21 -24
-25z m881 -1 c-22 -25 -32 -24 -54 4 -18 21 -18 23 1 43 l20 22 25 -25 c24
-24 24 -26 8 -44z m1160 0 c-22 -25 -32 -24 -54 4 -18 21 -18 23 1 43 l20 22
25 -25 c24 -24 24 -26 8 -44z m-12501 9 c-15 -17 -17 -17 -35 -1 -17 15 -17
17 -1 35 15 17 17 17 35 1 17 -15 17 -17 1 -35z m587 36 c11 -12 10 -18 -3
-32 -16 -15 -18 -15 -34 0 -13 14 -14 20 -3 32 7 9 16 16 20 16 4 0 13 -7 20
-16z m293 -8 c3 -6 -1 -20 -9 -30 -11 -16 -16 -17 -29 -6 -18 15 -19 32 -4 47
13 13 35 7 42 -11z m290 6 c16 -17 15 -20 -7 -41 l-23 -22 -16 24 c-14 22 -14
26 1 41 21 20 25 20 45 -2z m297 -17 c0 -7 -6 -18 -14 -24 -12 -10 -19 -8 -34
7 -19 19 -18 19 1 35 22 17 47 8 47 -18z m281 19 c17 -15 16 -17 -2 -35 -19
-19 -19 -19 -38 0 -17 17 -18 20 -3 35 20 20 21 20 43 0z m289 -1 c15 -16 14
-19 -3 -36 -18 -18 -20 -18 -38 -1 -18 16 -18 18 -3 36 21 22 23 22 44 1z
m293 -1 c14 -16 14 -19 -4 -42 l-19 -25 -19 25 c-18 23 -18 26 -4 42 9 10 19
18 23 18 4 0 14 -8 23 -18z m288 -35 c-19 -19 -19 -19 -38 0 -20 20 -20 20 0
36 19 16 22 16 39 0 18 -16 18 -17 -1 -36z m292 35 c15 -17 15 -19 -3 -35 -19
-16 -21 -16 -39 2 -17 17 -18 20 -3 35 21 20 25 20 45 -2z m584 -33 c-15 -24
-26 -24 -43 -1 -10 14 -10 20 3 35 15 16 18 16 34 1 15 -13 16 -20 6 -35z
m283 -3 c-19 -19 -21 -19 -37 -3 -16 15 -16 18 2 37 17 19 19 19 37 3 18 -16
18 -17 -2 -37z m293 36 c15 -17 15 -19 -4 -36 -20 -17 -22 -17 -40 0 -18 16
-18 18 -3 36 21 22 27 23 47 0z m584 -33 c-14 -23 -30 -24 -47 -4 -10 13 -9
19 5 35 17 19 20 19 36 4 15 -13 16 -20 6 -35z m283 -3 c-19 -19 -21 -19 -37
-3 -16 15 -16 18 2 37 17 19 19 19 37 3 18 -16 18 -17 -2 -37z m591 38 c16
-15 16 -17 -2 -35 -17 -17 -23 -18 -37 -7 -9 7 -19 14 -21 15 -5 3 30 43 37
43 3 0 13 -7 23 -16z m287 -3 c21 -17 21 -19 4 -35 -23 -20 -36 -20 -52 -1
-10 13 -10 19 5 35 22 24 17 24 43 1z m292 -35 c-16 -20 -34 -20 -50 -1 -10
13 -9 19 5 36 19 20 19 20 39 0 16 -17 17 -23 6 -35z m584 36 c15 -18 15 -20
-4 -37 -17 -15 -22 -16 -37 -4 -10 7 -20 15 -22 16 -6 4 30 43 39 43 4 0 14
-8 24 -18z m284 -1 c21 -17 21 -19 4 -35 -23 -20 -36 -20 -52 -1 -10 13 -10
19 5 35 22 24 17 24 43 1z m297 -1 c18 -20 18 -21 -3 -43 l-22 -21 -22 21
c-21 22 -21 23 -3 43 10 11 21 20 25 20 4 0 15 -9 25 -20z m295 -34 c-16 -20
-29 -20 -52 0 -17 16 -17 18 4 36 21 18 22 18 42 -1 16 -17 17 -23 6 -35z
m285 34 c10 -11 16 -21 14 -23 -2 -1 -12 -9 -22 -16 -15 -12 -20 -11 -37 4
-19 17 -19 19 -4 37 21 23 27 23 49 -2z m287 1 c15 -18 15 -20 -1 -35 -22 -20
-35 -20 -51 -1 -10 13 -10 19 5 35 22 25 26 25 47 1z m293 -1 c18 -20 18 -21
-3 -43 l-22 -21 -22 21 c-21 22 -21 23 -3 43 10 11 21 20 25 20 4 0 15 -9 25
-20z m295 -35 c-16 -19 -29 -19 -52 1 -17 16 -17 18 3 35 20 17 22 17 41 -1
18 -16 19 -22 8 -35z m282 36 c19 -18 19 -21 5 -35 -20 -20 -26 -20 -49 1 -17
15 -17 17 -2 35 21 22 20 22 46 -1z m588 -35 c-16 -20 -34 -20 -50 -1 -10 13
-9 19 5 36 19 20 19 20 39 0 16 -17 17 -23 6 -35z m587 10 c-2 -5 -11 -14 -20
-20 -12 -8 -21 -5 -36 9 -20 17 -20 20 -5 36 15 17 18 17 40 1 13 -9 22 -21
21 -26z m1446 -8 c-19 -21 -22 -22 -47 -3 -19 14 -19 15 -2 34 17 19 19 19 41
3 21 -16 22 -18 8 -34z m-1749 27 c16 -12 17 -17 6 -30 -16 -19 -34 -19 -50 0
-10 12 -10 18 0 30 15 18 20 18 44 0z m870 1 c25 -13 25 -14 8 -30 -23 -20
-36 -20 -52 -1 -10 12 -10 18 0 30 15 18 12 18 44 1z m290 -1 c16 -12 17 -17
6 -30 -16 -19 -34 -19 -50 0 -10 12 -10 18 0 30 15 18 20 18 44 0z m866 5 c0
-5 7 -10 17 -10 14 0 14 -2 -2 -20 -22 -24 -38 -25 -55 -5 -10 12 -10 18 0 30
14 17 40 20 40 5z m294 -5 c16 -12 17 -17 6 -30 -16 -19 -34 -19 -50 0 -10 12
-10 18 0 30 15 18 20 18 44 0z m-11904 -325 l-25 -27 -25 26 -25 26 25 30 25
30 25 -29 26 -28 -26 -28z m881 55 l26 -26 -28 -31 -29 -30 -28 30 -29 31 26
25 c14 14 28 26 30 26 3 0 17 -11 32 -25z m1160 0 l26 -26 -28 -31 -29 -30
-28 30 -28 30 25 26 c14 14 28 25 30 26 3 0 17 -11 32 -25z m870 -55 l-26 -28
-28 26 -27 26 27 28 27 28 26 -25 27 -26 -26 -29z m1160 0 l-26 -28 -28 26
-27 26 27 28 27 28 26 -25 27 -26 -26 -29z m870 0 l-26 -28 -28 26 -27 26 27
28 27 28 26 -25 27 -26 -26 -29z m1160 0 l-26 -28 -28 26 -27 26 27 28 27 28
26 -25 27 -26 -26 -29z m880 55 l26 -26 -29 -31 -29 -31 -29 28 -30 29 27 28
c15 15 29 28 32 28 3 0 17 -11 32 -25z m1160 -1 l28 -27 -30 -30 -30 -30 -25
23 c-13 13 -24 26 -24 30 0 8 42 60 49 60 3 0 17 -12 32 -26z m869 -56 l-30
-29 -24 28 -25 28 27 28 26 27 27 -26 28 -27 -29 -29z m1160 -1 l-29 -30 -25
29 -25 29 26 27 26 27 28 -26 28 -27 -29 -29z m869 -1 l-28 -29 -25 29 -25 29
26 27 26 27 27 -27 27 -27 -28 -29z m1162 -2 l-26 -31 -27 31 -26 31 24 26 25
26 28 -26 29 -26 -27 -31z m-12491 56 l25 -19 -26 -26 -26 -27 -25 25 -25 25
21 21 c25 25 25 25 56 1z m586 -1 l22 -20 -24 -24 -24 -24 -24 24 -23 23 21
21 c25 26 25 26 52 0z m290 0 l22 -20 -24 -24 -24 -24 -24 24 -23 23 21 21
c25 26 25 26 52 0z m580 0 l22 -20 -24 -24 -24 -24 -24 24 -23 23 21 21 c25
26 25 26 52 0z m290 0 l22 -20 -24 -24 -24 -24 -24 24 -23 23 21 21 c25 26 25
26 52 0z m290 0 l22 -20 -24 -24 -24 -24 -24 24 -23 23 21 21 c25 26 25 26 52
0z m580 0 l22 -20 -24 -24 -24 -24 -24 24 -23 23 21 21 c25 26 25 26 52 0z
m290 0 l22 -20 -25 -25 -25 -25 -23 23 -24 23 21 22 c26 28 26 28 54 2z m590
0 l22 -20 -25 -25 c-29 -29 -35 -30 -58 -4 -18 20 -18 21 5 45 28 30 28 30 56
4z m280 0 l23 -21 -25 -25 -25 -25 -25 23 -24 23 22 23 c26 28 26 28 54 2z
m290 0 l22 -20 -25 -25 -25 -25 -24 22 -24 23 22 23 c26 28 26 28 54 2z m590
0 l22 -20 -25 -25 c-29 -29 -35 -30 -58 -4 -18 20 -18 21 5 45 28 30 28 30 56
4z m282 -2 l22 -23 -25 -24 -25 -23 -25 23 -25 24 22 23 c12 13 24 23 28 23 4
0 16 -10 28 -23z m588 2 l22 -20 -25 -25 -25 -25 -24 22 -24 23 22 23 c26 28
26 28 54 2z m290 0 l22 -20 -25 -25 c-29 -29 -25 -29 -53 -4 l-23 20 23 25
c28 30 28 30 56 4z m287 1 l21 -20 -27 -27 -26 -27 -26 24 -25 24 22 23 c26
28 33 28 61 3z m583 -1 l22 -20 -25 -25 -25 -25 -24 22 -24 23 22 23 c26 28
26 28 54 2z m290 0 l22 -20 -25 -25 -25 -25 -24 22 -24 23 22 23 c26 28 26 28
54 2z m580 0 l22 -20 -25 -25 -25 -25 -24 22 -24 23 22 23 c26 28 26 28 54 2z
m290 0 l22 -20 -25 -25 -25 -25 -24 22 -24 23 22 23 c26 28 26 28 54 2z m291
-1 l23 -21 -26 -24 -26 -24 -24 22 -24 23 22 23 c26 28 26 28 55 1z m580 0
c26 -25 29 -38 8 -38 -7 0 -18 -8 -24 -17 -9 -17 -12 -16 -35 6 l-26 25 22 23
c26 28 26 28 55 1z m290 0 l23 -21 -26 -24 -26 -24 -24 22 -24 23 22 23 c26
28 26 28 55 1z m590 0 l23 -21 -26 -24 c-31 -28 -36 -28 -59 -3 -18 20 -18 21
5 45 28 30 28 30 57 3z m284 -4 c17 -19 20 -28 11 -31 -7 -3 -19 -12 -28 -20
-14 -14 -18 -13 -40 8 l-24 23 22 23 c27 29 29 29 59 -3z m287 3 l21 -22 -25
-24 -25 -24 -24 23 -25 24 22 23 c27 28 29 28 56 0z m586 0 l24 -23 -23 -22
c-28 -27 -36 -27 -59 -1 -17 20 -17 22 5 45 12 13 24 24 25 24 2 0 14 -10 28
-23z m281 -1 l26 -25 -20 -20 c-26 -26 -29 -26 -58 1 l-23 22 22 23 c12 13 23
23 24 23 2 0 15 -11 29 -24z m589 1 l24 -23 -24 -23 -25 -24 -24 23 -25 24 22
23 c12 13 23 23 24 23 2 0 14 -10 28 -23z m290 0 l24 -23 -24 -23 c-24 -23
-25 -23 -47 -5 -12 10 -24 20 -26 21 -4 3 39 53 45 53 2 0 14 -10 28 -23z
m272 9 c7 -8 19 -16 26 -19 15 -5 1 -35 -25 -51 -12 -8 -22 -5 -42 14 l-25 24
22 23 c25 27 25 27 44 9z m-11876 -343 c-16 -13 -32 -33 -35 -44 -7 -20 -8
-19 -26 10 -10 17 -27 36 -38 42 -18 12 -17 14 18 48 l36 36 37 -34 36 -34
-28 -24z m867 58 c38 -38 39 -40 19 -51 -12 -6 -29 -25 -39 -42 l-18 -32 -15
31 c-8 16 -26 36 -39 43 l-24 13 37 37 c20 20 38 38 38 39 1 0 19 -17 41 -38z
m1161 -2 c41 -40 41 -40 18 -51 -13 -5 -30 -24 -38 -41 l-15 -32 -22 37 c-13
20 -31 40 -40 43 -18 5 -8 20 55 84 1 0 20 -17 42 -40z m870 1 c38 -38 39 -41
20 -51 -10 -6 -27 -23 -37 -40 l-18 -29 -13 25 c-7 14 -24 32 -37 40 -25 17
-31 35 -10 35 6 0 21 14 32 30 11 16 21 30 21 30 1 0 20 -18 42 -40z m1160 0
c38 -38 39 -41 20 -51 -10 -6 -28 -25 -38 -42 -11 -18 -20 -27 -20 -20 0 7
-16 28 -37 48 -20 19 -34 36 -32 38 2 1 13 7 24 13 11 6 24 20 30 32 5 12 10
22 11 22 1 0 20 -18 42 -40z m875 -66 c-18 -17 -33 -36 -33 -42 0 -6 -17 7
-38 30 l-39 41 36 37 36 36 35 -35 36 -36 -33 -31z m1161 1 c-19 -18 -34 -37
-34 -43 0 -6 -17 7 -38 30 l-39 41 36 37 36 37 37 -35 37 -35 -35 -32z m873
66 l39 -40 -23 -12 c-12 -7 -28 -25 -35 -41 -15 -32 -24 -35 -33 -10 -3 9 -18
28 -33 41 l-28 25 35 37 c20 21 37 38 37 38 1 1 19 -17 41 -38z m1159 1 l39
-39 -31 -29 c-17 -16 -34 -36 -38 -44 -5 -12 -7 -12 -12 -2 -8 18 -50 59 -66
64 -8 3 1 19 27 45 22 22 41 41 41 42 1 0 19 -16 40 -37z m853 14 c7 -13 24
-30 37 -37 l24 -12 -35 -33 c-19 -17 -35 -37 -35 -43 0 -7 -18 7 -40 30 -42
44 -49 59 -27 59 6 0 21 14 32 30 24 36 28 37 44 6z m-6377 -83 l-34 -32 -32
32 -32 32 30 30 31 30 35 -30 35 -30 -33 -32z m589 -1 l-34 -32 -34 34 -35 35
33 29 34 30 35 -31 35 -32 -34 -33z m285 67 l33 -32 -36 -33 -35 -33 -33 31
-33 32 32 32 c17 17 33 32 36 33 2 0 19 -13 36 -30z m286 -66 l-34 -32 -33 31
-33 32 32 30 31 31 35 -30 35 -30 -33 -32z m589 -1 l-34 -32 -34 34 -35 35 33
29 34 30 35 -31 35 -32 -34 -33z m285 67 l33 -32 -36 -33 -36 -34 -32 32 -33
32 32 31 c17 18 33 33 36 34 2 0 19 -13 36 -30z m5508 7 c8 -14 25 -27 36 -31
20 -6 19 -9 -14 -41 -20 -19 -36 -39 -36 -45 0 -5 -17 9 -39 31 l-39 41 32 33
c38 41 40 42 60 12z m866 13 c0 -14 39 -49 53 -49 6 0 -6 -16 -26 -35 -21 -20
-37 -40 -37 -46 0 -5 -17 9 -39 31 l-39 41 32 33 c32 34 56 45 56 25z m1170
-13 c7 -13 22 -27 33 -30 19 -5 18 -8 -16 -41 -20 -20 -37 -40 -37 -46 0 -5
-18 9 -40 32 l-39 41 32 33 c40 40 50 42 67 11z m-12478 -11 l27 -24 -34 -34
-35 -35 -33 33 -33 33 30 25 c38 32 44 32 78 2z m582 -2 l32 -28 -33 -31 -33
-31 -34 32 -34 32 32 26 c17 14 33 26 35 26 2 1 18 -11 35 -26z m290 0 l32
-28 -33 -31 -33 -31 -36 33 -35 34 28 25 c35 31 39 31 77 -2z m587 1 l28 -27
-35 -32 -34 -32 -34 32 -34 32 32 26 c39 33 44 33 77 1z m283 -1 l32 -28 -34
-32 -34 -32 -33 33 -33 33 32 26 c17 14 33 26 35 26 2 1 18 -11 35 -26z m290
0 l32 -28 -34 -32 -34 -32 -33 33 -33 33 26 27 c33 32 37 32 76 -1z m587 1
l28 -27 -35 -33 -36 -33 -31 32 -32 31 30 28 c37 34 42 34 76 2z m2903 -1 l32
-28 -35 -33 -34 -33 -34 33 -33 32 33 27 c17 16 34 28 36 29 2 0 18 -12 35
-27z m290 0 l32 -28 -35 -33 -35 -33 -34 35 -35 34 29 26 c36 32 39 32 78 -1z
m287 1 l28 -27 -35 -33 -36 -33 -33 33 -33 33 32 26 c39 33 44 33 77 1z m583
-1 l32 -28 -35 -33 -34 -33 -34 33 -33 32 33 27 c17 16 34 28 36 29 2 0 18
-12 35 -27z m290 0 l32 -28 -35 -33 -35 -33 -34 35 -35 34 29 26 c36 32 39 32
78 -1z m587 1 l28 -27 -36 -34 -36 -34 -34 33 -33 32 33 27 c40 35 45 35 78 3z
m283 -1 l32 -28 -35 -33 -34 -33 -34 33 -33 32 33 27 c17 16 34 28 36 29 2 0
18 -12 35 -27z m290 0 l32 -28 -35 -33 -35 -33 -34 35 -35 34 29 26 c36 32 39
32 78 -1z m587 1 l28 -27 -36 -34 -36 -34 -31 28 c-17 16 -33 29 -35 31 -7 4
62 61 73 62 6 0 22 -12 37 -26z m278 1 c13 -14 28 -25 34 -25 6 0 -6 -16 -28
-35 l-38 -36 -31 28 c-17 15 -33 29 -35 31 -5 3 61 61 69 61 3 1 16 -11 29
-24z m571 16 c0 -6 13 -18 29 -27 l30 -17 -36 -36 -35 -36 -37 35 -38 35 31
27 c31 28 56 36 56 19z m306 -17 l34 -26 -37 -37 -37 -36 -33 33 -32 33 32 29
c18 16 33 30 35 30 2 0 19 -12 38 -26z m264 17 c0 -6 13 -18 29 -27 l30 -17
-36 -36 -36 -36 -33 33 -33 33 32 29 c33 31 47 37 47 21z m594 -6 c3 -8 17
-20 30 -27 l25 -11 -35 -36 -36 -36 -37 35 -36 35 30 27 c34 31 50 35 59 13z
m301 -12 l34 -27 -35 -36 -36 -36 -33 33 -34 34 32 29 c18 16 33 30 35 30 2 0
18 -12 37 -27z m569 12 c3 -8 17 -20 30 -27 l25 -11 -36 -36 -36 -36 -33 33
-33 33 32 29 c37 34 43 36 51 15z m290 0 c3 -8 17 -20 30 -27 l25 -11 -36 -36
-36 -36 -34 34 -34 34 27 28 c31 32 49 37 58 14z m301 -12 c30 -24 37 -53 13
-53 -5 0 -20 -12 -33 -27 -17 -19 -24 -23 -25 -13 0 14 -13 27 -49 49 -11 7
-6 16 20 40 19 17 35 31 37 31 2 0 18 -12 37 -27z m-7801 -315 c5 -3 -5 -18
-22 -33 -17 -14 -34 -35 -37 -46 -6 -19 -9 -18 -31 15 -14 20 -29 36 -35 36
-24 0 -16 29 18 66 l38 39 30 -35 c16 -19 34 -38 39 -42z m6056 32 c3 -3 17
-13 29 -22 l24 -17 -31 -26 c-17 -14 -34 -35 -37 -45 -5 -17 -11 -14 -49 25
l-43 45 41 43 c39 41 41 43 51 23 5 -12 12 -23 15 -26z m-10450 -70 l-35 -35
-35 34 -35 34 34 36 35 36 35 -35 36 -35 -35 -35z m299 69 l34 -40 -22 -16
c-13 -8 -30 -28 -40 -44 l-17 -28 -18 34 c-11 19 -27 37 -37 40 -26 9 -24 18
11 43 17 13 30 29 30 37 0 26 24 15 59 -26z m289 1 l32 -38 -35 -35 -35 -35
-35 35 -35 35 32 38 c18 21 35 38 38 38 3 0 20 -17 38 -38z m290 -58 c-15 -13
-28 -30 -28 -38 0 -20 -4 -18 -44 22 l-36 36 32 37 33 36 36 -35 36 -35 -29
-23z m290 59 l37 -38 -31 -29 c-17 -16 -34 -36 -38 -44 -5 -13 -7 -13 -12 0
-4 8 -21 28 -38 44 l-31 29 35 38 c19 21 36 38 38 38 1 1 19 -16 40 -38z m286
-75 c-40 -40 -44 -42 -44 -21 0 8 -13 24 -29 36 l-29 21 36 37 37 36 33 -36
32 -37 -36 -36z m294 74 l32 -38 -35 -35 -35 -35 -35 35 -35 35 32 38 c18 21
35 38 38 38 3 0 20 -17 38 -38z m291 -61 c-14 -15 -28 -32 -31 -39 -2 -7 -19
3 -41 25 l-37 37 32 37 33 36 36 -35 35 -35 -27 -26z m289 62 l37 -38 -31 -29
c-17 -16 -34 -36 -38 -44 -5 -13 -7 -13 -12 0 -4 8 -21 28 -38 44 l-31 29 35
38 c19 21 36 38 38 38 1 1 19 -16 40 -38z m292 -71 l-35 -35 -35 35 -35 35 34
35 35 35 35 -35 36 -35 -35 -35z m288 70 l32 -38 -35 -35 -35 -35 -35 35 -35
35 32 38 c18 21 35 38 38 38 3 0 20 -17 38 -38z m293 0 l43 -40 -23 -16 c-12
-9 -31 -30 -41 -46 -17 -28 -18 -29 -25 -9 -3 11 -20 32 -37 46 l-30 25 34 39
c18 22 34 40 35 40 1 1 21 -17 44 -39z m864 -73 l-36 -36 -35 35 -36 35 34 38
33 38 38 -37 38 -37 -36 -36z m1170 73 l39 -40 -23 -16 c-12 -9 -31 -30 -41
-46 l-18 -30 -16 30 c-9 17 -26 38 -38 47 l-23 16 33 28 c17 16 32 34 32 40 0
20 15 12 55 -29z m290 -73 l-37 -37 -26 28 c-15 16 -33 31 -41 33 -9 3 -1 17
25 45 l39 40 38 -36 38 -37 -36 -36z m870 73 l39 -40 -23 -16 c-12 -9 -31 -30
-41 -47 l-19 -30 -17 36 c-10 21 -28 40 -42 45 l-24 8 36 27 c20 16 36 35 36
43 0 23 13 17 55 -26z m286 -78 c-36 -38 -40 -40 -46 -22 -5 17 -21 31 -63 56
-1 1 14 19 35 40 l38 39 38 -36 37 -35 -39 -42z m559 109 c0 -5 14 -23 30 -39
l30 -30 -28 -29 c-16 -15 -31 -35 -34 -43 -3 -10 -16 -3 -45 28 l-40 42 36 39
c36 40 51 49 51 32z m599 -111 c-21 -22 -39 -37 -39 -32 0 11 -35 48 -56 60
-13 7 -9 15 23 48 l38 39 37 -37 36 -36 -39 -42z m311 53 c14 -13 19 -23 12
-23 -16 0 -35 -18 -51 -49 l-13 -23 -16 24 c-9 12 -21 26 -26 30 -6 4 -17 11
-24 16 -10 7 -4 18 25 48 37 38 38 38 53 19 8 -11 26 -30 40 -42z m250 58 c0
-5 14 -23 30 -39 l30 -30 -28 -29 c-16 -15 -31 -35 -34 -43 -3 -10 -16 -3 -45
28 l-40 43 36 38 c37 39 51 48 51 32z m602 -108 l-35 -38 -38 38 -37 37 37 38
36 37 37 -37 36 -36 -36 -39z m298 76 l39 -41 -29 -24 c-16 -14 -32 -34 -35
-44 -5 -17 -11 -14 -49 26 l-43 45 36 38 c20 21 37 39 39 40 1 0 20 -17 42
-40z m862 -76 l-35 -38 -39 39 c-33 33 -37 41 -23 48 20 12 55 49 55 60 0 4
18 -10 39 -31 l39 -39 -36 -39z m1168 76 l39 -41 -29 -25 c-16 -13 -32 -33
-35 -43 -5 -17 -12 -13 -51 27 -24 26 -40 49 -35 52 34 23 61 48 61 58 0 20 8
16 50 -28z m270 18 c0 -12 14 -25 49 -46 10 -7 5 -17 -25 -47 l-38 -39 -37 38
-37 37 37 38 c35 37 51 43 51 19z m890 -18 l39 -41 -34 -33 c-19 -18 -35 -37
-35 -44 0 -6 -4 -11 -10 -11 -5 0 -10 6 -10 13 0 17 -45 67 -61 67 -7 0 3 14
24 31 20 17 37 37 37 45 0 22 7 17 50 -27z m-9000 -69 l-35 -35 -35 35 -35 35
35 31 35 32 35 -31 35 -32 -35 -35z m289 67 l36 -33 -36 -34 -37 -35 -33 35
-34 35 30 31 c17 17 32 32 34 33 2 0 20 -14 40 -32z m892 -49 c-11 -4 -27 -18
-36 -32 l-16 -24 -39 36 -38 37 37 32 36 31 38 -36 c34 -33 36 -37 18 -44z
m268 49 l32 -34 -34 -34 -34 -34 -35 35 -35 35 31 31 c17 17 33 32 37 33 3 0
20 -14 38 -32z m892 -49 c-11 -4 -27 -18 -37 -32 l-16 -26 -27 28 c-14 16 -33
31 -41 34 -11 4 -5 14 25 41 l40 35 38 -36 c34 -33 36 -37 18 -44z m268 49
l32 -34 -35 -35 -35 -35 -23 27 c-13 15 -29 29 -37 31 -9 3 -3 16 20 41 19 19
36 36 40 37 3 0 20 -14 38 -32z m888 -52 c-12 -6 -28 -23 -36 -39 l-13 -29
-20 32 c-11 17 -23 31 -26 31 -4 0 -13 4 -21 9 -10 7 -4 17 25 44 l39 35 37
-36 c36 -35 36 -36 15 -47z m568 51 l34 -35 -35 -35 -36 -36 -25 30 c-13 17
-31 30 -39 30 -22 0 -16 16 11 28 13 6 30 20 37 32 7 11 14 20 16 20 1 0 18
-15 37 -34z m1158 0 l37 -34 -36 -36 -36 -36 -25 30 c-13 17 -32 30 -41 30
-10 0 -3 13 24 39 21 21 39 39 40 40 1 0 18 -14 37 -33z m873 -69 l-32 -34
-38 34 -37 35 38 33 38 33 32 -33 32 -33 -33 -35z m287 69 l37 -34 -36 -36
-36 -36 -25 30 c-13 17 -32 30 -41 30 -10 0 -3 13 24 39 21 21 39 39 40 40 1
0 18 -14 37 -33z m873 -69 l-32 -34 -38 34 -37 35 37 32 38 33 32 -33 33 -32
-33 -35z m287 68 l35 -35 -35 -35 -35 -35 -25 30 c-13 17 -32 30 -41 30 -10 0
-3 13 24 39 21 21 40 39 41 40 0 0 17 -15 36 -34z m899 -49 c-10 -4 -26 -19
-37 -31 l-20 -24 -38 35 -38 36 37 32 37 32 38 -35 c32 -31 35 -37 21 -45z
m262 49 l37 -35 -35 -34 -36 -34 -34 34 -34 34 31 34 c18 18 32 34 33 35 1 0
18 -15 38 -34z m-11879 -293 l27 -21 -35 -35 c-20 -19 -38 -45 -42 -56 -6 -19
-9 -17 -27 19 -11 23 -23 41 -27 41 -3 0 -14 7 -25 15 -17 13 -16 15 17 45 20
17 39 40 43 52 6 19 7 18 24 -9 10 -17 30 -39 45 -51z m834 43 c11 -19 32 -40
46 -47 31 -14 32 -22 1 -36 -13 -6 -32 -29 -42 -51 -22 -47 -30 -50 -38 -17
-3 13 -23 39 -43 57 -24 21 -33 34 -25 37 22 7 72 60 72 77 0 23 6 19 29 -20z
m1202 -42 c35 -21 36 -26 4 -43 -14 -7 -33 -30 -42 -51 -10 -21 -22 -39 -26
-39 -5 0 -6 4 -2 10 3 5 -14 29 -38 54 l-43 45 28 21 c15 11 35 34 44 52 l16
31 15 -32 c9 -17 29 -39 44 -48z m875 -4 c30 -16 30 -22 -1 -39 -13 -7 -33
-31 -44 -53 -17 -35 -21 -38 -27 -21 -4 10 -23 36 -42 56 l-35 36 36 32 c20
17 39 41 43 52 6 19 8 18 27 -15 11 -19 30 -41 43 -48z m1153 3 c14 -9 28 -17
30 -19 2 -1 -11 -15 -29 -30 -18 -15 -38 -40 -45 -57 l-13 -30 -15 30 c-8 16
-27 41 -42 56 l-27 27 36 31 c19 17 38 41 42 52 6 19 8 18 22 -12 8 -17 27
-39 41 -48z m870 0 c14 -9 28 -17 30 -19 2 -1 -15 -23 -38 -48 -22 -24 -41
-50 -41 -57 -1 -7 -8 1 -17 17 -22 42 -54 75 -72 75 -8 0 3 15 27 35 22 20 44
45 48 57 6 19 7 18 22 -12 8 -17 27 -39 41 -48z m1163 -3 l29 -21 -41 -44
c-22 -24 -40 -49 -40 -56 -1 -7 -10 4 -21 25 -11 21 -33 46 -49 55 l-29 18 35
28 c20 15 40 39 46 52 l11 24 15 -30 c8 -17 28 -40 44 -51z m826 68 c-3 -13
34 -67 46 -67 4 0 12 -5 19 -11 9 -9 2 -22 -31 -56 -23 -24 -40 -48 -37 -53 4
-6 2 -10 -3 -10 -4 0 -16 16 -25 35 -9 19 -31 44 -49 55 -18 11 -28 20 -23 20
15 0 71 52 84 78 13 24 24 30 19 9z m1186 -53 c8 -8 18 -14 23 -14 5 0 14 -5
20 -11 9 -9 4 -18 -19 -37 -17 -14 -36 -39 -43 -55 l-12 -29 -21 36 c-12 20
-35 44 -53 55 -29 18 -30 19 -10 26 12 3 36 24 53 46 30 38 33 39 40 19 4 -12
14 -28 22 -36z m900 -19 l25 -17 -31 -26 c-17 -14 -36 -39 -43 -56 l-13 -30
-16 32 c-9 17 -29 41 -45 53 l-28 21 46 46 46 47 17 -27 c9 -15 28 -34 42 -43z
m1160 0 l25 -17 -35 -30 c-19 -17 -37 -41 -40 -54 -8 -32 -16 -30 -32 6 -8 16
-26 39 -42 50 l-29 20 47 48 47 47 17 -27 c9 -15 28 -34 42 -43z m871 -1 l26
-17 -34 -36 c-19 -20 -39 -45 -44 -55 -10 -17 -12 -16 -28 18 -10 20 -30 44
-45 52 l-27 16 46 47 46 46 17 -27 c9 -15 29 -35 43 -44z m1160 0 l26 -17 -34
-36 c-19 -20 -39 -45 -44 -55 -10 -17 -12 -16 -28 18 -10 20 -30 44 -45 52
l-27 16 46 47 46 46 17 -27 c9 -15 29 -35 43 -44z m-12529 37 c14 -17 34 -33
45 -36 17 -6 14 -11 -27 -53 -41 -41 -47 -44 -56 -28 -13 24 -42 52 -58 58 -8
2 2 19 26 45 22 22 40 41 42 42 2 0 14 -12 28 -28z m604 -13 c25 -25 37 -44
30 -46 -15 -5 -45 -34 -57 -56 -8 -15 -15 -12 -50 23 l-41 41 37 39 c20 21 37
39 37 40 1 0 21 -18 44 -41z m290 0 c25 -25 37 -44 30 -46 -16 -6 -45 -34 -58
-58 -9 -17 -14 -15 -54 21 l-43 40 40 41 c22 22 41 42 41 42 1 1 21 -17 44
-40z m559 19 c7 -13 26 -32 43 -42 28 -16 29 -18 12 -27 -11 -6 -30 -24 -44
-40 l-26 -30 -41 41 -41 41 37 39 c20 21 39 39 42 40 3 0 11 -9 18 -22z m290
-1 c7 -13 24 -30 37 -37 23 -12 32 -29 16 -29 -11 0 -48 -35 -60 -55 -7 -14
-15 -10 -49 24 l-41 41 37 39 c20 21 39 39 42 40 3 0 11 -10 18 -23z m290 0
c7 -13 24 -30 37 -37 13 -7 24 -15 24 -19 0 -4 -11 -12 -24 -19 -13 -7 -30
-24 -37 -37 -7 -13 -15 -24 -19 -24 -3 0 -23 17 -43 38 l-38 39 38 40 c21 22
41 41 44 42 3 0 11 -10 18 -23z m579 -1 c6 -13 25 -31 43 -40 l32 -17 -26 -16
c-13 -10 -33 -28 -44 -40 l-19 -24 -41 41 -41 41 37 39 c20 21 39 39 42 40 3
0 11 -10 17 -24z m290 0 c7 -14 23 -30 37 -37 31 -14 31 -21 0 -38 -14 -7 -25
-17 -25 -22 0 -5 -6 -16 -14 -23 -12 -13 -20 -9 -54 25 l-40 40 37 39 c20 21
39 39 42 40 3 0 11 -10 17 -24z m590 0 c6 -14 15 -25 19 -25 5 0 16 -7 26 -14
16 -12 14 -16 -24 -55 -23 -22 -43 -41 -44 -41 -1 0 -20 17 -41 38 l-39 38 40
41 c22 22 43 41 46 41 3 1 11 -9 17 -23z m281 2 c7 -13 25 -31 41 -40 l28 -17
-46 -46 c-31 -30 -48 -41 -51 -32 -2 7 -19 28 -37 45 l-32 33 37 39 c20 21 39
39 42 40 3 0 11 -9 18 -22z m286 3 c4 -11 19 -28 33 -37 15 -9 29 -18 31 -20
3 -2 -12 -18 -32 -37 -20 -19 -37 -39 -37 -45 0 -18 -18 -12 -25 9 -3 10 -19
30 -35 44 l-30 24 38 40 c44 47 49 48 57 22z m590 0 c5 -16 29 -38 62 -55 2
-1 -16 -21 -40 -46 l-42 -44 -40 41 -40 41 40 40 c48 47 52 49 60 23z m331
-49 c21 -10 20 -12 -24 -57 -26 -25 -48 -41 -50 -35 -8 23 -54 71 -68 71 -8 0
4 18 28 43 l43 42 25 -26 c14 -15 35 -32 46 -38z m592 -2 c8 -3 -3 -21 -32
-52 -26 -26 -46 -44 -46 -40 0 13 -44 59 -63 66 -16 6 -12 13 25 49 l43 43 30
-31 c17 -17 36 -33 43 -35z m290 0 c8 -3 -2 -20 -30 -49 l-43 -45 -40 40 -40
40 40 40 40 40 30 -31 c17 -17 36 -33 43 -35z m235 41 c10 -16 24 -30 31 -30
35 0 31 -23 -12 -66 -25 -24 -46 -43 -47 -42 -22 40 -41 61 -60 70 l-23 10 41
43 c23 23 44 43 47 43 3 1 14 -11 23 -28z m610 -83 c-18 -16 -33 -35 -33 -43
0 -23 -14 -16 -32 16 -9 17 -28 35 -42 41 l-24 11 41 42 42 41 41 -40 40 -39
-33 -29z m282 -11 l-40 -41 -40 40 -40 40 40 40 40 40 40 -39 40 -38 -40 -42z
m586 -3 c-20 -22 -38 -42 -41 -42 -3 0 -13 13 -22 29 -9 17 -28 35 -42 41
l-24 11 41 42 42 41 41 -40 41 -40 -36 -42z m292 14 c-18 -16 -33 -35 -33 -43
0 -23 -14 -16 -32 16 -9 17 -28 35 -42 41 l-24 11 41 42 42 41 41 -40 40 -39
-33 -29z m282 -11 l-40 -41 -40 40 -40 40 40 40 40 40 40 -39 40 -38 -40 -42z
m586 -3 c-20 -22 -38 -42 -41 -42 -3 0 -13 13 -22 29 -9 17 -28 35 -42 41
l-24 11 41 42 42 41 41 -40 42 -40 -37 -42z m292 10 c-18 -17 -33 -36 -33 -42
0 -20 -15 -11 -32 19 -9 17 -28 35 -42 41 l-24 11 41 42 42 41 40 -40 40 -40
-32 -32z m557 96 c0 -13 19 -33 46 -47 21 -11 20 -13 -20 -54 l-41 -43 -40 40
-40 40 40 40 c38 38 55 46 55 24z m311 -106 c-20 -22 -38 -42 -41 -42 -3 0
-13 13 -22 29 -9 17 -28 35 -42 41 l-24 11 41 42 42 41 41 -40 40 -40 -35 -42z
m292 9 c-18 -18 -33 -37 -33 -42 0 -19 -15 -9 -32 20 -9 17 -28 35 -42 41
l-24 11 41 42 42 41 41 -40 40 -40 -33 -33z m582 -7 l-40 -41 -41 40 -40 40
40 41 41 40 40 -39 40 -40 -40 -41z m286 -2 c-20 -22 -39 -42 -42 -42 -3 -1
-12 11 -19 25 -8 14 -26 32 -41 41 l-27 16 42 41 41 41 41 -40 40 -40 -35 -42z
m584 2 c-32 -32 -43 -38 -52 -29 -7 7 -13 16 -13 21 0 4 -13 17 -29 27 l-28
19 41 41 41 41 40 -39 40 -40 -40 -41z m290 0 l-40 -41 -41 40 -40 40 40 41
41 40 40 -39 40 -40 -40 -41z m267 95 c9 -17 28 -35 41 -41 23 -10 23 -10 -21
-55 -41 -41 -46 -43 -58 -27 -7 10 -14 21 -14 25 0 3 -14 15 -31 25 l-30 20
35 24 c20 13 39 32 42 42 8 24 17 21 36 -13z m-6603 -334 c34 -13 34 -14 10
-21 -31 -10 -78 -53 -91 -85 l-10 -25 -12 30 c-16 37 -40 58 -86 78 -25 11
-29 16 -15 16 31 2 84 38 97 67 l12 25 30 -35 c17 -20 46 -42 65 -50z m284 4
c35 -16 37 -19 18 -25 -32 -10 -81 -55 -93 -84 l-10 -26 -9 24 c-12 32 -56 68
-98 82 l-34 12 44 14 c41 14 89 56 89 78 0 6 12 -5 27 -24 14 -18 44 -41 66
-51z m225 49 c14 -18 44 -40 65 -49 36 -16 38 -18 17 -25 -29 -9 -100 -74
-100 -91 0 -8 -4 -14 -10 -14 -5 0 -10 6 -10 14 0 24 -42 68 -84 86 -27 12
-34 19 -21 19 30 1 84 38 97 66 6 14 13 25 15 25 2 0 16 -14 31 -31z m645 -49
c35 -16 37 -19 18 -25 -32 -10 -81 -55 -93 -84 l-10 -26 -12 30 c-16 37 -40
58 -86 78 -25 11 -29 16 -15 16 31 2 84 38 97 66 l12 26 25 -32 c13 -17 42
-39 64 -49z m290 0 c35 -16 37 -19 18 -25 -32 -10 -81 -55 -93 -84 l-10 -26
-10 25 c-10 27 -47 70 -59 70 -4 0 -20 5 -36 11 l-28 11 40 16 c39 15 85 57
85 77 0 6 12 -5 27 -24 14 -18 44 -41 66 -51z m507 70 c0 -15 55 -70 71 -70 8
0 21 -4 28 -9 12 -8 9 -12 -15 -22 -41 -18 -84 -59 -84 -81 0 -10 -4 -18 -10
-18 -5 0 -10 6 -10 14 0 24 -42 68 -84 86 -27 12 -34 19 -21 19 30 1 84 38 97
66 11 25 28 34 28 15z m381 -80 c21 0 19 -3 -16 -23 -22 -12 -51 -40 -65 -61
-15 -23 -26 -32 -29 -24 -10 33 -47 72 -85 88 -27 12 -34 19 -21 19 30 1 84
38 96 65 l11 24 42 -44 c27 -28 51 -44 67 -44z m290 0 c21 0 19 -3 -16 -23
-22 -12 -51 -40 -65 -61 -15 -23 -26 -32 -29 -24 -10 33 -47 72 -85 88 -27 12
-34 19 -21 19 30 1 84 38 96 65 l11 24 42 -44 c27 -28 51 -44 67 -44z m489 79
c0 -19 62 -70 92 -76 l29 -6 -42 -24 c-47 -26 -79 -59 -79 -80 0 -24 -15 -14
-35 25 -13 24 -35 44 -65 59 -29 14 -38 22 -25 23 30 0 84 37 97 65 11 26 28
34 28 14z m373 -77 l32 -8 -40 -20 c-22 -10 -51 -36 -64 -57 l-24 -37 -21 37
c-13 23 -38 45 -66 59 -29 15 -38 24 -25 24 30 0 84 37 97 65 l11 25 34 -40
c19 -22 48 -43 66 -48z m603 -3 c28 0 28 -1 -12 -19 -38 -16 -63 -40 -89 -85
-9 -15 -13 -12 -35 21 -14 21 -44 47 -68 59 l-44 22 33 7 c19 3 47 22 68 44
l36 37 41 -42 c29 -31 49 -43 70 -44z m184 80 c0 -23 76 -79 109 -80 23 0 22
-2 -15 -18 -38 -18 -94 -72 -94 -92 0 -20 -18 -7 -30 21 -14 33 -48 61 -95 78
-29 10 -29 10 -5 11 35 1 88 36 102 66 11 25 28 34 28 14z m386 -79 c28 -1 27
-2 -16 -23 -27 -13 -55 -37 -70 -60 l-24 -38 -18 35 c-18 36 -38 51 -93 73
-29 11 -29 12 -5 12 34 1 88 36 101 65 l11 24 42 -44 c31 -32 51 -44 72 -44z
m590 -1 c28 0 28 -1 -12 -19 -38 -16 -62 -40 -90 -86 -11 -17 -13 -16 -29 17
-16 33 -37 48 -105 77 l-25 11 24 0 c29 1 101 41 101 56 0 6 5 16 11 22 8 8
22 -1 53 -33 31 -32 51 -44 72 -45z m216 46 c32 -32 54 -45 74 -46 28 0 27 -1
-12 -18 -38 -18 -94 -72 -94 -92 0 -20 -16 -7 -35 28 -14 26 -34 43 -70 59
-40 18 -45 23 -25 23 34 1 88 36 102 66 6 14 12 25 13 25 1 0 23 -20 47 -45z
m654 -45 c28 -1 27 -2 -16 -23 -26 -13 -55 -37 -69 -59 l-24 -38 -23 38 c-15
25 -38 44 -71 59 -39 18 -43 22 -23 22 34 1 88 36 101 65 l11 24 42 -44 c31
-32 51 -44 72 -44z m214 40 c0 -11 59 -40 82 -40 18 -1 13 -6 -22 -23 -27 -13
-55 -37 -70 -60 l-24 -38 -21 35 c-11 20 -26 36 -32 36 -7 0 -13 4 -13 8 0 5
-19 15 -42 22 -31 10 -37 15 -23 17 59 12 124 57 125 88 0 10 39 -35 40 -45z
m270 25 c17 -31 69 -65 101 -66 19 0 16 -4 -18 -19 -26 -11 -54 -35 -70 -59
-15 -22 -30 -40 -33 -41 -3 0 -14 16 -24 35 -12 22 -35 42 -64 56 l-44 22 37
12 c42 15 72 39 80 66 9 26 19 24 35 -6z"
      />
      <path
        d="M0 5671 l0 -281 30 0 c64 0 120 49 120 103 0 25 2 28 15 17 8 -7 12
-16 9 -21 -9 -14 35 -68 69 -86 59 -30 133 -12 176 43 15 19 21 41 21 80 0 46
-4 57 -34 88 -18 20 -43 36 -55 36 -32 0 -25 13 18 33 126 58 95 249 -42 264
-56 7 -107 -17 -139 -63 -26 -39 -34 -42 -42 -16 -11 35 -62 73 -105 79 l-41
6 0 -282z m193 55 c11 -15 38 -36 59 -46 l39 -17 -39 -16 c-43 -17 -82 -63
-82 -96 0 -12 -4 -21 -10 -21 -5 0 -10 9 -10 20 0 43 -37 88 -79 96 -17 4 -31
9 -31 13 1 3 19 15 42 26 27 14 48 34 62 60 12 24 22 33 24 24 3 -8 14 -27 25
-43z"
      />
      <path
        d="M555 5943 c-36 -10 -63 -34 -80 -70 -34 -71 2 -159 79 -194 34 -15
37 -19 21 -24 -73 -23 -108 -64 -108 -129 0 -53 20 -88 71 -119 60 -37 133
-22 181 39 25 32 28 101 7 143 -16 31 -56 61 -81 61 -27 0 -16 15 30 40 25 14
45 30 45 37 0 7 5 13 10 13 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 -9 10 -20
0 -11 4 -20 9 -20 5 0 11 -9 14 -20 3 -13 22 -27 46 -37 44 -17 54 -33 20 -33
-31 0 -89 -62 -90 -96 -3 -81 11 -111 69 -147 87 -55 202 9 202 112 0 71 -31
112 -104 135 -16 6 -13 10 22 27 40 20 73 55 84 89 3 9 12 0 24 -25 13 -28 31
-46 61 -60 46 -22 55 -35 23 -35 -31 0 -80 -51 -90 -93 -17 -79 28 -148 109
-166 109 -24 191 85 143 190 -16 35 -59 69 -88 69 -26 0 -15 16 20 28 28 10
100 82 82 82 -2 0 -1 13 4 30 10 35 -13 91 -54 129 -56 53 -172 32 -206 -37
-15 -31 -30 -42 -30 -22 0 5 -15 26 -34 46 -28 30 -42 36 -87 40 -65 6 -115
-17 -139 -64 -17 -36 -26 -40 -34 -14 -4 10 -19 31 -36 47 -22 21 -40 29 -77
31 -26 1 -57 0 -68 -3z"
      />
      <path
        d="M1413 5933 c-54 -30 -71 -62 -70 -124 2 -68 25 -106 78 -128 47 -20
49 -27 6 -35 -18 -3 -41 -17 -52 -32 -27 -37 -47 -96 -35 -108 6 -6 10 -18 10
-28 0 -34 52 -78 104 -87 44 -8 54 -6 87 15 20 13 44 33 53 46 20 28 21 100 2
137 -16 31 -56 61 -81 61 -27 0 -16 14 27 35 28 13 48 33 61 58 l20 39 12 -29
c14 -34 57 -71 92 -79 26 -7 21 -24 -7 -24 -31 0 -80 -51 -90 -93 -18 -81 29
-150 115 -166 43 -8 53 -6 86 15 20 13 44 33 53 46 20 28 21 100 2 137 -16 31
-56 61 -81 61 -24 0 -17 19 10 26 14 3 25 10 25 15 0 5 7 9 15 9 8 0 23 18 32
40 19 43 33 52 33 21 0 -23 47 -71 83 -83 31 -12 36 -28 7 -28 -31 0 -80 -51
-90 -93 -17 -79 30 -151 111 -166 84 -15 159 45 159 128 0 71 -32 112 -104
135 -15 5 -10 11 25 29 27 14 52 38 65 61 l21 38 19 -39 c13 -26 32 -44 61
-58 46 -22 55 -35 23 -35 -31 0 -80 -51 -90 -93 -17 -79 30 -151 111 -166 109
-19 189 86 143 190 -16 36 -58 69 -88 69 -28 0 -17 14 22 31 50 21 80 72 78
131 -2 52 -24 91 -69 119 -67 42 -169 5 -199 -71 l-10 -25 -12 30 c-48 112
-223 109 -267 -4 l-10 -24 -17 34 c-9 18 -33 44 -52 57 -30 20 -43 23 -89 19
-43 -5 -61 -12 -87 -38 -19 -18 -34 -39 -34 -46 0 -22 -14 -14 -32 17 -36 64
-121 88 -185 53z"
      />
      <path
        d="M2585 5940 c-43 -18 -78 -67 -82 -117 -6 -65 19 -111 77 -142 l44
-25 -34 -9 c-65 -17 -98 -83 -80 -162 12 -53 46 -83 104 -94 44 -8 54 -6 87
15 51 32 69 61 69 112 0 73 -30 113 -99 132 -25 6 -24 8 21 27 36 16 52 31 69
64 l21 43 13 -31 c13 -31 72 -83 93 -83 7 0 12 -4 12 -10 0 -5 -9 -10 -20 -10
-31 0 -80 -51 -90 -93 -11 -53 4 -100 46 -136 19 -17 38 -31 42 -32 4 0 22 -2
41 -4 39 -3 98 28 125 67 20 28 21 100 1 138 -15 30 -61 60 -90 60 -8 0 -15 5
-15 10 0 6 7 10 15 10 34 0 76 32 93 70 19 46 27 49 36 18 8 -32 63 -88 87
-88 10 0 19 -4 19 -10 0 -5 -9 -10 -19 -10 -31 0 -80 -48 -91 -89 -14 -54 3
-103 51 -144 45 -39 89 -43 146 -14 69 36 94 117 58 187 -15 30 -61 60 -90 60
-27 0 -15 14 28 35 83 39 108 146 50 215 -66 79 -207 56 -244 -39 l-10 -24
-17 34 c-31 62 -114 96 -173 71 -34 -14 -89 -66 -89 -84 0 -28 -13 -21 -30 15
-29 61 -112 94 -175 67z"
      />
      <path
        d="M3455 5940 c-11 -4 -33 -22 -50 -40 -26 -27 -30 -39 -30 -90 0 -70
21 -105 80 -134 l39 -20 -28 -7 c-66 -18 -104 -90 -86 -161 15 -54 79 -106
128 -103 79 4 133 51 140 123 7 77 -35 132 -113 144 -15 3 -7 10 30 28 35 17
57 37 72 64 l21 39 15 -34 c17 -40 41 -63 82 -80 l30 -12 -29 -8 c-109 -31
-123 -197 -22 -251 45 -24 121 -11 163 29 34 31 43 115 18 163 -16 30 -40 48
-84 60 -25 6 -24 8 21 27 36 16 52 31 69 64 l21 43 13 -30 c15 -36 50 -69 90
-85 l30 -12 -35 -8 c-108 -24 -127 -185 -28 -243 49 -29 98 -28 143 3 22 15
45 38 52 51 17 34 16 95 -2 130 -15 30 -61 60 -90 60 -8 0 -15 5 -15 10 0 6 7
10 15 10 33 0 76 32 96 72 l21 42 13 -30 c15 -36 50 -69 90 -85 l30 -12 -33
-7 c-69 -16 -111 -89 -92 -160 32 -110 160 -141 239 -56 31 33 40 110 17 154
-16 31 -61 62 -91 62 -27 0 -16 15 25 32 42 18 76 63 86 115 8 43 -21 101 -66
131 -70 47 -181 12 -211 -67 l-10 -24 -17 34 c-41 82 -143 103 -216 43 -19
-16 -38 -39 -42 -51 -8 -31 -16 -29 -34 10 -37 80 -148 101 -211 41 -17 -16
-35 -40 -40 -53 l-9 -23 -23 33 c-45 67 -120 95 -182 69z"
      />
      <path
        d="M4615 5940 c-11 -4 -33 -22 -50 -40 -26 -28 -30 -38 -30 -90 1 -72
21 -105 86 -137 41 -21 42 -22 16 -23 -40 0 -92 -54 -101 -103 -14 -75 54
-161 129 -162 76 -2 135 49 143 123 7 79 -27 126 -103 142 l-29 6 49 24 c35
18 57 37 72 65 l22 39 11 -29 c16 -42 39 -65 85 -86 37 -16 38 -18 13 -18 -64
-2 -118 -91 -98 -160 25 -87 95 -127 173 -98 9 4 23 9 31 11 23 8 49 54 56
102 6 36 3 51 -17 84 -13 22 -25 40 -26 40 -1 0 -20 6 -41 14 l-39 14 46 20
c35 15 51 31 68 63 l21 43 13 -30 c16 -36 50 -68 95 -87 l35 -15 -34 -1 c-25
-1 -42 -10 -67 -37 -79 -84 -25 -218 92 -229 106 -10 182 117 124 209 -12 20
-22 36 -23 36 -1 0 -20 6 -41 14 l-39 14 46 20 c55 24 80 66 81 134 2 82 -67
143 -152 135 -34 -4 -53 -13 -83 -43 -21 -21 -39 -44 -39 -51 0 -23 -15 -14
-30 19 -24 50 -63 73 -125 73 -46 0 -58 -4 -85 -30 -18 -16 -37 -41 -42 -55
-8 -18 -12 -21 -15 -10 -8 23 -55 70 -85 86 -30 15 -82 17 -113 4z"
      />
      <path
        d="M12755 5938 c-120 -42 -126 -207 -10 -262 19 -10 40 -14 45 -11 12 7
14 285 3 284 -5 0 -21 -5 -38 -11z"
      />
      <path
        d="M8079 5635 c-85 -46 -82 -182 5 -233 30 -18 43 -20 86 -13 82 13 126
69 117 150 -4 36 -13 52 -41 78 -31 29 -43 33 -88 33 -29 -1 -65 -7 -79 -15z"
      />
      <path
        d="M8369 5636 c-86 -49 -85 -177 1 -231 33 -21 45 -23 89 -16 84 13 117
59 108 150 -8 77 -36 104 -114 108 -38 2 -67 -2 -84 -11z"
      />
      <path
        d="M8665 5634 c-53 -27 -76 -104 -50 -170 22 -58 70 -85 136 -77 114 14
159 153 75 230 -30 28 -43 33 -83 32 -27 0 -62 -7 -78 -15z"
      />
      <path
        d="M8949 5635 c-87 -47 -83 -187 8 -233 46 -24 120 -17 154 14 63 57 65
146 5 201 -31 29 -43 33 -88 33 -29 -1 -65 -7 -79 -15z"
      />
      <path
        d="M9239 5635 c-40 -21 -63 -64 -63 -115 0 -53 22 -91 69 -119 29 -17
43 -19 85 -12 82 13 126 69 117 150 -4 36 -13 52 -41 78 -31 29 -43 33 -88 33
-29 -1 -65 -7 -79 -15z"
      />
      <path
        d="M9539 5635 c-40 -21 -63 -64 -63 -115 0 -81 63 -140 143 -134 51 3
85 24 111 67 35 57 19 133 -37 177 -31 24 -114 27 -154 5z"
      />
      <path
        d="M9829 5635 c-42 -23 -63 -64 -63 -124 0 -34 4 -56 12 -59 7 -2 12
-10 12 -18 0 -7 17 -22 39 -33 32 -17 48 -19 89 -12 58 9 75 20 97 64 30 60
13 133 -41 176 -32 25 -105 28 -145 6z"
      />
      <path
        d="M10109 5635 c-40 -22 -61 -62 -61 -115 0 -89 66 -144 154 -130 123
18 160 162 62 239 -32 25 -114 28 -155 6z"
      />
      <path
        d="M10399 5635 c-40 -21 -63 -64 -63 -115 0 -53 22 -91 69 -119 29 -17
43 -19 85 -12 27 4 60 17 71 27 67 61 64 157 -7 213 -32 25 -114 28 -155 6z"
      />
      <path
        d="M10699 5635 c-87 -47 -87 -174 -1 -229 36 -22 46 -24 91 -17 28 4 61
17 72 27 67 61 64 157 -7 213 -32 25 -114 28 -155 6z"
      />
      <path
        d="M10989 5635 c-42 -23 -63 -64 -62 -121 0 -62 14 -89 56 -111 35 -18
117 -19 149 -2 51 27 73 120 43 179 -34 65 -120 91 -186 55z"
      />
      <path
        d="M11273 5637 c-42 -24 -65 -65 -65 -116 0 -86 61 -144 142 -135 54 6
85 25 110 67 36 58 20 132 -36 176 -31 24 -114 29 -151 8z"
      />
      <path
        d="M11569 5635 c-52 -28 -79 -100 -59 -160 6 -19 28 -48 48 -64 32 -25
44 -28 87 -25 58 5 89 23 115 67 38 62 13 152 -50 184 -33 17 -109 16 -141 -2z"
      />
      <path
        d="M11859 5635 c-80 -44 -87 -162 -12 -224 32 -26 41 -29 87 -25 59 6
89 23 116 67 38 61 18 140 -43 177 -38 23 -110 26 -148 5z"
      />
      <path
        d="M12149 5635 c-84 -46 -84 -188 1 -237 30 -17 107 -16 142 3 55 30 76
135 37 190 -39 56 -122 76 -180 44z"
      />
      <path
        d="M12438 5637 c-48 -22 -69 -60 -67 -122 3 -69 35 -112 94 -125 71 -16
130 12 164 77 26 51 1 131 -51 163 -37 22 -99 26 -140 7z"
      />
      <path
        d="M12724 5631 c-102 -62 -71 -217 49 -245 l27 -6 0 135 c0 133 0 135
-22 135 -13 0 -37 -9 -54 -19z"
      />
      <path
        d="M0 5233 c0 -150 -1 -150 76 -113 28 14 48 33 59 57 40 85 -11 187
-95 189 l-40 1 0 -134z"
      />
      <path
        d="M239 5354 c-14 -9 -35 -31 -47 -48 -74 -108 52 -245 172 -187 65 32
92 99 66 166 -28 73 -126 109 -191 69z"
      />
      <path
        d="M536 5351 c-61 -41 -84 -118 -52 -180 47 -90 197 -87 241 6 27 56 15
110 -34 155 -34 32 -47 38 -83 38 -25 0 -55 -8 -72 -19z"
      />
      <path
        d="M826 5351 c-92 -61 -87 -190 9 -235 96 -46 206 33 192 138 -8 60 -70
116 -129 116 -25 0 -55 -8 -72 -19z"
      />
      <path
        d="M1113 5353 c-88 -44 -82 -193 10 -236 45 -22 62 -21 113 3 58 29 82
82 73 163 -1 10 -20 33 -41 52 -31 29 -46 35 -81 34 -23 0 -56 -7 -74 -16z"
      />
      <path
        d="M1389 5331 c-36 -36 -39 -43 -39 -94 0 -49 4 -60 33 -90 84 -90 227
-30 227 95 0 72 -60 128 -138 128 -38 0 -50 -5 -83 -39z"
      />
      <path
        d="M1674 5326 c-39 -38 -44 -49 -44 -87 0 -78 59 -139 135 -139 44 0
101 36 120 76 27 57 16 110 -34 156 -35 33 -47 38 -87 38 -42 0 -51 -5 -90
-44z"
      />
      <path
        d="M1964 5326 c-39 -38 -44 -49 -44 -87 0 -142 184 -191 252 -68 31 55
20 114 -31 161 -35 33 -47 38 -87 38 -42 0 -51 -5 -90 -44z"
      />
      <path
        d="M2269 5347 c-42 -28 -59 -60 -59 -112 0 -107 115 -168 205 -109 27
18 65 85 65 116 0 24 -43 92 -72 112 -33 24 -99 20 -139 -7z"
      />
      <path
        d="M2549 5331 c-35 -34 -39 -44 -39 -87 0 -61 28 -110 75 -130 46 -19
73 -18 114 4 68 37 91 129 48 193 -29 43 -60 59 -116 59 -37 0 -49 -5 -82 -39z"
      />
      <path
        d="M2835 5328 c-40 -40 -45 -50 -45 -90 0 -121 145 -182 229 -97 54 54
53 125 -2 186 -28 30 -42 36 -85 41 -51 4 -53 4 -97 -40z"
      />
      <path
        d="M3125 5328 c-40 -40 -45 -50 -45 -90 0 -122 144 -182 229 -97 54 54
53 125 -2 186 -28 30 -42 36 -85 41 -51 4 -53 4 -97 -40z"
      />
      <path
        d="M3450 5354 c-14 -10 -30 -23 -36 -29 -60 -58 -37 -178 41 -211 45
-18 66 -18 114 3 30 13 44 28 60 63 12 26 21 54 21 64 0 25 -24 67 -54 95 -34
32 -111 40 -146 15z"
      />
      <path
        d="M3709 5331 c-35 -34 -39 -44 -39 -87 0 -82 57 -144 132 -144 37 0 90
34 110 71 50 94 -14 199 -122 199 -36 0 -48 -6 -81 -39z"
      />
      <path
        d="M3994 5326 c-37 -37 -44 -50 -44 -83 0 -79 44 -129 122 -139 76 -9
148 53 148 129 0 72 -70 137 -147 137 -29 0 -44 -8 -79 -44z"
      />
      <path
        d="M4284 5326 c-39 -38 -44 -49 -44 -87 0 -107 106 -171 199 -121 39 21
71 73 71 115 0 68 -72 137 -144 137 -32 0 -46 -7 -82 -44z"
      />
      <path
        d="M4609 5354 c-130 -81 -63 -272 88 -249 24 3 43 10 43 16 0 5 6 9 13
9 15 0 49 54 40 64 -3 3 -1 6 5 6 7 0 12 14 12 30 0 98 -122 173 -201 124z"
      />
      <path
        d="M4904 5360 c-11 -4 -33 -22 -47 -40 -51 -60 -28 -168 42 -205 111
-57 235 72 176 185 -29 56 -111 85 -171 60z"
      />
      <path
        d="M5154 5326 c-36 -36 -44 -50 -44 -81 0 -76 66 -145 138 -145 65 0
132 67 132 132 0 29 -18 75 -35 94 -22 23 -75 44 -111 44 -30 0 -45 -8 -80
-44z"
      />
      <path
        d="M5454 5326 c-39 -38 -44 -49 -44 -87 0 -108 105 -172 199 -121 37 20
71 73 71 110 0 71 -72 142 -144 142 -32 0 -46 -7 -82 -44z"
      />
      <path
        d="M5744 5326 c-39 -38 -44 -49 -44 -87 0 -90 67 -148 154 -135 122 19
155 163 54 237 -63 45 -109 41 -164 -15z"
      />
      <path
        d="M6064 5360 c-11 -4 -33 -22 -47 -40 -68 -80 -4 -220 100 -220 66 0
133 67 133 133 0 95 -98 163 -186 127z"
      />
      <path
        d="M6314 5326 c-36 -36 -44 -50 -44 -81 0 -76 66 -145 138 -145 62 0
132 67 132 126 0 27 -20 86 -35 100 -23 23 -75 44 -111 44 -30 0 -45 -8 -80
-44z"
      />
      <path
        d="M6614 5326 c-39 -38 -44 -49 -44 -87 0 -108 105 -172 199 -121 37 20
71 73 71 110 0 71 -72 142 -144 142 -32 0 -46 -7 -82 -44z"
      />
      <path
        d="M6944 5360 c-11 -4 -34 -24 -52 -44 -81 -93 -2 -230 122 -212 122 19
155 163 54 237 -42 29 -83 36 -124 19z"
      />
      <path
        d="M7225 5360 c-64 -26 -95 -123 -61 -189 21 -39 70 -71 111 -71 69 0
135 65 135 133 0 95 -99 163 -185 127z"
      />
      <path
        d="M7535 5360 c-4 -6 -10 -8 -14 -6 -18 12 -64 -54 -71 -101 -9 -56 9
-96 60 -130 40 -28 84 -29 129 -5 86 46 93 172 13 227 -38 26 -105 34 -117 15z"
      />
      <path
        d="M7825 5360 c-4 -6 -10 -8 -15 -5 -4 3 -24 -13 -44 -35 -30 -33 -36
-48 -36 -83 0 -54 17 -86 59 -114 64 -43 156 -20 193 48 33 62 14 130 -48 173
-39 26 -97 35 -109 16z"
      />
      <path
        d="M8115 5360 c-4 -6 -10 -8 -15 -5 -4 3 -24 -13 -44 -35 -30 -33 -36
-48 -36 -83 0 -54 17 -86 59 -114 64 -43 156 -20 193 48 33 62 14 130 -48 173
-39 26 -97 35 -109 16z"
      />
      <path
        d="M8385 5356 c-54 -24 -70 -51 -70 -122 0 -58 3 -66 31 -95 57 -57 156
-46 205 22 18 25 27 76 19 102 -1 4 -3 10 -4 15 -9 43 -75 92 -122 92 -16 -1
-42 -7 -59 -14z"
      />
      <path
        d="M8684 5360 c-11 -4 -33 -23 -48 -41 -25 -30 -28 -39 -24 -92 4 -48 9
-63 35 -89 86 -86 234 -11 219 110 -7 50 -23 77 -63 103 -29 20 -83 24 -119 9z"
      />
      <path
        d="M8974 5360 c-11 -4 -34 -24 -52 -44 -27 -31 -32 -45 -32 -85 0 -135
188 -180 252 -60 23 43 23 75 -1 119 -34 65 -106 95 -167 70z"
      />
      <path
        d="M9264 5360 c-11 -4 -34 -24 -52 -44 -27 -31 -32 -45 -32 -86 0 -43 5
-56 33 -86 28 -31 41 -37 83 -41 81 -7 154 52 154 125 0 90 -105 165 -186 132z"
      />
      <path
        d="M9557 5355 c-56 -29 -77 -65 -77 -129 0 -136 189 -172 250 -48 25 50
22 88 -11 130 -46 59 -106 77 -162 47z"
      />
      <path
        d="M9844 5360 c-11 -4 -33 -22 -47 -40 -22 -26 -27 -42 -27 -86 0 -47 4
-59 33 -90 102 -112 278 12 212 149 -29 62 -108 93 -171 67z"
      />
      <path
        d="M10134 5360 c-11 -4 -34 -24 -52 -44 -26 -30 -32 -45 -32 -84 0 -139
195 -182 255 -56 49 104 -64 227 -171 184z"
      />
      <path
        d="M10424 5361 c-11 -5 -34 -25 -52 -45 -68 -78 -25 -199 75 -213 56 -7
86 1 121 37 77 77 25 214 -86 226 -20 2 -46 0 -58 -5z"
      />
      <path
        d="M10724 5361 c-11 -5 -34 -25 -52 -45 -27 -31 -32 -45 -32 -86 0 -43
5 -56 34 -88 33 -35 38 -37 97 -37 41 0 64 4 67 13 2 6 9 12 15 12 17 1 43 40
51 76 9 42 -18 109 -56 135 -33 24 -93 33 -124 20z"
      />
      <path
        d="M11004 5360 c-11 -4 -33 -22 -47 -40 -22 -26 -27 -42 -27 -86 0 -47
4 -59 33 -90 65 -71 175 -52 211 36 21 52 20 65 -4 116 -30 62 -103 90 -166
64z"
      />
      <path
        d="M11294 5361 c-11 -5 -34 -25 -52 -45 -95 -108 31 -263 163 -200 52
24 82 90 66 143 -15 53 -70 102 -117 107 -22 2 -48 0 -60 -5z"
      />
      <path
        d="M11594 5360 c-11 -4 -34 -24 -52 -44 -27 -31 -32 -45 -32 -86 0 -43
5 -56 33 -86 28 -31 42 -37 84 -41 134 -13 197 131 100 225 -36 35 -91 48
-133 32z"
      />
      <path
        d="M11873 5350 c-51 -31 -73 -67 -73 -121 0 -139 190 -179 250 -54 18
37 20 50 11 82 -23 86 -120 134 -188 93z"
      />
      <path
        d="M12148 5345 c-48 -33 -58 -52 -58 -114 0 -44 5 -56 33 -87 26 -29 42
-37 79 -41 56 -6 110 21 126 65 21 56 22 65 12 101 -23 88 -120 125 -192 76z"
      />
      <path
        d="M12439 5345 c-47 -31 -69 -66 -69 -110 0 -71 47 -123 119 -132 130
-15 193 126 101 225 -17 17 -36 32 -43 33 -7 0 -26 2 -43 4 -20 3 -41 -3 -65
-20z"
      />
      <path
        d="M12732 5341 c-51 -38 -62 -58 -62 -117 0 -41 5 -51 39 -85 25 -26 48
-39 65 -39 l27 0 -3 132 c-2 82 -7 133 -14 135 -6 2 -29 -10 -52 -26z"
      />
      <path
        d="M0 4950 l0 -130 29 0 c40 0 94 40 108 80 31 86 -28 180 -112 180
l-25 0 0 -130z"
      />
      <path
        d="M245 5062 c-33 -21 -55 -44 -59 -64 -2 -7 -6 -21 -11 -30 -4 -10 -4
-26 0 -35 5 -10 9 -24 11 -32 1 -8 16 -28 32 -44 64 -63 180 -39 209 43 42
118 -78 225 -182 162z"
      />
      <path
        d="M539 5057 c-19 -12 -42 -40 -53 -61 -16 -32 -17 -44 -7 -76 6 -21 27
-52 46 -69 92 -80 232 4 209 126 -11 59 -72 103 -144 103 -9 0 -32 -10 -51
-23z"
      />
      <path
        d="M819 5057 c-69 -46 -76 -142 -14 -204 27 -27 41 -33 76 -33 148 0
196 182 65 244 -47 22 -87 20 -127 -7z"
      />
      <path
        d="M1109 5057 c-69 -46 -76 -142 -14 -204 27 -27 41 -33 77 -33 48 0
100 27 119 61 20 39 18 111 -4 141 -43 58 -121 73 -178 35z"
      />
      <path
        d="M1415 5061 c-29 -17 -73 -71 -59 -71 4 0 1 -6 -5 -14 -8 -9 -8 -21
-2 -39 5 -14 7 -27 5 -30 -3 -3 12 -23 34 -46 34 -36 44 -41 84 -41 139 0 187
185 63 244 -44 21 -83 20 -120 -3z"
      />
      <path
        d="M1699 5057 c-19 -12 -42 -40 -53 -61 -16 -32 -17 -44 -7 -76 17 -57
71 -100 125 -100 137 0 184 186 61 244 -46 22 -86 20 -126 -7z"
      />
      <path
        d="M1979 5057 c-69 -46 -76 -142 -14 -204 27 -27 41 -33 76 -33 148 0
196 182 65 244 -47 22 -87 20 -127 -7z"
      />
      <path
        d="M2269 5057 c-69 -46 -76 -142 -14 -204 29 -29 40 -33 84 -33 46 0 55
4 91 40 23 23 38 45 35 51 -4 5 -1 16 5 24 7 8 8 20 4 27 -5 7 -9 18 -9 23 0
22 -38 64 -70 79 -46 22 -86 20 -126 -7z"
      />
      <path
        d="M2584 5066 c-29 -13 -85 -76 -68 -76 5 0 3 -5 -3 -11 -7 -7 -8 -21
-3 -39 5 -16 6 -31 3 -33 -2 -3 13 -23 35 -46 35 -37 44 -41 87 -41 138 0 184
186 60 244 -40 19 -70 20 -111 2z"
      />
      <path
        d="M2874 5066 c-65 -29 -99 -108 -70 -164 28 -53 72 -82 126 -82 41 0
54 5 83 33 45 43 55 92 29 150 -30 67 -99 93 -168 63z"
      />
      <path
        d="M3163 5069 c-39 -11 -70 -49 -79 -95 -9 -49 2 -82 41 -121 28 -28 40
-33 83 -33 47 0 90 21 114 55 43 59 15 159 -52 188 -44 19 -60 20 -107 6z"
      />
      <path
        d="M3453 5065 c-60 -26 -90 -113 -59 -172 25 -50 62 -73 117 -73 40 0
55 5 83 31 35 30 61 92 48 113 -3 6 -7 16 -8 22 -4 25 -37 63 -69 78 -40 19
-70 20 -112 1z"
      />
      <path
        d="M3743 5065 c-86 -37 -96 -162 -17 -224 40 -31 113 -29 153 5 91 76
34 236 -82 234 -12 -1 -36 -7 -54 -15z"
      />
      <path
        d="M4034 5066 c-65 -29 -99 -108 -70 -164 42 -82 142 -110 205 -56 91
76 34 236 -82 234 -12 -1 -36 -7 -53 -14z"
      />
      <path
        d="M4326 5070 c-90 -28 -111 -147 -39 -219 19 -19 37 -26 82 -29 51 -4
61 -1 85 22 16 14 34 42 41 62 33 97 -69 196 -169 164z"
      />
      <path
        d="M4610 5062 c-64 -31 -88 -117 -50 -179 53 -88 158 -88 223 -1 40 54
18 127 -53 176 -40 27 -73 28 -120 4z"
      />
      <path
        d="M4900 5062 c-64 -31 -88 -117 -50 -179 26 -44 63 -66 110 -66 72 0
123 54 123 128 0 51 -21 88 -66 115 -40 25 -72 25 -117 2z"
      />
      <path
        d="M5195 5066 c-32 -14 -65 -46 -69 -68 -2 -7 -6 -21 -11 -31 -18 -43
41 -129 101 -146 69 -19 152 33 156 97 4 74 -13 110 -65 142 -37 23 -70 25
-112 6z"
      />
      <path
        d="M5494 5066 c-134 -58 -88 -246 60 -246 41 0 53 5 82 34 30 30 34 40
34 89 0 67 -30 111 -88 127 -46 12 -50 12 -88 -4z"
      />
      <path
        d="M5773 5065 c-36 -15 -60 -47 -69 -93 -8 -42 1 -74 32 -111 51 -61
137 -59 193 5 59 66 32 164 -56 200 -40 17 -59 17 -100 -1z"
      />
      <path
        d="M6060 5062 c-102 -50 -84 -211 27 -241 113 -31 205 104 135 198 -24
33 -75 61 -109 61 -10 0 -34 -8 -53 -18z"
      />
      <path
        d="M6354 5066 c-33 -15 -77 -63 -69 -76 3 -5 1 -11 -5 -15 -5 -3 -10
-17 -10 -30 0 -13 5 -27 10 -30 6 -4 8 -10 6 -14 -8 -13 58 -71 92 -80 44 -13
95 3 126 40 21 24 26 41 26 83 0 62 -24 100 -76 122 -41 17 -60 17 -100 0z"
      />
      <path
        d="M6654 5066 c-105 -46 -107 -180 -3 -233 56 -29 111 -19 152 27 22 25
27 40 27 84 0 62 -24 100 -76 122 -41 17 -60 17 -100 0z"
      />
      <path
        d="M6933 5065 c-97 -42 -95 -193 4 -237 56 -25 108 -12 152 37 59 67 32
165 -56 201 -40 17 -59 17 -100 -1z"
      />
      <path
        d="M7223 5065 c-39 -17 -63 -52 -70 -103 -17 -130 143 -199 224 -97 55
68 28 167 -53 201 -41 17 -60 17 -101 -1z"
      />
      <path
        d="M7520 5062 c-47 -23 -85 -94 -71 -133 5 -15 7 -29 5 -32 -10 -9 67
-75 94 -80 44 -9 97 10 126 44 21 24 26 41 26 83 0 62 -24 100 -76 122 -44 18
-62 18 -104 -4z"
      />
      <path
        d="M7814 5066 c-67 -30 -99 -107 -69 -170 43 -91 157 -109 219 -35 21
24 26 41 26 83 0 62 -24 100 -76 122 -41 17 -60 17 -100 0z"
      />
      <path
        d="M8090 5062 c-62 -33 -86 -110 -54 -177 40 -85 148 -94 213 -19 59 66
32 164 -56 200 -43 18 -62 18 -103 -4z"
      />
      <path
        d="M8380 5062 c-105 -56 -85 -217 31 -245 72 -18 159 52 159 127 0 48
-38 102 -86 122 -44 18 -62 18 -104 -4z"
      />
      <path
        d="M8680 5062 c-62 -33 -86 -110 -54 -177 21 -45 59 -68 112 -68 75 0
122 51 122 134 0 55 -26 94 -76 115 -44 18 -62 18 -104 -4z"
      />
      <path
        d="M8975 5064 c-45 -23 -63 -43 -75 -80 -16 -47 -4 -89 35 -128 40 -40
91 -52 137 -33 53 23 71 51 76 115 3 48 0 62 -19 86 -23 29 -74 56 -106 56
-10 0 -31 -7 -48 -16z"
      />
      <path
        d="M9273 5073 c-13 -2 -23 -8 -23 -13 0 -4 -9 -10 -20 -13 -23 -6 -50
-62 -50 -103 0 -37 27 -90 57 -109 40 -26 104 -24 146 5 87 60 71 184 -30 226
-33 14 -40 15 -80 7z"
      />
      <path
        d="M9550 5062 c-62 -33 -86 -110 -54 -177 43 -92 161 -95 220 -6 22 33
26 49 21 82 -6 47 -37 85 -84 105 -43 18 -61 18 -103 -4z"
      />
      <path
        d="M9840 5062 c-141 -75 -43 -299 104 -238 99 41 100 200 1 242 -45 18
-63 18 -105 -4z"
      />
      <path
        d="M10155 5073 c-39 -10 -84 -53 -96 -92 -29 -97 69 -193 164 -162 54
18 80 53 84 113 5 65 -21 112 -72 134 -35 14 -45 15 -80 7z"
      />
      <path
        d="M10440 5073 c-14 -3 -27 -8 -30 -12 -3 -3 -14 -11 -25 -17 -11 -6
-26 -31 -34 -56 -12 -39 -12 -52 0 -88 8 -24 23 -48 34 -54 11 -6 22 -14 25
-17 12 -14 63 -19 98 -10 81 22 117 115 72 188 -31 52 -84 77 -140 66z"
      />
      <path
        d="M10733 5073 c-35 -7 -81 -60 -89 -99 -20 -110 78 -194 173 -148 75
37 103 117 63 181 -32 53 -89 79 -147 66z"
      />
      <path
        d="M11023 5073 c-34 -7 -81 -59 -89 -99 -20 -107 74 -190 169 -150 70
29 99 125 57 193 -27 45 -81 67 -137 56z"
      />
      <path
        d="M11312 5073 c-25 -5 -94 -71 -87 -84 3 -4 1 -10 -5 -14 -28 -17 0
-98 46 -134 112 -88 260 52 184 176 -27 45 -81 67 -138 56z"
      />
      <path
        d="M11612 5072 c-35 -6 -90 -67 -98 -107 -18 -96 81 -179 173 -144 71
27 101 107 69 184 -22 51 -80 79 -144 67z"
      />
      <path
        d="M11895 5073 c-81 -21 -120 -126 -75 -201 52 -85 177 -74 225 18 39
76 -12 168 -100 183 -16 3 -39 3 -50 0z"
      />
      <path
        d="M12185 5073 c-83 -22 -122 -130 -72 -204 32 -48 91 -68 143 -48 95
36 114 177 32 231 -28 18 -75 28 -103 21z"
      />
      <path
        d="M12475 5073 c-35 -9 -61 -32 -83 -74 -28 -53 -27 -75 6 -124 38 -55
93 -75 148 -54 95 36 114 177 32 231 -28 18 -75 28 -103 21z"
      />
      <path
        d="M12716 5036 c-41 -42 -53 -89 -36 -138 13 -35 59 -74 95 -80 19 -3
20 3 20 122 0 120 -1 125 -22 128 -14 2 -34 -9 -57 -32z"
      />
      <path
        d="M0 4650 l0 -132 32 6 c70 14 113 65 109 130 -4 66 -40 107 -108 121
l-33 7 0 -132z"
      />
      <path
        d="M265 4766 c-48 -21 -85 -71 -85 -115 0 -47 55 -116 101 -126 36 -8
84 9 118 43 32 32 41 110 17 147 -33 51 -100 74 -151 51z"
      />
      <path
        d="M563 4769 c-95 -28 -112 -158 -29 -219 12 -8 23 -15 26 -16 3 0 13
-4 23 -8 28 -13 68 3 109 43 33 33 38 45 38 84 0 56 -34 102 -88 117 -20 5
-39 10 -42 9 -3 0 -19 -5 -37 -10z"
      />
      <path
        d="M853 4769 c-104 -29 -111 -184 -10 -232 43 -21 56 -21 95 -2 49 25
82 71 82 113 -1 89 -78 145 -167 121z"
      />
      <path
        d="M1143 4769 c-91 -26 -114 -158 -39 -221 74 -62 196 -2 196 96 0 91
-73 148 -157 125z"
      />
      <path
        d="M1443 4769 c-18 -5 -45 -25 -60 -44 -68 -85 -10 -200 100 -200 43 0
52 4 83 37 28 31 34 46 34 80 0 93 -72 151 -157 127z"
      />
      <path
        d="M1730 4770 c-19 -5 -47 -22 -62 -39 -69 -78 -7 -206 100 -206 38 0
48 5 83 42 31 34 39 50 39 80 0 89 -71 143 -160 123z"
      />
      <path
        d="M2013 4769 c-47 -14 -83 -62 -83 -112 0 -46 30 -104 58 -113 9 -3 27
-10 38 -16 58 -27 154 48 154 120 0 40 -28 95 -57 110 -30 15 -76 20 -110 11z"
      />
      <path
        d="M2303 4768 c-47 -12 -83 -60 -83 -112 0 -120 119 -179 201 -100 43
41 56 98 32 141 -10 19 -26 41 -35 50 -23 21 -77 31 -115 21z"
      />
      <path
        d="M2559 4742 c-42 -38 -53 -101 -27 -154 15 -32 39 -55 58 -56 3 -1 13
-4 23 -7 30 -12 82 4 111 34 33 34 44 97 26 141 -30 73 -132 95 -191 42z"
      />
      <path
        d="M2849 4747 c-53 -46 -64 -107 -29 -165 43 -71 130 -79 189 -18 59 61
50 155 -19 191 -44 23 -110 19 -141 -8z"
      />
      <path
        d="M3124 4736 c-28 -28 -34 -41 -34 -77 0 -84 68 -149 142 -135 48 9 98
63 105 113 10 79 -43 133 -133 133 -39 0 -51 -5 -80 -34z"
      />
      <path
        d="M3424 4736 c-28 -28 -34 -42 -34 -76 0 -89 65 -151 143 -136 30 6
115 76 92 76 -5 0 -4 5 3 12 30 30 -5 116 -58 143 -16 8 -48 15 -71 15 -33 0
-47 -6 -75 -34z"
      />
      <path
        d="M3714 4736 c-29 -29 -34 -41 -34 -80 0 -90 54 -143 133 -133 56 8 98
51 104 109 10 83 -41 138 -128 138 -33 0 -47 -6 -75 -34z"
      />
      <path
        d="M4013 4748 c-58 -42 -70 -109 -29 -169 88 -129 283 -8 211 131 -31
60 -123 79 -182 38z"
      />
      <path
        d="M4284 4736 c-28 -28 -34 -41 -34 -77 0 -84 68 -149 142 -135 48 9 98
63 105 113 10 79 -43 133 -133 133 -39 0 -51 -5 -80 -34z"
      />
      <path
        d="M4584 4736 c-28 -28 -34 -41 -34 -77 0 -55 29 -104 74 -125 47 -23
94 -12 137 32 29 30 34 42 34 82 0 78 -50 122 -139 122 -30 0 -45 -7 -72 -34z"
      />
      <path
        d="M4874 4736 c-29 -29 -34 -41 -34 -80 0 -90 54 -143 133 -133 56 8 98
51 104 109 10 83 -41 138 -128 138 -33 0 -47 -6 -75 -34z"
      />
      <path
        d="M5159 4731 c-32 -32 -39 -46 -39 -78 0 -48 37 -105 79 -123 71 -29
156 23 167 102 11 82 -40 138 -127 138 -35 0 -48 -6 -80 -39z"
      />
      <path
        d="M5454 4736 c-28 -28 -34 -41 -34 -77 0 -84 68 -149 142 -135 48 9 98
63 105 113 10 79 -43 133 -133 133 -39 0 -51 -5 -80 -34z"
      />
      <path
        d="M5744 4736 c-28 -28 -34 -41 -34 -77 0 -121 122 -179 206 -98 90 87
33 209 -97 209 -33 0 -48 -7 -75 -34z"
      />
      <path
        d="M6034 4736 c-29 -29 -34 -41 -34 -80 0 -85 47 -136 125 -136 53 0
105 51 112 111 10 84 -41 139 -128 139 -33 0 -47 -6 -75 -34z"
      />
      <path
        d="M6324 4738 c-84 -78 -27 -218 89 -218 50 0 105 54 113 112 11 82 -40
138 -126 138 -32 0 -48 -7 -76 -32z"
      />
      <path
        d="M6614 4736 c-29 -29 -34 -41 -34 -81 0 -76 57 -135 130 -135 46 0
110 63 117 116 10 80 -43 134 -133 134 -39 0 -51 -5 -80 -34z"
      />
      <path
        d="M6904 4736 c-30 -30 -34 -41 -34 -84 0 -55 22 -92 70 -117 49 -26 86
-19 131 23 96 89 42 212 -93 212 -32 0 -47 -7 -74 -34z"
      />
      <path
        d="M7194 4736 c-30 -30 -34 -40 -34 -88 0 -60 16 -90 60 -113 73 -38
157 3 178 86 9 35 7 46 -11 81 -26 48 -61 68 -118 68 -33 0 -47 -6 -75 -34z"
      />
      <path
        d="M7490 4731 c-31 -33 -37 -46 -37 -82 -1 -111 123 -169 203 -96 44 40
55 96 29 151 -21 44 -59 66 -116 66 -36 0 -48 -6 -79 -39z"
      />
      <path
        d="M7774 4736 c-30 -30 -34 -40 -34 -85 0 -44 5 -56 33 -87 26 -29 42
-37 79 -41 43 -5 49 -3 87 33 94 88 44 214 -84 214 -40 0 -52 -5 -81 -34z"
      />
      <path
        d="M8064 4736 c-30 -30 -34 -40 -34 -85 0 -42 5 -56 31 -86 17 -19 35
-33 39 -30 5 3 11 1 15 -5 3 -5 22 -10 41 -10 47 0 112 60 120 111 13 78 -45
139 -131 139 -40 0 -52 -5 -81 -34z"
      />
      <path
        d="M8353 4735 c-34 -34 -35 -38 -31 -95 4 -61 17 -83 65 -109 71 -37
159 16 179 109 6 25 -35 95 -66 113 -15 9 -47 17 -70 17 -35 0 -48 -6 -77 -35z"
      />
      <path
        d="M8649 4730 c-34 -36 -37 -44 -35 -86 8 -112 122 -164 202 -91 88 81
34 217 -87 217 -36 0 -47 -6 -80 -40z"
      />
      <path
        d="M8949 4747 c-36 -31 -49 -59 -49 -106 0 -60 48 -111 112 -118 43 -5
49 -3 90 35 40 38 43 44 43 92 0 47 -3 55 -37 86 -32 29 -45 34 -85 34 -35 0
-55 -6 -74 -23z"
      />
      <path
        d="M9224 4736 c-30 -30 -34 -40 -34 -85 0 -42 5 -56 31 -86 17 -19 35
-33 39 -30 5 3 11 1 15 -5 13 -22 74 -11 108 20 69 60 73 135 11 189 -29 26
-43 31 -85 31 -45 0 -55 -4 -85 -34z"
      />
      <path
        d="M9523 4735 c-34 -34 -35 -38 -31 -94 5 -74 35 -108 102 -117 55 -8
84 8 122 66 35 53 28 95 -26 144 -34 30 -47 36 -86 36 -39 0 -51 -5 -81 -35z"
      />
      <path
        d="M9844 4760 c-68 -27 -88 -142 -36 -201 35 -39 107 -51 147 -25 58 38
79 109 50 166 -31 61 -98 86 -161 60z"
      />
      <path
        d="M10140 4762 c-55 -25 -80 -62 -80 -123 0 -35 6 -47 39 -80 31 -32 46
-39 77 -39 65 0 134 67 134 131 0 29 -26 74 -56 98 -27 21 -82 27 -114 13z"
      />
      <path
        d="M10414 4760 c-40 -16 -64 -59 -64 -113 0 -44 4 -53 39 -88 33 -33 45
-39 81 -39 36 0 48 6 81 38 62 63 64 119 4 179 -28 28 -40 33 -76 32 -24 0
-54 -4 -65 -9z"
      />
      <path
        d="M10714 4760 c-40 -16 -64 -59 -64 -113 0 -44 4 -53 39 -88 51 -51
103 -54 154 -9 40 35 63 82 52 107 -5 10 -7 23 -7 28 2 14 -38 59 -65 73 -25
14 -78 15 -109 2z"
      />
      <path
        d="M11004 4760 c-41 -16 -64 -59 -64 -118 0 -50 3 -58 36 -88 29 -28 43
-34 79 -34 115 0 164 148 74 224 -32 27 -82 33 -125 16z"
      />
      <path
        d="M11295 4760 c-43 -17 -68 -57 -71 -112 -2 -48 0 -55 36 -90 30 -30
45 -38 74 -38 122 0 177 147 85 225 -32 26 -83 32 -124 15z"
      />
      <path
        d="M11585 4761 c-50 -22 -79 -89 -63 -147 4 -18 24 -46 43 -63 49 -43
101 -43 149 -1 64 57 69 136 11 190 -27 25 -104 37 -140 21z"
      />
      <path
        d="M11875 4761 c-29 -12 -54 -43 -65 -79 -21 -74 44 -162 120 -162 48 0
103 44 120 95 12 36 -3 90 -34 120 -31 32 -97 43 -141 26z"
      />
      <path
        d="M12165 4761 c-81 -35 -94 -160 -21 -216 123 -93 265 88 152 194 -27
25 -97 37 -131 22z"
      />
      <path
        d="M12460 4763 c-29 -11 -70 -57 -70 -78 0 -11 -5 -26 -11 -32 -7 -7 -7
-13 0 -17 5 -4 8 -13 5 -20 -3 -8 12 -32 34 -55 34 -36 44 -41 84 -41 36 0 51
6 75 29 61 58 64 138 8 191 -24 22 -94 36 -125 23z"
      />
      <path
        d="M12741 4757 c-86 -44 -85 -155 1 -220 50 -37 58 -21 58 113 0 131 -3
136 -59 107z"
      />
      <path
        d="M0 4365 l0 -115 25 0 c68 0 122 68 110 138 -8 44 -63 92 -107 92
l-28 0 0 -115z"
      />
      <path
        d="M242 4467 c-28 -16 -62 -70 -62 -98 0 -29 37 -97 59 -109 28 -15 97
-12 130 5 38 19 65 77 57 120 -10 51 -66 95 -119 95 -23 -1 -52 -6 -65 -13z"
      />
      <path
        d="M538 4464 c-53 -28 -72 -98 -43 -154 21 -41 53 -60 103 -60 57 0 96
21 117 66 23 50 13 95 -31 133 -40 35 -98 41 -146 15z"
      />
      <path
        d="M823 4460 c-36 -22 -60 -82 -49 -125 4 -15 20 -40 36 -56 23 -24 37
-29 75 -29 60 0 98 21 120 66 23 50 13 95 -31 133 -42 37 -101 42 -151 11z"
      />
      <path
        d="M1113 4461 c-36 -23 -60 -83 -49 -126 4 -15 20 -40 36 -56 32 -32 78
-38 135 -18 64 24 81 132 29 187 -31 35 -107 41 -151 13z"
      />
      <path
        d="M1409 4465 c-17 -10 -32 -31 -42 -61 -14 -44 -14 -49 5 -90 23 -47
51 -64 104 -64 108 0 160 122 84 195 -35 33 -109 43 -151 20z"
      />
      <path
        d="M1693 4461 c-36 -23 -60 -83 -49 -126 4 -15 20 -40 36 -56 23 -24 37
-29 75 -29 61 0 98 21 120 66 21 45 10 97 -30 134 -35 32 -109 38 -152 11z"
      />
      <path
        d="M1988 4463 c-30 -19 -58 -68 -58 -103 0 -29 26 -76 52 -94 28 -20
100 -21 136 -2 68 35 75 135 13 190 -30 27 -108 32 -143 9z"
      />
      <path
        d="M2278 4462 c-30 -18 -58 -67 -58 -102 0 -29 26 -76 52 -94 40 -28
142 -14 165 23 6 9 13 35 17 56 8 48 -17 104 -54 122 -35 18 -90 16 -122 -5z"
      />
      <path
        d="M2563 4452 c-18 -16 -33 -35 -33 -43 0 -8 -5 -20 -12 -27 -17 -17 27
-106 59 -121 101 -46 210 45 170 141 -21 49 -51 70 -104 75 -42 4 -52 1 -80
-25z"
      />
      <path
        d="M2858 4463 c-31 -20 -58 -69 -58 -106 0 -37 41 -91 80 -103 101 -33
195 57 156 149 -20 48 -50 69 -103 74 -34 3 -56 -2 -75 -14z"
      />
      <path
        d="M3148 4463 c-31 -20 -58 -69 -58 -106 0 -38 41 -91 83 -104 49 -16
92 -7 126 28 50 49 46 125 -8 173 -30 27 -108 32 -143 9z"
      />
      <path
        d="M3448 4462 c-31 -18 -58 -67 -58 -104 0 -30 33 -84 61 -99 29 -15 94
-15 122 0 81 44 78 164 -4 207 -37 19 -88 17 -121 -4z"
      />
      <path
        d="M3738 4462 c-16 -9 -37 -35 -47 -57 -17 -36 -18 -43 -5 -75 20 -47
41 -68 79 -79 46 -12 81 -3 114 30 95 95 -27 252 -141 181z"
      />
      <path
        d="M4018 4462 c-30 -18 -58 -67 -58 -102 0 -36 40 -91 77 -107 97 -40
198 57 158 152 -27 66 -117 95 -177 57z"
      />
      <path
        d="M4308 4462 c-31 -18 -58 -67 -58 -104 0 -37 37 -88 76 -104 98 -41
200 55 159 151 -27 66 -117 95 -177 57z"
      />
      <path
        d="M4614 4464 c-61 -30 -81 -102 -44 -162 41 -67 133 -77 189 -20 36 37
45 79 26 123 -26 65 -105 92 -171 59z"
      />
      <path
        d="M4904 4464 c-61 -30 -81 -102 -44 -162 40 -66 125 -76 179 -21 55 54
45 139 -21 179 -39 24 -72 25 -114 4z"
      />
      <path
        d="M5195 4470 c-33 -13 -65 -55 -72 -93 -12 -74 66 -146 143 -131 42 8
89 52 97 91 17 90 -82 169 -168 133z"
      />
      <path
        d="M5484 4464 c-16 -8 -38 -28 -47 -43 -70 -118 95 -238 192 -140 96 95
-21 244 -145 183z"
      />
      <path
        d="M5774 4464 c-43 -21 -68 -67 -61 -116 13 -99 134 -139 206 -67 96 95
-21 244 -145 183z"
      />
      <path
        d="M6064 4464 c-94 -46 -78 -184 26 -215 29 -9 43 -8 76 7 86 37 91 168
8 207 -43 21 -70 21 -110 1z"
      />
      <path
        d="M6354 4470 c-67 -27 -88 -137 -36 -189 75 -76 205 -26 205 79 0 47
-17 76 -56 100 -34 21 -77 25 -113 10z"
      />
      <path
        d="M6644 4464 c-71 -35 -84 -133 -25 -186 40 -36 89 -45 134 -23 45 21
68 56 69 104 0 48 -15 77 -55 101 -38 24 -81 25 -123 4z"
      />
      <path
        d="M6934 4464 c-93 -46 -79 -181 23 -214 45 -15 87 -5 123 32 25 24 30
38 30 77 0 53 -18 84 -60 106 -37 19 -76 19 -116 -1z"
      />
      <path
        d="M7224 4464 c-69 -34 -83 -127 -29 -184 36 -37 82 -45 134 -23 45 19
60 46 63 108 1 51 -14 79 -53 99 -40 20 -75 20 -115 0z"
      />
      <path
        d="M7525 4468 c-11 -6 -31 -22 -44 -37 -20 -23 -22 -33 -18 -79 5 -42
12 -59 33 -77 79 -68 194 -18 194 84 0 55 -20 88 -66 107 -40 17 -72 17 -99 2z"
      />
      <path
        d="M7815 4470 c-44 -18 -70 -59 -70 -112 0 -43 4 -53 32 -78 18 -16 48
-32 67 -35 44 -9 102 18 121 56 20 37 19 99 -1 128 -29 41 -101 61 -149 41z"
      />
      <path
        d="M8105 4470 c-73 -30 -96 -132 -41 -189 54 -57 157 -46 191 20 20 37
19 99 -1 128 -29 41 -101 61 -149 41z"
      />
      <path
        d="M8395 4470 c-73 -30 -96 -132 -42 -188 37 -38 83 -47 135 -27 94 38
90 188 -6 215 -43 12 -60 12 -87 0z"
      />
      <path
        d="M8699 4472 c-8 -4 -28 -17 -44 -30 -25 -20 -29 -30 -29 -77 -1 -46 3
-58 28 -84 38 -39 83 -48 130 -26 89 42 88 174 -1 211 -35 15 -59 17 -84 6z"
      />
      <path
        d="M8975 4470 c-44 -18 -70 -59 -70 -111 0 -41 5 -53 30 -79 79 -79 205
-26 205 85 0 81 -88 137 -165 105z"
      />
      <path
        d="M9265 4470 c-44 -18 -70 -59 -70 -111 0 -41 5 -53 30 -79 100 -100
254 15 189 141 -24 47 -97 71 -149 49z"
      />
      <path
        d="M9565 4470 c-44 -18 -70 -59 -70 -111 0 -41 5 -53 30 -79 57 -57 149
-47 188 21 23 41 22 80 -4 123 -27 46 -93 68 -144 46z"
      />
      <path
        d="M9855 4470 c-74 -30 -96 -132 -41 -189 73 -76 196 -27 196 79 0 81
-83 140 -155 110z"
      />
      <path
        d="M10134 4470 c-39 -15 -74 -68 -74 -111 0 -52 48 -107 101 -115 73
-12 139 43 139 116 0 83 -88 142 -166 110z"
      />
      <path
        d="M10424 4470 c-39 -15 -74 -68 -74 -111 0 -52 48 -107 101 -115 73
-12 139 43 139 116 0 83 -88 142 -166 110z"
      />
      <path
        d="M10724 4470 c-11 -4 -33 -22 -47 -40 -48 -56 -28 -143 39 -175 108
-51 212 55 159 162 -25 50 -97 75 -151 53z"
      />
      <path
        d="M11014 4470 c-64 -25 -92 -111 -57 -171 17 -29 67 -59 97 -59 61 0
116 57 116 120 0 81 -83 140 -156 110z"
      />
      <path
        d="M11294 4470 c-32 -13 -74 -73 -74 -104 0 -54 46 -112 96 -122 75 -14
144 40 144 114 0 85 -88 144 -166 112z"
      />
      <path
        d="M11594 4470 c-64 -25 -92 -111 -57 -171 61 -103 223 -58 223 61 0 82
-89 141 -166 110z"
      />
      <path
        d="M11893 4473 c-36 -7 -83 -68 -83 -109 0 -65 57 -124 119 -124 93 0
151 101 104 181 -27 46 -77 64 -140 52z"
      />
      <path
        d="M12183 4473 c-12 -2 -36 -20 -53 -39 -100 -114 68 -264 172 -152 75
81 -7 213 -119 191z"
      />
      <path
        d="M12465 4473 c-40 -10 -85 -69 -85 -112 0 -37 33 -90 66 -106 86 -41
174 15 174 110 0 68 -84 126 -155 108z"
      />
      <path
        d="M12763 4473 c-12 -2 -36 -20 -53 -39 -34 -39 -40 -86 -15 -133 13
-26 67 -61 94 -61 7 0 11 39 11 120 0 66 -3 119 -7 119 -5 -1 -18 -4 -30 -6z"
      />
      <path
        d="M0 4080 c0 -114 1 -120 20 -120 29 0 76 26 94 52 20 29 20 90 0 129
-8 17 -30 37 -49 45 -65 27 -65 27 -65 -106z"
      />
      <path
        d="M285 4190 c-3 -5 -11 -8 -16 -4 -6 3 -25 -11 -44 -31 -46 -48 -48
-95 -5 -147 51 -61 133 -61 180 0 35 44 22 128 -24 164 -29 21 -82 32 -91 18z"
      />
      <path
        d="M585 4190 c-4 -6 -11 -8 -16 -4 -5 3 -25 -10 -44 -29 -30 -30 -35
-42 -35 -80 0 -62 35 -103 94 -112 158 -24 189 190 34 228 -15 4 -30 3 -33 -3z"
      />
      <path
        d="M875 4190 c-4 -6 -11 -8 -16 -4 -5 3 -25 -10 -44 -29 -30 -30 -35
-42 -35 -81 0 -36 6 -52 29 -78 26 -29 35 -33 80 -33 43 0 54 4 85 34 40 39
47 81 22 130 -23 44 -106 86 -121 61z"
      />
      <path
        d="M1134 4186 c-44 -20 -64 -53 -64 -107 0 -113 131 -160 200 -71 23 28
26 87 8 126 -22 50 -92 75 -144 52z"
      />
      <path
        d="M1433 4185 c-62 -26 -87 -111 -49 -167 39 -59 96 -73 154 -38 66 41
71 146 9 191 -33 23 -78 29 -114 14z"
      />
      <path
        d="M1733 4188 c-17 -4 -43 -22 -57 -39 -58 -69 -16 -173 75 -185 113
-15 173 118 89 196 -34 32 -63 39 -107 28z"
      />
      <path
        d="M2023 4188 c-81 -21 -111 -127 -54 -190 26 -29 36 -33 79 -33 63 0
103 27 116 77 12 47 1 85 -34 118 -34 32 -63 39 -107 28z"
      />
      <path
        d="M2308 4187 c-52 -12 -71 -38 -76 -102 -4 -52 -3 -56 31 -90 26 -26
43 -35 69 -35 42 0 96 32 108 66 38 98 -33 185 -132 161z"
      />
      <path
        d="M2603 4189 c-48 -14 -73 -52 -73 -111 0 -43 4 -54 34 -84 40 -40 83
-45 134 -14 49 30 67 97 40 154 -9 21 -82 69 -99 65 -2 0 -18 -5 -36 -10z"
      />
      <path
        d="M2844 4156 c-28 -28 -34 -42 -34 -76 0 -36 6 -48 39 -81 45 -45 86
-51 139 -19 100 61 48 210 -74 210 -28 0 -44 -8 -70 -34z"
      />
      <path
        d="M3134 4156 c-29 -29 -34 -41 -34 -81 0 -40 5 -52 34 -81 39 -39 80
-44 136 -16 109 54 61 212 -66 212 -28 0 -44 -8 -70 -34z"
      />
      <path
        d="M3443 4173 c-30 -27 -43 -57 -43 -103 0 -56 50 -110 102 -110 64 0
128 63 128 126 0 13 -17 41 -38 64 -34 35 -44 40 -84 40 -29 0 -52 -6 -65 -17z"
      />
      <path
        d="M3719 4161 c-25 -25 -29 -37 -29 -81 0 -46 4 -56 34 -86 40 -40 83
-45 134 -14 36 22 52 54 52 107 0 27 -8 43 -34 69 -29 29 -41 34 -81 34 -38 0
-52 -5 -76 -29z"
      />
      <path
        d="M4004 4156 c-28 -28 -34 -42 -34 -76 0 -36 6 -48 39 -81 43 -44 80
-50 131 -24 35 18 60 62 60 106 0 23 -10 41 -39 70 -33 33 -45 39 -81 39 -34
0 -48 -6 -76 -34z"
      />
      <path
        d="M4294 4156 c-29 -29 -34 -41 -34 -81 0 -40 5 -52 34 -81 26 -26 42
-34 69 -34 53 0 85 16 107 52 32 53 26 94 -19 139 -33 33 -45 39 -81 39 -34 0
-48 -6 -76 -34z"
      />
      <path
        d="M4620 4183 c-35 -14 -60 -58 -60 -107 0 -47 16 -79 50 -99 55 -33
118 -16 157 42 34 50 30 83 -15 131 -33 34 -45 40 -78 39 -21 0 -46 -3 -54 -6z"
      />
      <path
        d="M4879 4161 c-49 -50 -38 -150 21 -184 78 -47 170 11 170 107 0 30 -7
45 -34 72 -29 29 -41 34 -81 34 -38 0 -52 -5 -76 -29z"
      />
      <path
        d="M5164 4154 c-25 -26 -34 -44 -34 -70 0 -72 51 -124 122 -124 40 0 73
21 93 60 43 82 -14 170 -111 170 -29 0 -43 -7 -70 -36z"
      />
      <path
        d="M5464 4156 c-28 -28 -34 -42 -34 -75 0 -80 43 -121 124 -121 58 0
106 55 106 121 0 23 -10 41 -39 70 -33 33 -45 39 -81 39 -34 0 -48 -6 -76 -34z"
      />
      <path
        d="M5754 4156 c-28 -28 -34 -42 -34 -75 0 -78 41 -121 114 -121 38 0 80
29 101 70 25 48 19 79 -24 121 -33 33 -45 39 -81 39 -34 0 -48 -6 -76 -34z"
      />
      <path
        d="M6039 4161 c-49 -50 -38 -150 21 -184 78 -47 170 11 170 107 0 30 -7
45 -34 72 -29 29 -41 34 -81 34 -38 0 -52 -5 -76 -29z"
      />
      <path
        d="M6326 4157 c-25 -26 -31 -41 -30 -73 2 -83 56 -133 130 -121 141 23
112 227 -32 227 -29 0 -43 -7 -68 -33z"
      />
      <path
        d="M6624 4156 c-28 -28 -34 -42 -34 -75 0 -62 23 -98 70 -112 90 -27
168 31 156 116 -7 57 -60 105 -117 105 -33 0 -47 -6 -75 -34z"
      />
      <path
        d="M6914 4156 c-28 -28 -34 -42 -34 -75 0 -86 50 -129 133 -117 36 6 47
13 71 49 36 55 31 99 -19 145 -50 46 -104 45 -151 -2z"
      />
      <path
        d="M7199 4161 c-25 -25 -29 -37 -29 -83 1 -78 40 -118 115 -118 66 0
115 70 101 144 -16 83 -127 116 -187 57z"
      />
      <path
        d="M7499 4161 c-28 -28 -33 -46 -30 -101 4 -60 49 -100 113 -100 99 0
144 139 67 205 -45 37 -110 35 -150 -4z"
      />
      <path
        d="M7784 4156 c-29 -29 -34 -41 -34 -83 0 -41 5 -54 29 -77 38 -36 108
-47 146 -22 66 43 70 132 9 185 -50 45 -104 43 -150 -3z"
      />
      <path
        d="M8074 4156 c-33 -33 -39 -52 -34 -105 5 -60 61 -98 131 -88 47 6 64
19 85 63 23 48 12 95 -32 133 -50 45 -104 43 -150 -3z"
      />
      <path
        d="M8380 4177 c-30 -15 -52 -57 -53 -102 -2 -69 46 -115 119 -115 29 0
44 7 71 37 33 34 35 40 30 87 -3 40 -11 55 -36 78 -35 31 -87 37 -131 15z"
      />
      <path
        d="M8667 4167 c-61 -51 -53 -158 15 -193 59 -31 123 -11 153 46 28 53
11 120 -37 154 -33 24 -98 20 -131 -7z"
      />
      <path
        d="M8947 4156 c-82 -75 -27 -196 88 -196 27 0 44 8 65 29 53 53 52 111
-5 168 -46 46 -97 46 -148 -1z"
      />
      <path
        d="M9237 4156 c-45 -41 -54 -101 -21 -151 35 -53 139 -59 181 -10 46 53
42 108 -12 162 -46 46 -97 46 -148 -1z"
      />
      <path
        d="M9560 4181 c0 -5 -9 -12 -19 -16 -10 -3 -25 -20 -32 -36 -37 -91 12
-169 107 -169 28 0 44 8 69 33 45 45 53 79 29 124 -28 52 -60 73 -110 73 -24
0 -44 -4 -44 -9z"
      />
      <path
        d="M9827 4167 c-83 -69 -37 -207 70 -207 45 0 77 20 98 60 28 53 11 120
-37 154 -33 24 -98 20 -131 -7z"
      />
      <path
        d="M10112 4162 c-18 -15 -36 -40 -40 -57 -30 -108 110 -194 188 -116 53
53 52 111 -5 168 -43 43 -95 45 -143 5z"
      />
      <path
        d="M10402 4162 c-39 -33 -54 -79 -41 -126 14 -51 51 -76 111 -76 41 0
54 5 78 29 53 53 52 111 -5 168 -43 43 -95 45 -143 5z"
      />
      <path
        d="M10715 4173 c-35 -19 -42 -27 -54 -70 -13 -44 3 -97 36 -123 37 -29
103 -27 141 5 35 29 57 78 47 102 -5 10 -9 25 -11 33 -2 8 -17 27 -34 43 -35
31 -79 35 -125 10z"
      />
      <path
        d="M10993 4169 c-62 -40 -65 -142 -6 -189 34 -27 102 -27 135 0 57 44
61 129 8 182 -35 35 -89 38 -137 7z"
      />
      <path
        d="M11287 4171 c-32 -21 -62 -59 -53 -68 3 -3 1 -12 -5 -19 -7 -9 -6
-22 4 -46 44 -104 183 -106 217 -3 12 37 0 96 -24 121 -38 37 -96 44 -139 15z"
      />
      <path
        d="M11578 4165 c-98 -67 -56 -205 62 -205 116 0 159 134 66 205 -42 32
-82 32 -128 0z"
      />
      <path
        d="M11872 4170 c-46 -28 -68 -82 -53 -131 33 -109 184 -105 221 5 15 46
-6 96 -53 124 -43 27 -73 28 -115 2z"
      />
      <path
        d="M12153 4169 c-42 -27 -60 -78 -44 -130 15 -51 53 -79 106 -79 52 0
89 27 105 76 35 105 -75 192 -167 133z"
      />
      <path
        d="M12450 4174 c-40 -28 -55 -56 -56 -102 -2 -36 4 -49 31 -77 28 -30
39 -35 77 -35 82 0 132 68 108 147 -21 68 -107 104 -160 67z"
      />
      <path
        d="M12747 4171 c-48 -31 -63 -65 -59 -126 3 -31 46 -74 81 -81 l32 -7
-3 113 c-2 64 -7 115 -13 117 -5 1 -23 -5 -38 -16z"
      />
      <path
        d="M0 3780 l0 -110 29 0 c41 0 89 43 97 86 12 65 -38 134 -97 134 l-29
0 0 -110z"
      />
      <path
        d="M247 3870 c-27 -21 -57 -73 -57 -97 0 -22 43 -81 68 -93 102 -46 201
64 143 158 -34 54 -107 69 -154 32z"
      />
      <path
        d="M537 3860 c-47 -41 -57 -86 -28 -133 39 -64 110 -75 165 -27 42 37
52 76 33 121 -19 46 -39 61 -90 66 -38 5 -48 1 -80 -27z"
      />
      <path
        d="M822 3865 c-53 -44 -56 -119 -6 -168 73 -73 216 11 184 108 -16 47
-52 77 -102 82 -37 4 -49 1 -76 -22z"
      />
      <path
        d="M1110 3863 c-39 -35 -49 -87 -26 -135 41 -86 166 -75 196 17 34 104
-90 190 -170 118z"
      />
      <path
        d="M1417 3870 c-42 -33 -55 -70 -43 -114 17 -58 50 -86 101 -86 52 0 89
27 105 75 32 98 -84 187 -163 125z"
      />
      <path
        d="M1696 3859 c-44 -38 -56 -87 -31 -127 28 -44 57 -62 101 -62 50 0 86
29 103 82 34 104 -90 181 -173 107z"
      />
      <path
        d="M1981 3864 c-18 -15 -33 -39 -37 -59 -7 -41 14 -103 38 -111 10 -3
18 -10 18 -15 0 -5 20 -9 44 -9 52 0 92 27 112 77 40 95 -94 185 -175 117z"
      />
      <path
        d="M2271 3864 c-80 -67 -36 -194 67 -194 38 0 50 5 76 33 17 19 32 45
33 58 9 101 -101 166 -176 103z"
      />
      <path
        d="M2600 3883 c-49 -18 -80 -83 -66 -139 25 -100 173 -99 206 1 28 86
-56 169 -140 138z"
      />
      <path
        d="M2890 3883 c-8 -3 -27 -17 -42 -31 -73 -68 -23 -182 80 -182 77 0
128 81 98 154 -21 51 -84 78 -136 59z"
      />
      <path
        d="M3170 3883 c-53 -20 -82 -89 -60 -144 40 -95 165 -91 206 8 12 28 12
38 0 68 -23 55 -93 87 -146 68z"
      />
      <path
        d="M3465 3881 c-35 -15 -57 -49 -62 -93 -12 -119 126 -167 196 -69 12
17 19 31 15 31 -3 0 -1 6 5 13 8 10 6 24 -9 55 -29 60 -88 86 -145 63z"
      />
      <path
        d="M3755 3881 c-35 -15 -57 -49 -62 -93 -13 -127 145 -170 201 -55 16
34 17 44 7 78 -19 62 -86 94 -146 70z"
      />
      <path
        d="M4050 3883 c-29 -10 -69 -56 -75 -85 -4 -18 -1 -42 10 -64 43 -91
161 -90 200 2 37 89 -46 179 -135 147z"
      />
      <path
        d="M4336 3883 c-33 -9 -65 -50 -71 -90 -8 -49 22 -99 70 -119 30 -12 40
-12 68 -1 83 36 105 128 44 185 -25 23 -77 35 -111 25z"
      />
      <path
        d="M4625 3881 c-56 -23 -80 -102 -50 -160 22 -44 75 -66 119 -51 35 11
76 48 80 71 1 8 5 19 9 26 4 6 3 14 -3 18 -6 4 -8 10 -5 15 10 15 -14 50 -48
70 -34 21 -68 24 -102 11z"
      />
      <path
        d="M4915 3881 c-35 -15 -57 -49 -62 -93 -5 -56 22 -102 71 -118 49 -16
104 10 129 62 45 91 -45 187 -138 149z"
      />
      <path
        d="M5210 3883 c-23 -8 -70 -55 -64 -64 2 -4 0 -10 -6 -14 -33 -20 20
-117 74 -135 67 -22 137 30 139 104 2 80 -70 135 -143 109z"
      />
      <path
        d="M5505 3880 c-33 -13 -65 -55 -71 -91 -7 -45 25 -95 72 -115 32 -14
39 -13 72 3 47 24 74 62 74 106 0 77 -75 127 -147 97z"
      />
      <path
        d="M5785 3881 c-85 -35 -84 -172 2 -207 45 -19 92 -5 128 37 72 86 -25
213 -130 170z"
      />
      <path
        d="M6075 3881 c-84 -35 -84 -172 0 -207 75 -31 145 19 145 104 -1 83
-71 133 -145 103z"
      />
      <path
        d="M6370 3883 c-24 -9 -49 -34 -65 -66 -20 -40 -19 -51 9 -96 63 -100
196 -60 196 58 0 34 -6 53 -21 70 -33 35 -80 48 -119 34z"
      />
      <path
        d="M6665 3880 c-33 -13 -65 -55 -71 -91 -7 -45 25 -95 71 -115 46 -19
78 -10 115 32 54 62 36 147 -37 175 -29 11 -51 11 -78 -1z"
      />
      <path
        d="M6950 3883 c-48 -18 -80 -83 -66 -136 14 -57 87 -98 138 -78 41 16
78 68 78 110 0 71 -83 128 -150 104z"
      />
      <path
        d="M7235 3881 c-85 -35 -84 -172 2 -207 46 -20 86 -10 118 28 33 39 35
113 4 147 -35 37 -81 49 -124 32z"
      />
      <path
        d="M7535 3881 c-85 -37 -84 -172 2 -207 75 -32 143 19 143 105 0 39 -5
52 -28 75 -31 29 -84 41 -117 27z"
      />
      <path
        d="M7815 3872 c-41 -26 -55 -45 -61 -84 -11 -72 69 -140 137 -116 53 18
71 42 77 96 4 43 1 53 -24 81 -37 41 -87 50 -129 23z"
      />
      <path
        d="M8090 3868 c-44 -30 -59 -75 -40 -126 16 -46 32 -61 75 -73 41 -12
81 5 114 47 32 40 27 87 -11 129 -44 48 -90 55 -138 23z"
      />
      <path
        d="M8382 3870 c-75 -47 -67 -162 15 -196 47 -20 88 -10 120 31 39 49 38
96 -1 141 -40 45 -87 54 -134 24z"
      />
      <path
        d="M8682 3870 c-75 -47 -67 -162 15 -196 46 -20 86 -10 118 28 79 93
-31 232 -133 168z"
      />
      <path
        d="M8975 3872 c-41 -26 -55 -45 -61 -84 -7 -44 25 -95 72 -114 73 -30
133 10 142 94 4 43 1 53 -24 81 -37 41 -87 50 -129 23z"
      />
      <path
        d="M9265 3877 c-41 -19 -53 -36 -60 -82 -7 -49 9 -82 55 -113 45 -30 84
-24 128 23 23 26 32 44 32 70 0 26 -9 44 -32 70 -38 40 -80 51 -123 32z"
      />
      <path
        d="M9550 3868 c-66 -45 -66 -141 0 -186 26 -18 44 -22 70 -18 48 7 98
64 98 111 0 47 -50 104 -98 111 -26 4 -44 0 -70 -18z"
      />
      <path
        d="M9840 3868 c-66 -45 -66 -141 0 -186 72 -49 160 3 160 93 0 90 -88
141 -160 93z"
      />
      <path
        d="M10145 3879 c-60 -35 -92 -101 -65 -134 7 -8 9 -15 5 -15 -5 0 0 -9
11 -21 75 -83 181 -50 192 59 4 43 1 53 -24 81 -32 35 -86 49 -119 30z"
      />
      <path
        d="M10425 3873 c-44 -24 -54 -37 -60 -80 -7 -47 9 -80 55 -111 46 -31
84 -23 129 24 29 32 33 41 29 78 -9 79 -88 125 -153 89z"
      />
      <path
        d="M10712 3870 c-51 -31 -69 -111 -36 -158 43 -62 122 -65 173 -6 52 58
35 131 -39 169 -38 20 -59 19 -98 -5z"
      />
      <path
        d="M11002 3870 c-60 -37 -71 -119 -23 -172 25 -28 36 -33 76 -33 40 0
51 5 76 33 51 57 36 142 -31 177 -38 20 -59 19 -98 -5z"
      />
      <path
        d="M11310 3879 c-31 -13 -71 -49 -65 -60 3 -4 1 -10 -5 -14 -21 -13 -10
-62 24 -101 30 -35 38 -39 81 -39 40 0 51 5 76 33 51 57 36 142 -31 177 -33
17 -45 18 -80 4z"
      />
      <path
        d="M11605 3876 c-43 -19 -66 -49 -72 -94 -5 -35 -1 -44 30 -78 30 -33
41 -39 77 -39 36 0 47 6 76 38 46 50 48 105 5 148 -16 16 -36 29 -44 29 -9 0
-21 2 -29 5 -7 2 -26 -2 -43 -9z"
      />
      <path
        d="M11880 3872 c-66 -33 -81 -118 -31 -174 25 -28 36 -33 76 -33 41 0
51 4 81 37 57 63 38 141 -42 174 -42 18 -42 18 -84 -4z"
      />
      <path
        d="M12170 3872 c-66 -33 -81 -118 -31 -174 25 -28 36 -33 76 -33 40 0
51 5 76 33 53 59 35 148 -37 178 -42 18 -42 18 -84 -4z"
      />
      <path
        d="M12461 3873 c-37 -19 -64 -61 -69 -103 -2 -17 8 -39 31 -65 31 -36
39 -40 82 -40 40 0 51 5 76 33 80 90 -15 229 -120 175z"
      />
      <path
        d="M12765 3876 c-43 -19 -66 -49 -72 -94 -4 -33 -1 -44 26 -74 33 -38
69 -54 77 -34 3 7 3 58 2 113 l-3 101 -30 -12z"
      />
      <path
        d="M0 3494 l0 -106 31 6 c17 3 36 6 40 6 16 0 49 64 49 95 0 17 -7 41
-16 54 -15 21 -70 51 -94 51 -6 0 -10 -40 -10 -106z"
      />
      <path
        d="M283 3593 c-13 -2 -35 -17 -50 -34 -49 -52 -36 -128 29 -162 31 -16
37 -17 70 -3 98 41 105 153 12 192 -18 8 -35 13 -36 13 -2 -1 -13 -4 -25 -6z"
      />
      <path
        d="M578 3593 c-44 -7 -78 -49 -78 -97 0 -50 16 -75 64 -100 33 -18 37
-18 70 -2 96 46 102 154 10 192 -18 8 -35 13 -36 13 -2 -1 -15 -4 -30 -6z"
      />
      <path
        d="M863 3593 c-39 -8 -73 -52 -73 -95 0 -51 11 -71 54 -96 30 -18 40
-19 71 -9 84 25 112 114 54 168 -17 16 -37 29 -44 29 -7 0 -19 2 -27 4 -7 2
-23 1 -35 -1z"
      />
      <path
        d="M1153 3593 c-40 -8 -73 -52 -73 -97 0 -50 19 -80 65 -102 30 -14 40
-14 65 -4 64 27 91 106 54 159 -14 19 -76 55 -86 50 -2 -1 -13 -4 -25 -6z"
      />
      <path
        d="M1453 3593 c-35 -7 -76 -56 -78 -95 -2 -52 19 -84 69 -103 44 -17 46
-17 75 4 61 44 78 102 45 150 -14 19 -76 55 -86 50 -2 -1 -13 -4 -25 -6z"
      />
      <path
        d="M1733 3593 c-13 -2 -23 -9 -23 -14 0 -5 -5 -9 -11 -9 -33 0 -52 -87
-29 -131 6 -10 29 -28 51 -40 35 -18 45 -19 70 -8 78 32 103 117 50 170 -16
16 -36 29 -44 29 -9 0 -21 2 -29 4 -7 2 -23 1 -35 -1z"
      />
      <path
        d="M2023 3592 c-38 -6 -73 -50 -73 -94 0 -53 13 -74 61 -99 35 -18 45
-19 70 -8 73 30 101 112 57 164 -23 26 -75 43 -115 37z"
      />
      <path
        d="M2267 3562 c-20 -21 -27 -38 -27 -68 0 -48 20 -78 65 -100 60 -28
121 11 135 86 7 38 5 44 -25 76 -27 29 -39 34 -77 34 -35 0 -50 -6 -71 -28z"
      />
      <path
        d="M2567 3562 c-21 -22 -27 -38 -27 -74 0 -54 18 -79 73 -97 33 -11 40
-10 67 9 42 30 60 59 60 98 0 83 -115 125 -173 64z"
      />
      <path
        d="M2848 3558 c-55 -60 -29 -143 52 -167 37 -11 43 -10 70 9 68 48 80
111 31 161 -25 24 -37 29 -78 29 -41 -1 -52 -5 -75 -32z"
      />
      <path
        d="M3137 3562 c-48 -50 -31 -138 32 -164 54 -22 68 -20 110 20 53 50 57
98 12 143 -25 24 -38 29 -78 29 -41 0 -54 -5 -76 -28z"
      />
      <path
        d="M3437 3562 c-20 -21 -27 -38 -27 -68 0 -48 20 -78 65 -100 29 -13 40
-14 64 -4 64 26 94 99 63 152 -32 56 -121 66 -165 20z"
      />
      <path
        d="M3727 3562 c-21 -22 -27 -38 -27 -73 0 -55 20 -85 66 -98 71 -20 134
32 134 109 0 81 -116 122 -173 62z"
      />
      <path
        d="M4008 3558 c-52 -58 -29 -143 47 -168 27 -9 40 -8 64 4 74 39 93 115
42 167 -25 24 -37 29 -78 29 -41 -1 -52 -5 -75 -32z"
      />
      <path
        d="M4297 3562 c-53 -55 -28 -147 48 -172 27 -9 40 -8 64 4 74 39 93 115
42 167 -25 24 -38 29 -78 29 -41 0 -54 -5 -76 -28z"
      />
      <path
        d="M4597 3562 c-53 -55 -28 -147 48 -172 27 -9 39 -8 62 3 64 33 82 79
56 138 -29 65 -117 82 -166 31z"
      />
      <path
        d="M4886 3561 c-26 -27 -28 -35 -24 -80 6 -56 18 -73 70 -90 41 -14 68
-5 100 32 24 27 31 53 24 92 -15 76 -116 102 -170 46z"
      />
      <path
        d="M5177 3566 c-59 -51 -37 -151 38 -176 40 -13 80 3 115 47 29 37 22
98 -15 130 -37 32 -100 31 -138 -1z"
      />
      <path
        d="M5467 3562 c-53 -55 -28 -147 48 -172 40 -13 80 3 115 47 29 37 22
98 -15 130 -40 35 -113 32 -148 -5z"
      />
      <path
        d="M5757 3562 c-53 -55 -28 -147 48 -172 40 -13 80 3 115 47 17 22 20
36 16 66 -10 59 -47 87 -113 87 -30 0 -46 -7 -66 -28z"
      />
      <path
        d="M6051 3564 c-59 -50 -34 -148 44 -174 78 -26 148 60 115 141 -27 64
-104 80 -159 33z"
      />
      <path
        d="M6342 3565 c-58 -49 -44 -143 25 -171 44 -19 81 -7 118 38 29 34 32
66 10 108 -29 56 -102 68 -153 25z"
      />
      <path
        d="M6629 3561 c-55 -55 -31 -146 46 -171 40 -13 80 3 114 46 51 65 1
153 -87 154 -35 0 -49 -6 -73 -29z"
      />
      <path
        d="M6919 3561 c-55 -55 -31 -146 46 -171 27 -9 40 -8 64 4 39 20 71 63
71 93 0 31 -30 83 -47 83 -7 0 -13 5 -13 10 0 6 -21 10 -46 10 -37 0 -51 -5
-75 -29z"
      />
      <path
        d="M7209 3561 c-55 -55 -31 -146 46 -171 78 -26 155 67 116 140 -35 66
-112 81 -162 31z"
      />
      <path
        d="M7509 3561 c-23 -24 -29 -38 -29 -73 0 -56 21 -85 72 -98 38 -11 43
-10 77 18 42 34 57 68 47 106 -19 76 -112 101 -167 47z"
      />
      <path
        d="M7791 3561 c-58 -54 -34 -145 44 -171 77 -25 156 64 125 141 -26 63
-117 79 -169 30z"
      />
      <path
        d="M8079 3561 c-55 -55 -31 -146 46 -171 77 -25 156 64 125 141 -27 63
-121 80 -171 30z"
      />
      <path
        d="M8369 3561 c-55 -56 -31 -146 46 -171 30 -10 40 -9 68 7 17 11 40 36
50 56 17 36 17 39 -2 75 -34 67 -112 83 -162 33z"
      />
      <path
        d="M8671 3564 c-27 -22 -31 -33 -31 -73 0 -59 20 -88 70 -101 43 -12 69
-3 102 35 81 94 -46 219 -141 139z"
      />
      <path
        d="M8975 3578 c-38 -21 -55 -50 -55 -91 0 -69 78 -124 134 -95 65 33 88
85 61 141 -24 52 -91 73 -140 45z"
      />
      <path
        d="M9239 3562 c-56 -50 -37 -137 38 -168 40 -17 61 -12 102 22 38 32 47
73 26 117 -29 63 -112 77 -166 29z"
      />
      <path
        d="M9539 3562 c-56 -50 -37 -137 38 -168 40 -17 61 -12 101 21 22 18 29
33 30 67 4 93 -99 142 -169 80z"
      />
      <path
        d="M9829 3562 c-26 -23 -32 -36 -32 -70 0 -56 24 -89 73 -102 105 -28
173 104 88 175 -41 34 -88 33 -129 -3z"
      />
      <path
        d="M10135 3578 c-30 -18 -46 -35 -50 -58 -2 -11 -6 -24 -10 -29 -3 -5
-1 -12 4 -16 6 -3 8 -12 5 -19 -7 -17 56 -69 89 -73 30 -4 88 34 105 70 39 80
-64 170 -143 125z"
      />
      <path
        d="M10399 3562 c-27 -24 -32 -36 -32 -72 0 -38 5 -48 36 -75 44 -38 75
-41 118 -12 67 45 74 114 17 162 -42 35 -97 34 -139 -3z"
      />
      <path
        d="M10711 3574 c-30 -21 -51 -71 -44 -107 3 -15 21 -40 40 -56 30 -25
39 -27 74 -21 37 7 95 47 84 58 -3 3 0 11 6 18 29 35 -45 124 -104 124 -18 0
-43 -7 -56 -16z"
      />
      <path
        d="M10992 3565 c-34 -28 -44 -63 -31 -110 7 -26 20 -38 54 -55 47 -23
69 -21 103 9 51 43 51 114 0 156 -39 33 -87 33 -126 0z"
      />
      <path
        d="M11284 3568 c-35 -27 -44 -39 -37 -50 2 -5 0 -15 -7 -23 -7 -9 -8
-15 0 -20 5 -3 10 -15 10 -25 0 -23 50 -63 84 -67 12 -2 38 7 57 20 67 45 74
114 17 162 -37 32 -85 33 -124 3z"
      />
      <path
        d="M11569 3562 c-27 -24 -32 -36 -32 -72 0 -38 5 -48 36 -75 44 -38 75
-41 118 -12 67 45 74 114 17 162 -42 35 -97 34 -139 -3z"
      />
      <path
        d="M11859 3562 c-25 -23 -32 -36 -33 -71 -1 -36 4 -47 32 -72 45 -40 99
-40 144 0 28 25 32 35 31 72 -2 35 -8 48 -35 71 -45 38 -96 38 -139 0z"
      />
      <path
        d="M12152 3565 c-37 -31 -47 -76 -26 -120 27 -58 110 -74 160 -32 23 20
27 32 27 72 0 41 -4 52 -31 77 -42 36 -89 38 -130 3z"
      />
      <path
        d="M12441 3564 c-17 -14 -31 -34 -31 -44 0 -10 -4 -21 -10 -25 -6 -3 -5
-16 2 -33 40 -94 152 -102 194 -14 12 27 13 39 4 67 -24 74 -102 97 -159 49z"
      />
      <path
        d="M12732 3565 c-39 -32 -48 -74 -28 -117 17 -34 63 -67 84 -60 8 2 12
33 12 103 0 91 -2 99 -19 99 -11 0 -33 -11 -49 -25z"
      />
      <path
        d="M0 3205 c0 -88 2 -95 20 -95 55 0 109 64 94 113 -11 37 -55 77 -86
77 l-28 0 0 -95z"
      />
      <path
        d="M254 3288 c-26 -12 -51 -65 -46 -95 2 -12 16 -36 32 -53 50 -54 133
-35 161 37 29 76 -68 150 -147 111z"
      />
      <path
        d="M549 3285 c-33 -18 -53 -64 -44 -104 20 -93 161 -96 196 -4 30 79
-71 152 -152 108z"
      />
      <path
        d="M839 3285 c-33 -18 -53 -64 -44 -104 20 -93 161 -96 196 -4 30 79
-71 152 -152 108z"
      />
      <path
        d="M1129 3285 c-55 -30 -62 -116 -12 -155 34 -27 92 -26 122 1 90 84 -2
213 -110 154z"
      />
      <path
        d="M1423 3288 c-28 -13 -47 -68 -38 -108 20 -89 151 -93 185 -6 30 79
-66 153 -147 114z"
      />
      <path
        d="M1709 3285 c-34 -18 -51 -59 -43 -101 18 -94 159 -101 194 -10 30 81
-70 155 -151 111z"
      />
      <path
        d="M1999 3285 c-33 -18 -53 -64 -44 -104 9 -43 48 -71 100 -71 92 0 131
100 64 163 -33 30 -78 35 -120 12z"
      />
      <path
        d="M2289 3285 c-84 -46 -47 -175 51 -175 84 0 126 111 64 168 -29 25
-76 28 -115 7z"
      />
      <path
        d="M2588 3289 c-18 -7 -31 -22 -38 -45 -24 -72 18 -134 90 -134 88 0
125 102 59 163 -31 28 -63 33 -111 16z"
      />
      <path
        d="M2869 3285 c-33 -18 -51 -59 -43 -100 8 -46 35 -67 91 -72 41 -4 52
-1 76 21 42 39 40 98 -4 139 -33 30 -78 35 -120 12z"
      />
      <path
        d="M3159 3285 c-33 -18 -53 -64 -44 -104 9 -43 48 -71 100 -71 92 0 131
100 64 163 -33 30 -78 35 -120 12z"
      />
      <path
        d="M3459 3286 c-53 -30 -61 -106 -17 -153 32 -35 101 -32 137 5 24 23
28 35 26 71 -2 27 -10 50 -22 63 -26 26 -90 33 -124 14z"
      />
      <path
        d="M3749 3285 c-53 -29 -61 -105 -17 -152 31 -33 100 -32 133 2 34 34
35 102 1 134 -30 28 -83 36 -117 16z"
      />
      <path
        d="M4034 3289 c-11 -6 -28 -24 -38 -40 -21 -36 -15 -73 18 -112 32 -37
105 -39 141 -2 35 34 35 102 1 134 -27 26 -90 36 -122 20z"
      />
      <path
        d="M4319 3286 c-73 -41 -54 -159 29 -179 33 -8 89 11 107 36 23 32 19
91 -8 122 -27 32 -90 42 -128 21z"
      />
      <path
        d="M4621 3287 c-55 -32 -66 -100 -23 -147 30 -35 70 -41 120 -21 54 22
68 95 29 144 -26 34 -88 45 -126 24z"
      />
      <path
        d="M4911 3287 c-53 -31 -66 -98 -26 -145 60 -71 165 -32 165 62 0 69
-80 116 -139 83z"
      />
      <path
        d="M5195 3288 c-33 -19 -44 -41 -45 -84 0 -73 69 -117 137 -89 88 37 65
170 -32 181 -22 2 -49 -1 -60 -8z"
      />
      <path
        d="M5489 3286 c-33 -19 -53 -66 -44 -104 24 -110 195 -94 195 19 0 75
-84 122 -151 85z"
      />
      <path
        d="M5779 3285 c-77 -42 -49 -175 37 -177 69 -2 113 34 113 92 1 76 -81
123 -150 85z"
      />
      <path
        d="M6071 3286 c-55 -30 -66 -99 -23 -145 40 -44 99 -46 138 -4 20 22 24
35 22 72 -3 39 -8 49 -37 68 -38 26 -65 28 -100 9z"
      />
      <path
        d="M6354 3288 c-48 -23 -58 -100 -19 -146 59 -70 165 -32 165 58 0 42
-15 67 -50 85 -33 17 -65 18 -96 3z"
      />
      <path
        d="M6651 3286 c-53 -29 -66 -97 -26 -144 60 -72 175 -34 175 58 0 42
-15 67 -50 85 -35 18 -67 19 -99 1z"
      />
      <path
        d="M6941 3286 c-53 -30 -66 -97 -26 -144 60 -71 175 -33 175 58 0 42
-15 67 -50 85 -35 18 -67 19 -99 1z"
      />
      <path
        d="M7231 3286 c-51 -28 -65 -95 -30 -140 30 -38 64 -49 105 -36 40 14
64 48 64 94 0 48 -8 65 -41 81 -34 18 -66 19 -98 1z"
      />
      <path
        d="M7523 3288 c-43 -21 -55 -100 -22 -142 30 -38 64 -49 105 -36 55 18
82 88 54 141 -22 41 -91 59 -137 37z"
      />
      <path
        d="M7811 3286 c-53 -30 -66 -97 -26 -144 30 -36 66 -47 110 -32 40 13
65 48 65 90 0 42 -15 67 -50 85 -35 18 -67 19 -99 1z"
      />
      <path
        d="M8105 3286 c-32 -14 -55 -51 -55 -91 0 -31 43 -81 76 -89 61 -16 124
32 124 94 0 42 -15 67 -50 85 -35 18 -55 18 -95 1z"
      />
      <path
        d="M8395 3286 c-54 -23 -71 -93 -34 -140 30 -38 64 -49 105 -36 40 14
64 48 64 94 0 48 -8 65 -41 81 -34 18 -55 18 -94 1z"
      />
      <path
        d="M8693 3289 c-52 -15 -70 -95 -32 -143 30 -38 64 -49 105 -36 39 13
64 48 64 90 0 42 -15 67 -50 85 -32 17 -41 17 -87 4z"
      />
      <path
        d="M8974 3286 c-58 -25 -69 -111 -20 -153 67 -59 166 -19 166 67 0 44
-16 70 -55 86 -42 17 -51 17 -91 0z"
      />
      <path
        d="M9265 3286 c-32 -14 -55 -51 -55 -91 0 -31 43 -81 76 -89 61 -16 124
32 124 94 0 44 -16 70 -55 86 -41 17 -51 17 -90 0z"
      />
      <path
        d="M9565 3286 c-54 -23 -71 -93 -34 -140 30 -38 64 -49 105 -36 91 30
97 147 9 180 -33 13 -43 12 -80 -4z"
      />
      <path
        d="M9855 3286 c-31 -13 -55 -51 -55 -86 0 -63 65 -110 126 -90 81 27 84
151 3 179 -39 13 -37 13 -74 -3z"
      />
      <path
        d="M10140 3289 c-78 -29 -71 -153 10 -178 67 -20 130 23 130 89 0 37
-28 77 -61 89 -37 13 -45 13 -79 0z"
      />
      <path
        d="M10425 3286 c-76 -33 -70 -150 10 -176 67 -22 135 23 135 90 0 37
-28 77 -61 89 -40 14 -47 13 -84 -3z"
      />
      <path
        d="M10724 3284 c-110 -54 -34 -218 79 -170 34 14 67 58 67 90 0 28 -35
76 -64 86 -38 13 -45 13 -82 -6z"
      />
      <path
        d="M11014 3284 c-107 -52 -38 -212 74 -173 34 12 62 52 62 89 0 37 -28
77 -61 89 -40 14 -37 14 -75 -5z"
      />
      <path
        d="M11304 3290 c-31 -12 -54 -49 -54 -88 0 -98 131 -137 175 -51 29 55
7 119 -46 138 -37 13 -46 13 -75 1z"
      />
      <path
        d="M11594 3284 c-90 -44 -55 -184 46 -184 51 0 100 49 100 99 0 36 -22
70 -55 86 -41 19 -52 19 -91 -1z"
      />
      <path
        d="M11884 3285 c-108 -55 -40 -211 76 -176 69 20 91 98 43 151 -34 37
-77 46 -119 25z"
      />
      <path
        d="M12184 3290 c-30 -12 -64 -58 -64 -87 0 -52 47 -103 95 -103 28 0 67
25 81 51 21 42 17 75 -13 108 -31 33 -67 45 -99 31z"
      />
      <path
        d="M12473 3293 c-53 -10 -78 -85 -48 -143 43 -84 175 -43 175 54 0 52
-69 99 -127 89z"
      />
      <path
        d="M12726 3259 c-29 -35 -33 -67 -11 -108 11 -23 57 -51 81 -51 2 0 4
43 4 95 0 94 0 95 -24 95 -15 0 -34 -12 -50 -31z"
      />
      <path
        d="M0 2915 c0 -59 4 -95 10 -95 24 0 79 30 89 48 15 28 14 68 -4 101
-15 31 -33 41 -71 41 -24 0 -24 -1 -24 -95z"
      />
      <path
        d="M253 2995 c-13 -9 -27 -34 -33 -56 -10 -37 -9 -44 14 -74 32 -41 73
-52 115 -31 50 26 64 74 39 129 -22 46 -92 62 -135 32z"
      />
      <path
        d="M539 2981 c-38 -39 -39 -80 0 -123 65 -73 180 -15 157 78 -19 72
-105 97 -157 45z"
      />
      <path
        d="M829 2981 c-78 -79 15 -200 109 -141 60 37 67 98 15 146 -37 34 -86
32 -124 -5z"
      />
      <path
        d="M1115 2985 c-49 -48 -22 -145 44 -160 34 -7 85 15 101 44 21 40 7
100 -29 128 -28 22 -89 16 -116 -12z"
      />
      <path
        d="M1415 2985 c-49 -48 -22 -145 44 -160 34 -7 85 15 101 44 18 34 11
78 -16 110 -34 41 -92 44 -129 6z"
      />
      <path
        d="M1699 2981 c-41 -41 -39 -83 5 -127 39 -39 67 -43 113 -14 77 46 37
169 -55 170 -24 0 -42 -8 -63 -29z"
      />
      <path
        d="M1989 2981 c-21 -22 -29 -39 -29 -66 0 -43 45 -95 82 -95 33 0 87 36
98 66 15 39 12 50 -21 89 -41 46 -87 48 -130 6z"
      />
      <path
        d="M2275 2985 c-37 -36 -35 -97 4 -136 16 -16 39 -29 50 -29 60 0 118
70 99 120 -27 71 -104 94 -153 45z"
      />
      <path
        d="M2575 2985 c-37 -36 -35 -97 4 -136 16 -16 39 -29 50 -29 54 0 101
48 101 104 0 13 -12 38 -26 55 -34 41 -92 44 -129 6z"
      />
      <path
        d="M2859 2981 c-41 -41 -39 -83 5 -127 19 -19 43 -34 55 -34 52 0 101
48 101 99 0 11 -14 36 -31 56 -41 46 -87 48 -130 6z"
      />
      <path
        d="M3149 2981 c-74 -75 8 -194 100 -146 30 15 61 60 61 89 0 8 -14 31
-31 51 -41 46 -87 48 -130 6z"
      />
      <path
        d="M3463 3000 c-52 -21 -59 -105 -14 -151 46 -45 104 -34 141 28 27 46
26 56 -14 97 -35 37 -67 45 -113 26z"
      />
      <path
        d="M3735 2985 c-19 -18 -25 -35 -25 -69 0 -37 5 -48 31 -70 47 -40 106
-30 134 23 23 45 19 79 -14 112 -38 38 -91 40 -126 4z"
      />
      <path
        d="M4019 2979 c-42 -45 -40 -88 8 -129 31 -27 41 -31 72 -26 86 13 107
125 32 169 -44 26 -79 21 -112 -14z"
      />
      <path
        d="M4309 2981 c-61 -62 -21 -156 66 -156 46 0 64 14 84 62 16 40 8 65
-30 98 -41 34 -83 33 -120 -4z"
      />
      <path
        d="M4625 2998 c-33 -19 -44 -41 -45 -85 0 -34 5 -46 31 -67 51 -43 101
-33 138 29 28 48 27 57 -13 99 -35 38 -72 46 -111 24z"
      />
      <path
        d="M4895 2985 c-19 -18 -25 -35 -25 -69 0 -37 5 -48 31 -70 49 -42 105
-32 134 24 23 44 19 78 -14 111 -38 38 -91 40 -126 4z"
      />
      <path
        d="M5181 2981 c-67 -71 -1 -181 94 -157 30 8 65 60 65 96 0 39 -42 81
-89 87 -36 5 -44 2 -70 -26z"
      />
      <path
        d="M5479 2981 c-72 -73 6 -189 103 -152 29 11 58 57 58 92 0 11 -15 36
-34 55 -44 44 -86 46 -127 5z"
      />
      <path
        d="M5769 2981 c-71 -71 -3 -187 90 -155 29 11 71 67 71 96 0 11 -15 35
-34 54 -44 44 -86 46 -127 5z"
      />
      <path
        d="M6055 2985 c-19 -18 -25 -35 -25 -69 0 -37 5 -48 31 -70 48 -41 106
-30 134 25 49 93 -67 187 -140 114z"
      />
      <path
        d="M6340 2980 c-35 -37 -36 -86 -3 -124 46 -52 115 -45 147 15 52 96
-70 188 -144 109z"
      />
      <path
        d="M6639 2981 c-37 -38 -39 -87 -5 -124 47 -50 113 -48 147 6 26 42 18
83 -21 120 -41 37 -83 36 -121 -2z"
      />
      <path
        d="M6929 2981 c-53 -53 -32 -140 38 -157 38 -10 74 7 101 46 28 42 23
74 -19 113 -40 37 -82 36 -120 -2z"
      />
      <path
        d="M7215 2985 c-50 -49 -26 -144 40 -161 93 -23 153 100 80 164 -36 30
-88 30 -120 -3z"
      />
      <path
        d="M7515 2985 c-50 -49 -26 -144 40 -161 93 -23 153 100 80 164 -36 30
-88 30 -120 -3z"
      />
      <path
        d="M7799 2981 c-37 -38 -39 -87 -5 -124 47 -50 113 -48 147 6 26 42 18
83 -21 120 -41 37 -83 36 -121 -2z"
      />
      <path
        d="M8089 2981 c-61 -62 -22 -161 63 -161 13 0 34 7 47 16 30 22 56 81
42 98 -6 7 -8 15 -5 18 2 3 -9 17 -26 32 -42 35 -83 34 -121 -3z"
      />
      <path
        d="M8375 2985 c-36 -35 -35 -106 1 -140 51 -47 122 -26 144 41 31 92
-78 167 -145 99z"
      />
      <path
        d="M8675 2985 c-36 -35 -35 -106 1 -140 51 -47 122 -26 144 41 31 92
-78 167 -145 99z"
      />
      <path
        d="M8959 2979 c-41 -43 -40 -91 2 -130 52 -48 119 -36 145 27 12 29 12
39 0 68 -29 71 -98 87 -147 35z"
      />
      <path
        d="M9249 2981 c-39 -39 -41 -98 -4 -136 69 -68 187 13 150 102 -28 67
-97 82 -146 34z"
      />
      <path
        d="M9565 2998 c-33 -18 -45 -40 -45 -85 0 -55 33 -93 80 -93 44 0 67 15
88 58 16 34 16 39 1 71 -27 57 -75 76 -124 49z"
      />
      <path
        d="M9835 2985 c-19 -18 -25 -35 -25 -70 0 -57 32 -95 80 -95 45 0 70 16
86 57 40 94 -71 179 -141 108z"
      />
      <path
        d="M10120 2980 c-40 -43 -40 -87 -1 -129 48 -51 120 -40 147 24 11 26
11 40 2 67 -16 46 -32 59 -78 65 -35 5 -43 2 -70 -27z"
      />
      <path
        d="M10409 2981 c-22 -23 -29 -39 -29 -69 0 -58 29 -87 89 -87 53 0 71
13 88 61 20 57 -28 124 -90 124 -18 0 -39 -11 -58 -29z"
      />
      <path
        d="M10725 2998 c-33 -19 -44 -41 -45 -86 0 -88 89 -124 151 -62 17 16
28 34 24 39 -3 5 -1 12 4 15 13 8 -25 83 -48 96 -25 13 -61 12 -86 -2z"
      />
      <path
        d="M10995 2985 c-19 -18 -25 -35 -25 -70 0 -59 32 -95 85 -95 53 0 85
36 85 95 0 35 -6 52 -25 70 -33 34 -87 34 -120 0z"
      />
      <path
        d="M11280 2980 c-82 -88 42 -219 127 -134 22 22 25 32 21 75 -6 59 -34
89 -86 89 -25 0 -41 -8 -62 -30z"
      />
      <path
        d="M11579 2981 c-41 -41 -40 -91 1 -133 27 -27 36 -30 73 -25 52 7 77
36 77 89 0 51 -43 98 -90 98 -22 0 -41 -9 -61 -29z"
      />
      <path
        d="M11876 2989 c-49 -38 -50 -121 -3 -154 59 -41 147 7 147 80 0 28 -7
44 -31 66 -37 35 -76 37 -113 8z"
      />
      <path
        d="M12163 2995 c-34 -24 -47 -76 -29 -118 25 -61 96 -78 141 -32 36 35
35 106 -1 140 -31 29 -78 33 -111 10z"
      />
      <path
        d="M12453 2995 c-34 -24 -47 -76 -29 -118 8 -20 23 -40 34 -46 59 -32
132 8 132 71 0 55 -10 74 -46 92 -43 20 -63 20 -91 1z"
      />
      <path
        d="M12753 2989 c-41 -26 -55 -65 -38 -106 15 -35 58 -68 75 -58 12 7 14
171 2 179 -4 2 -22 -4 -39 -15z"
      />
      <path
        d="M0 2620 c0 -90 0 -90 25 -90 38 0 75 42 75 84 0 48 -8 65 -41 81 -55
29 -59 24 -59 -75z"
      />
      <path
        d="M253 2686 c-39 -34 -39 -98 0 -132 33 -28 58 -30 98 -7 52 31 52 121
-1 148 -41 21 -64 19 -97 -9z"
      />
      <path
        d="M558 2693 c-55 -34 -56 -100 -2 -142 37 -29 70 -26 105 8 45 45 40
110 -12 136 -36 19 -57 19 -91 -2z"
      />
      <path
        d="M834 2686 c-41 -35 -39 -89 4 -127 41 -37 76 -37 113 0 46 46 37 106
-21 136 -40 21 -64 19 -96 -9z"
      />
      <path
        d="M1132 2694 c-71 -50 -32 -164 56 -164 35 0 72 43 72 84 0 48 -8 65
-41 81 -36 19 -59 19 -87 -1z"
      />
      <path
        d="M1440 2697 c-53 -27 -62 -98 -18 -144 48 -51 137 -10 138 64 0 45 -9
62 -41 78 -34 18 -47 18 -79 2z"
      />
      <path
        d="M1735 2701 c-62 -26 -76 -93 -30 -141 38 -40 77 -40 116 -1 45 45 40
110 -12 136 -31 17 -45 18 -74 6z"
      />
      <path
        d="M2010 2697 c-55 -28 -60 -89 -12 -139 35 -37 75 -37 113 1 46 46 37
106 -21 136 -35 18 -48 18 -80 2z"
      />
      <path
        d="M2300 2697 c-53 -27 -60 -97 -14 -144 50 -50 134 -12 134 61 0 48 -8
65 -41 81 -34 18 -47 18 -79 2z"
      />
      <path
        d="M2600 2697 c-53 -27 -62 -98 -18 -144 25 -26 57 -29 97 -8 32 16 41
33 41 75 0 42 -9 59 -41 75 -34 18 -47 18 -79 2z"
      />
      <path
        d="M2900 2703 c-23 -8 -60 -45 -55 -54 3 -4 1 -10 -5 -14 -18 -11 -11
-33 21 -70 40 -45 78 -47 120 -6 46 46 38 110 -18 139 -23 13 -39 14 -63 5z"
      />
      <path
        d="M3185 2703 c-49 -12 -77 -71 -54 -115 32 -63 93 -75 140 -29 46 46
34 108 -28 140 -23 12 -26 12 -58 4z"
      />
      <path
        d="M3475 2700 c-98 -39 -43 -192 60 -166 49 12 75 89 45 132 -28 39 -65
51 -105 34z"
      />
      <path
        d="M3770 2703 c-66 -24 -78 -117 -21 -160 9 -7 31 -13 49 -13 79 0 113
102 51 154 -30 25 -49 30 -79 19z"
      />
      <path
        d="M4051 2697 c-14 -6 -33 -28 -44 -47 -18 -33 -18 -35 -2 -65 37 -64
88 -74 136 -26 41 41 40 90 -2 125 -34 29 -52 32 -88 13z"
      />
      <path
        d="M4345 2703 c-53 -13 -77 -71 -51 -122 31 -59 90 -68 137 -22 39 39
39 85 2 117 -16 13 -31 24 -35 24 -3 0 -12 2 -20 4 -7 2 -22 1 -33 -1z"
      />
      <path
        d="M4630 2697 c-54 -28 -62 -96 -15 -143 35 -35 79 -32 116 7 23 25 27
36 22 62 -11 62 -74 100 -123 74z"
      />
      <path
        d="M4930 2703 c-51 -19 -72 -80 -46 -131 40 -78 156 -45 156 46 0 57
-61 104 -110 85z"
      />
      <path
        d="M5211 2698 c-14 -7 -33 -29 -44 -48 -18 -33 -18 -35 -2 -65 10 -16
25 -36 33 -42 47 -38 132 14 132 79 0 59 -68 102 -119 76z"
      />
      <path
        d="M5516 2703 c-22 -6 -47 -28 -50 -46 -2 -6 -6 -19 -10 -29 -9 -20 17
-74 43 -88 55 -29 131 18 131 80 0 52 -60 96 -114 83z"
      />
      <path
        d="M5790 2697 c-32 -16 -54 -61 -45 -94 9 -39 50 -73 85 -73 46 0 89 46
85 89 -5 61 -74 104 -125 78z"
      />
      <path
        d="M6090 2703 c-51 -19 -72 -80 -46 -131 39 -76 156 -45 156 42 0 58
-62 108 -110 89z"
      />
      <path
        d="M6371 2698 c-14 -7 -33 -28 -43 -46 -18 -32 -18 -34 2 -72 21 -42 52
-57 93 -46 39 11 59 33 64 73 4 32 1 45 -18 66 -29 32 -66 41 -98 25z"
      />
      <path
        d="M6675 2700 c-50 -20 -73 -75 -50 -119 30 -59 89 -68 136 -22 19 20
29 40 29 60 0 54 -66 101 -115 81z"
      />
      <path
        d="M6952 2695 c-57 -25 -66 -92 -17 -141 34 -34 79 -32 116 5 37 37 37
72 2 110 -35 37 -60 44 -101 26z"
      />
      <path
        d="M7242 2695 c-42 -18 -57 -57 -42 -107 12 -42 34 -58 80 -58 70 0 106
90 58 144 -30 32 -56 38 -96 21z"
      />
      <path
        d="M7538 2691 c-89 -45 -39 -181 57 -157 62 16 85 88 45 138 -25 33 -62
39 -102 19z"
      />
      <path
        d="M7827 2691 c-44 -22 -63 -63 -47 -102 27 -64 92 -78 141 -30 35 35
38 77 9 113 -25 33 -62 39 -103 19z"
      />
      <path
        d="M8111 2694 c-56 -24 -64 -92 -16 -140 34 -34 79 -32 116 5 36 36 37
70 4 109 -30 35 -62 44 -104 26z"
      />
      <path
        d="M8401 2694 c-80 -34 -51 -164 36 -164 71 0 108 85 63 142 -24 32 -59
39 -99 22z"
      />
      <path
        d="M8698 2691 c-78 -39 -51 -161 36 -161 72 0 112 84 66 142 -25 33 -62
40 -102 19z"
      />
      <path
        d="M8987 2691 c-17 -9 -38 -29 -45 -44 -12 -24 -11 -33 3 -62 29 -63 88
-74 136 -26 35 35 38 77 9 113 -25 33 -62 39 -103 19z"
      />
      <path
        d="M9249 2681 c-35 -28 -33 -95 4 -127 39 -33 81 -31 118 5 36 36 37 70
3 110 -20 25 -34 31 -64 31 -22 0 -47 -8 -61 -19z"
      />
      <path
        d="M9553 2685 c-44 -31 -36 -121 13 -144 57 -26 107 -3 123 55 8 29 7
41 -6 61 -29 44 -88 57 -130 28z"
      />
      <path
        d="M9839 2681 c-19 -15 -24 -29 -24 -66 0 -37 5 -51 24 -66 56 -46 141
-6 141 66 0 72 -85 112 -141 66z"
      />
      <path
        d="M10126 2679 c-15 -12 -25 -24 -22 -27 3 -3 1 -11 -5 -18 -6 -8 -8
-21 -4 -31 5 -10 8 -21 7 -25 -3 -15 52 -48 80 -48 41 0 88 48 88 90 0 69 -86
104 -144 59z"
      />
      <path
        d="M10410 2682 c-17 -13 -23 -30 -24 -61 -2 -83 86 -121 145 -62 16 16
29 36 29 45 0 25 -20 69 -38 82 -25 19 -86 17 -112 -4z"
      />
      <path
        d="M10711 2683 c-12 -10 -24 -35 -28 -55 -5 -32 -1 -41 25 -68 35 -35
78 -40 111 -12 24 20 45 79 30 88 -5 3 -7 9 -4 14 9 15 -47 50 -80 50 -18 0
-42 -8 -54 -17z"
      />
      <path
        d="M11000 2682 c-31 -25 -38 -90 -12 -123 30 -37 93 -39 129 -3 22 22
25 32 20 67 -7 50 -36 77 -82 77 -18 0 -43 -8 -55 -18z"
      />
      <path
        d="M11297 2689 c-19 -11 -40 -52 -41 -81 0 -9 9 -30 21 -46 27 -37 88
-42 124 -11 34 29 34 103 0 130 -25 21 -75 25 -104 8z"
      />
      <path
        d="M11593 2688 c-20 -10 -43 -54 -43 -84 0 -7 11 -26 24 -42 37 -43 95
-43 130 -1 30 35 32 58 11 98 -21 42 -71 53 -122 29z"
      />
      <path
        d="M11865 2675 c-32 -31 -32 -75 -1 -113 47 -55 129 -36 151 36 7 25
-25 86 -51 96 -34 13 -75 5 -99 -19z"
      />
      <path
        d="M12155 2675 c-31 -30 -33 -82 -6 -117 25 -31 94 -37 123 -12 39 36
28 130 -17 148 -35 13 -76 6 -100 -19z"
      />
      <path
        d="M12445 2675 c-53 -52 -20 -140 53 -143 70 -3 110 63 78 126 -26 50
-91 58 -131 17z"
      />
      <path
        d="M12740 2672 c-18 -18 -30 -41 -30 -56 0 -34 51 -92 74 -83 14 6 16
19 14 84 -3 89 -14 99 -58 55z"
      />
      <path
        d="M0 2335 c0 -74 0 -75 25 -75 33 0 75 42 75 75 0 33 -42 75 -75 75
-25 0 -25 -1 -25 -75z"
      />
      <path
        d="M245 2376 c-21 -28 -24 -38 -15 -60 16 -42 35 -56 79 -56 72 0 106
76 56 125 -37 38 -87 34 -120 -9z"
      />
      <path
        d="M541 2384 c-46 -58 -10 -124 66 -124 53 0 88 38 79 85 -13 66 -105
90 -145 39z"
      />
      <path
        d="M831 2384 c-12 -15 -21 -38 -21 -53 1 -77 105 -103 153 -39 18 24 19
30 7 60 -18 44 -37 58 -81 58 -29 0 -42 -6 -58 -26z"
      />
      <path
        d="M1121 2384 c-37 -47 -22 -103 32 -123 56 -21 115 30 102 89 -14 62
-96 82 -134 34z"
      />
      <path
        d="M1425 2385 c-43 -42 -28 -103 28 -124 56 -21 115 30 102 89 -13 60
-86 80 -130 35z"
      />
      <path
        d="M1713 2395 c-13 -9 -25 -32 -29 -52 -6 -33 -3 -40 26 -66 39 -35 82
-32 118 10 61 69 -38 162 -115 108z"
      />
      <path
        d="M1995 2385 c-27 -26 -32 -70 -12 -96 51 -66 151 -35 151 48 0 69 -88
100 -139 48z"
      />
      <path
        d="M2285 2385 c-24 -24 -31 -57 -19 -90 10 -25 60 -46 91 -39 41 11 55
30 57 76 0 31 -5 44 -23 59 -32 27 -77 24 -106 -6z"
      />
      <path
        d="M2585 2385 c-42 -41 -30 -102 24 -125 61 -25 126 40 101 101 -23 54
-84 66 -125 24z"
      />
      <path
        d="M2879 2400 c-23 -14 -42 -65 -35 -95 3 -14 20 -32 37 -40 74 -39 152
32 115 103 -8 16 -23 32 -31 36 -24 9 -67 7 -86 -4z"
      />
      <path
        d="M3155 2385 c-64 -63 10 -162 92 -123 40 19 58 61 43 99 -23 55 -93
67 -135 24z"
      />
      <path
        d="M3455 2385 c-56 -55 -4 -148 72 -129 37 10 50 25 58 65 5 29 2 39
-21 62 -34 35 -76 36 -109 2z"
      />
      <path
        d="M3745 2385 c-60 -59 6 -159 82 -123 15 7 34 26 41 41 36 76 -64 142
-123 82z"
      />
      <path
        d="M4042 2398 c-29 -15 -48 -79 -30 -107 35 -56 125 -47 149 16 25 66
-50 124 -119 91z"
      />
      <path
        d="M4315 2385 c-64 -63 10 -162 92 -123 15 7 33 25 40 39 37 78 -71 146
-132 84z"
      />
      <path
        d="M4615 2385 c-64 -63 10 -163 91 -123 53 25 51 112 -2 137 -38 17 -63
13 -89 -14z"
      />
      <path
        d="M4905 2385 c-40 -39 -32 -96 16 -121 81 -42 154 63 85 123 -35 30
-71 29 -101 -2z"
      />
      <path
        d="M5198 2390 c-72 -56 -10 -163 75 -131 57 22 69 88 23 128 -33 28 -65
29 -98 3z"
      />
      <path
        d="M5485 2385 c-30 -29 -33 -74 -6 -106 25 -31 92 -33 122 -3 31 31 28
82 -5 111 -36 30 -80 30 -111 -2z"
      />
      <path
        d="M5775 2385 c-30 -29 -33 -74 -6 -106 15 -18 28 -23 60 -23 52 2 81
26 81 68 -1 74 -85 112 -135 61z"
      />
      <path
        d="M6065 2385 c-46 -45 -23 -118 40 -131 74 -14 118 88 59 135 -35 28
-69 26 -99 -4z"
      />
      <path
        d="M6355 2385 c-45 -44 -26 -119 33 -130 51 -9 86 19 90 72 3 34 -1 44
-24 62 -35 28 -69 27 -99 -4z"
      />
      <path
        d="M6645 2385 c-48 -47 -23 -120 45 -131 51 -8 90 24 90 76 0 72 -84
106 -135 55z"
      />
      <path
        d="M6935 2385 c-48 -47 -23 -120 46 -131 28 -5 41 -1 63 18 26 22 28 28
21 66 -12 71 -81 96 -130 47z"
      />
      <path
        d="M7225 2385 c-16 -15 -25 -36 -25 -55 0 -36 38 -80 71 -80 11 0 34 8
50 17 25 15 29 24 29 59 0 75 -75 110 -125 59z"
      />
      <path
        d="M7525 2385 c-16 -15 -25 -36 -25 -55 0 -38 39 -80 73 -80 42 0 77 36
77 80 0 71 -76 104 -125 55z"
      />
      <path
        d="M7811 2393 c-12 -10 -24 -35 -27 -55 -12 -72 70 -114 130 -67 56 44
20 139 -54 139 -15 0 -37 -8 -49 -17z"
      />
      <path
        d="M8095 2385 c-73 -72 28 -178 109 -114 20 16 26 29 26 59 0 72 -84
106 -135 55z"
      />
      <path
        d="M8385 2385 c-53 -52 -11 -143 61 -132 47 8 64 28 64 76 0 72 -76 106
-125 56z"
      />
      <path
        d="M8685 2385 c-16 -15 -25 -36 -25 -55 0 -38 39 -80 75 -80 67 0 101
98 49 139 -35 28 -69 26 -99 -4z"
      />
      <path
        d="M8982 2403 c-16 -6 -42 -56 -42 -81 0 -31 45 -72 80 -72 72 0 109 96
54 139 -25 20 -63 26 -92 14z"
      />
      <path
        d="M9255 2385 c-50 -49 -15 -135 55 -135 72 0 109 96 54 139 -36 28 -79
27 -109 -4z"
      />
      <path
        d="M9555 2385 c-16 -15 -25 -36 -25 -55 0 -36 38 -80 69 -80 35 0 69 18
77 42 13 37 5 71 -22 96 -33 29 -69 28 -99 -3z"
      />
      <path
        d="M9845 2385 c-16 -15 -25 -36 -25 -55 0 -38 39 -80 75 -80 36 0 75 42
75 80 0 38 -39 80 -75 80 -14 0 -37 -11 -50 -25z"
      />
      <path
        d="M10136 2388 c-38 -35 -42 -78 -12 -111 49 -53 136 -19 136 53 0 38
-39 80 -74 80 -14 0 -36 -10 -50 -22z"
      />
      <path
        d="M10415 2385 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
-80 80 -19 0 -40 -9 -55 -25z"
      />
      <path
        d="M10715 2385 c-16 -15 -25 -36 -25 -55 0 -85 121 -109 149 -29 28 81
-65 144 -124 84z"
      />
      <path
        d="M11005 2385 c-16 -15 -25 -36 -25 -55 0 -38 39 -80 75 -80 36 0 75
42 75 80 0 38 -39 80 -75 80 -14 0 -37 -11 -50 -25z"
      />
      <path
        d="M11299 2391 c-43 -34 -45 -84 -3 -125 40 -40 124 2 124 62 0 64 -72
102 -121 63z"
      />
      <path
        d="M11586 2387 c-34 -34 -34 -79 -1 -112 69 -70 179 28 118 104 -10 12
-35 24 -55 27 -30 5 -41 2 -62 -19z"
      />
      <path
        d="M11893 2400 c-49 -20 -59 -96 -17 -129 37 -29 79 -27 109 5 40 43 26
105 -28 124 -35 12 -35 12 -64 0z"
      />
      <path
        d="M12183 2400 c-49 -20 -59 -96 -17 -129 35 -28 69 -26 99 4 61 60 -4
157 -82 125z"
      />
      <path
        d="M12473 2400 c-43 -18 -61 -94 -30 -125 9 -9 32 -19 51 -22 28 -4 39
-1 60 20 61 61 -2 160 -81 127z"
      />
      <path
        d="M12763 2400 c-49 -20 -59 -96 -17 -129 47 -37 54 -29 54 59 0 44 -3
80 -7 79 -5 0 -18 -4 -30 -9z"
      />
      <path
        d="M0 2046 c0 -70 2 -76 20 -76 27 0 70 43 70 69 0 28 -21 71 -34 71 -6
0 -21 3 -33 6 -22 6 -23 4 -23 -70z"
      />
      <path
        d="M283 2113 c-25 -5 -53 -39 -48 -57 2 -6 6 -23 9 -37 5 -25 41 -49 72
-49 23 0 64 47 64 73 0 47 -46 79 -97 70z"
      />
      <path
        d="M580 2113 c-57 -22 -69 -88 -21 -122 16 -12 39 -21 50 -21 29 0 71
43 71 73 0 47 -57 86 -100 70z"
      />
      <path
        d="M870 2113 c-74 -28 -62 -143 16 -143 30 0 84 50 84 77 -1 42 -60 81
-100 66z"
      />
      <path
        d="M1160 2113 c-32 -11 -50 -37 -50 -70 0 -68 68 -96 117 -47 21 21 24
32 20 61 -4 20 -10 39 -14 43 -12 11 -56 19 -73 13z"
      />
      <path
        d="M1455 2113 c-34 -9 -45 -24 -45 -64 0 -73 66 -104 115 -54 54 53 5
136 -70 118z"
      />
      <path
        d="M1740 2113 c-54 -20 -66 -78 -25 -118 13 -14 36 -25 50 -25 33 0 75
42 75 75 0 24 -31 65 -49 65 -5 0 -15 2 -23 4 -7 3 -20 2 -28 -1z"
      />
      <path
        d="M2030 2113 c-74 -27 -62 -143 16 -143 31 0 84 50 84 79 0 21 -32 61
-49 61 -5 0 -15 2 -23 4 -7 3 -20 2 -28 -1z"
      />
      <path
        d="M2320 2113 c-74 -27 -62 -143 15 -143 35 0 75 42 75 79 0 28 -22 61
-40 61 -5 0 -14 2 -22 4 -7 3 -20 2 -28 -1z"
      />
      <path
        d="M2615 2112 c-34 -7 -45 -23 -45 -63 0 -73 66 -104 115 -54 54 53 6
133 -70 117z"
      />
      <path
        d="M2900 2112 c-53 -17 -65 -73 -25 -116 30 -33 68 -34 100 -1 27 26 32
62 13 88 -15 19 -66 36 -88 29z"
      />
      <path
        d="M3757 2102 c-54 -33 -25 -132 38 -132 32 0 75 42 75 73 0 54 -65 88
-113 59z"
      />
      <path
        d="M4055 2110 c-48 -18 -57 -74 -20 -114 30 -33 68 -34 100 -1 27 26 32
62 13 88 -17 22 -67 36 -93 27z"
      />
      <path
        d="M5810 2113 c-74 -27 -62 -143 16 -143 27 0 84 49 84 72 0 19 -42 66
-65 71 -11 3 -27 3 -35 0z"
      />
      <path
        d="M6100 2113 c-32 -12 -50 -37 -50 -70 0 -68 68 -96 117 -47 20 20 24
32 19 55 -9 47 -49 75 -86 62z"
      />
      <path
        d="M6370 2107 c-20 -10 -26 -21 -28 -59 -3 -39 1 -49 20 -62 32 -23 65
-20 93 9 33 32 31 60 -4 96 -33 32 -44 35 -81 16z"
      />
      <path
        d="M6680 2113 c-33 -12 -50 -37 -50 -72 0 -30 5 -40 29 -54 40 -23 68
-21 96 8 31 30 32 57 3 89 -25 26 -54 37 -78 29z"
      />
      <path
        d="M6965 2110 c-74 -29 -56 -140 22 -140 26 0 83 49 83 72 0 19 -42 65
-65 71 -11 3 -29 2 -40 -3z"
      />
      <path
        d="M7255 2110 c-76 -30 -56 -140 25 -140 33 0 70 40 70 75 0 46 -53 82
-95 65z"
      />
      <path
        d="M7541 2107 c-46 -23 -47 -102 -2 -126 34 -19 68 -13 90 15 28 36 26
60 -8 95 -33 32 -44 35 -80 16z"
      />
      <path
        d="M7840 2113 c-23 -9 -50 -35 -45 -44 3 -4 1 -10 -4 -13 -30 -19 32
-86 80 -86 30 0 69 40 69 71 0 39 -64 85 -100 72z"
      />
      <path
        d="M8125 2110 c-76 -30 -57 -141 24 -140 30 1 53 16 70 48 27 51 -38
115 -94 92z"
      />
      <path
        d="M8415 2110 c-49 -19 -64 -82 -30 -120 59 -65 165 32 108 98 -22 25
-51 33 -78 22z"
      />
      <path
        d="M8710 2113 c-28 -11 -42 -34 -43 -68 -1 -45 26 -75 68 -75 36 0 60
22 70 62 6 22 1 33 -23 58 -30 30 -43 34 -72 23z"
      />
      <path
        d="M8990 2107 c-32 -16 -53 -56 -38 -74 6 -7 7 -13 2 -13 -4 0 3 -11 16
-25 44 -46 109 -28 125 36 5 19 0 33 -17 52 -29 32 -56 40 -88 24z"
      />
      <path
        d="M10745 2110 c-76 -30 -61 -140 20 -140 52 0 90 57 67 103 -11 22 -52
51 -65 46 -1 0 -11 -4 -22 -9z"
      />
      <path
        d="M11035 2110 c-76 -30 -61 -140 19 -140 50 0 72 32 68 95 -2 17 -49
57 -64 54 -1 0 -12 -4 -23 -9z"
      />
      <path
        d="M11313 2110 c-12 -5 -29 -22 -37 -38 -14 -28 -14 -32 7 -63 17 -26
31 -35 58 -37 69 -6 100 78 45 125 -29 25 -39 27 -73 13z"
      />
      <path
        d="M3157 2092 c-37 -41 -7 -122 44 -122 70 0 114 75 69 120 -26 26 -90
27 -113 2z"
      />
      <path
        d="M3457 2092 c-23 -25 -21 -78 3 -102 29 -29 67 -25 101 11 34 37 36
57 9 87 -26 28 -90 30 -113 4z"
      />
      <path
        d="M4317 2092 c-41 -45 -6 -122 53 -122 64 0 103 77 60 120 -26 26 -90
27 -113 2z"
      />
      <path
        d="M4617 2092 c-39 -43 -7 -122 49 -122 29 0 84 52 84 79 0 51 -98 82
-133 43z"
      />
      <path
        d="M4907 2092 c-23 -25 -21 -78 3 -102 27 -27 66 -25 95 5 29 28 32 56
9 90 -20 31 -82 35 -107 7z"
      />
      <path
        d="M5191 2096 c-7 -8 -14 -28 -16 -44 -5 -38 33 -82 70 -82 29 0 75 40
75 65 0 20 -19 57 -34 67 -19 13 -83 9 -95 -6z"
      />
      <path
        d="M5487 2092 c-40 -44 -5 -122 54 -122 33 0 79 38 79 65 0 20 -19 57
-34 67 -22 15 -83 8 -99 -10z"
      />
      <path
        d="M9255 2090 c-42 -47 -10 -120 52 -120 36 0 52 11 71 47 35 65 -72
129 -123 73z"
      />
      <path
        d="M9555 2090 c-41 -46 -10 -120 50 -120 19 0 39 8 50 20 22 24 25 81 6
104 -20 23 -84 21 -106 -4z"
      />
      <path
        d="M9845 2090 c-41 -46 -10 -120 50 -120 39 0 68 30 68 70 0 40 -29 70
-68 70 -19 0 -39 -8 -50 -20z"
      />
      <path
        d="M10125 2090 c-10 -11 -14 -20 -10 -20 5 0 4 -5 -3 -12 -8 -8 -9 -15
-1 -25 7 -7 8 -13 3 -13 -4 0 3 -11 16 -25 47 -50 123 -21 123 45 0 16 -8 39
-18 50 -24 27 -85 27 -110 0z"
      />
      <path
        d="M10415 2090 c-43 -48 -10 -120 55 -120 45 0 73 27 73 70 0 43 -28 70
-73 70 -24 0 -43 -7 -55 -20z"
      />
      <path
        d="M11589 2092 c-50 -41 -17 -122 51 -122 46 0 70 25 70 73 0 61 -70 89
-121 49z"
      />
      <path
        d="M11882 2093 c-52 -44 -24 -123 42 -123 44 0 66 26 66 78 0 31 -5 44
-19 52 -29 15 -66 12 -89 -7z"
      />
      <path
        d="M12172 2093 c-52 -44 -24 -123 42 -123 41 0 66 26 66 68 0 61 -63 93
-108 55z"
      />
      <path
        d="M12457 2098 c-50 -38 -17 -128 47 -128 41 0 66 26 66 68 0 58 -67 93
-113 60z"
      />
      <path
        d="M12752 2093 c-34 -30 -36 -57 -6 -92 42 -50 54 -42 54 39 0 76 -9 86
-48 53z"
      />
      <path
        d="M0 1750 c0 -77 11 -87 58 -54 31 21 31 87 0 108 -47 33 -58 23 -58
-54z"
      />
      <path
        d="M265 1795 c-28 -27 -30 -42 -13 -80 14 -32 53 -43 87 -24 44 25 41
97 -4 117 -36 17 -43 15 -70 -13z"
      />
      <path
        d="M572 1804 c-36 -25 -45 -50 -28 -83 25 -47 69 -55 104 -18 30 32 28
71 -4 96 -32 25 -43 26 -72 5z"
      />
      <path
        d="M862 1804 c-34 -24 -43 -48 -31 -83 15 -41 69 -54 102 -23 29 28 28
79 -3 104 -27 22 -39 23 -68 2z"
      />
      <path
        d="M1133 1794 c-44 -44 -15 -114 47 -114 36 0 60 29 60 73 0 26 -6 40
-22 51 -32 23 -55 20 -85 -10z"
      />
      <path
        d="M1433 1794 c-44 -44 -15 -114 47 -114 36 0 60 29 60 73 0 26 -6 40
-22 51 -32 23 -55 20 -85 -10z"
      />
      <path
        d="M1732 1804 c-33 -23 -46 -52 -31 -70 6 -8 8 -14 3 -14 -5 -1 2 -10
15 -20 31 -26 63 -25 89 3 30 32 28 71 -4 96 -32 25 -43 26 -72 5z"
      />
      <path
        d="M2009 1790 c-34 -33 -33 -62 3 -92 28 -25 60 -22 86 5 30 32 28 71
-4 96 -36 28 -50 26 -85 -9z"
      />
      <path
        d="M2301 1803 c-32 -27 -38 -76 -12 -102 26 -26 72 -27 94 -3 25 28 22
87 -5 106 -29 21 -51 20 -77 -1z"
      />
      <path
        d="M2595 1795 c-46 -45 -19 -115 43 -115 57 0 84 78 42 120 -27 27 -56
25 -85 -5z"
      />
      <path
        d="M2879 1789 c-35 -38 -36 -49 -4 -83 29 -32 67 -34 95 -4 27 29 25 64
-5 93 -32 33 -51 31 -86 -6z"
      />
      <path
        d="M3169 1791 c-35 -36 -36 -54 -4 -86 30 -31 65 -32 93 -2 29 30 28 62
-3 92 -32 32 -50 31 -86 -4z"
      />
      <path
        d="M3485 1808 c-67 -39 -54 -128 19 -128 40 0 66 26 66 66 0 53 -44 85
-85 62z"
      />
      <path
        d="M3756 1796 c-47 -46 -24 -116 38 -116 40 0 66 26 66 66 0 38 -17 61
-52 69 -21 5 -33 1 -52 -19z"
      />
      <path
        d="M4041 1791 c-32 -35 -33 -46 -8 -84 13 -20 26 -27 51 -27 82 0 89
111 8 135 -17 5 -29 -1 -51 -24z"
      />
      <path
        d="M4330 1791 c-35 -35 -37 -49 -9 -85 26 -33 68 -34 97 -3 27 28 28 58
5 85 -31 36 -60 37 -93 3z"
      />
      <path
        d="M4630 1791 c-34 -33 -37 -56 -14 -89 22 -32 76 -30 102 3 17 21 19
32 11 57 -10 37 -16 43 -47 53 -17 5 -29 -1 -52 -24z"
      />
      <path
        d="M4915 1795 c-46 -45 -22 -115 39 -115 40 0 66 26 66 66 0 35 -8 47
-37 63 -31 16 -41 14 -68 -14z"
      />
      <path
        d="M5205 1796 c-29 -29 -32 -62 -9 -94 30 -44 114 -19 114 34 0 35 -12
62 -28 67 -10 3 -25 8 -34 12 -11 4 -25 -2 -43 -19z"
      />
      <path
        d="M5499 1791 c-34 -35 -36 -49 -8 -85 13 -17 30 -26 49 -26 31 0 70 39
70 70 0 18 -53 70 -72 70 -5 0 -23 -13 -39 -29z"
      />
      <path
        d="M5788 1790 c-24 -25 -29 -36 -23 -58 13 -55 78 -71 113 -27 24 31 18
69 -16 95 -33 26 -38 25 -74 -10z"
      />
      <path
        d="M6075 1795 c-46 -45 -22 -115 40 -115 63 0 89 80 39 119 -34 27 -50
26 -79 -4z"
      />
      <path
        d="M6365 1795 c-29 -28 -32 -61 -9 -93 12 -18 24 -22 52 -20 68 6 83 85
23 121 -37 22 -37 22 -66 -8z"
      />
      <path
        d="M6659 1791 c-34 -35 -36 -49 -8 -85 13 -17 30 -26 49 -26 31 0 70 39
70 70 0 18 -53 70 -72 70 -5 0 -23 -13 -39 -29z"
      />
      <path
        d="M6949 1791 c-34 -35 -36 -49 -8 -85 38 -48 106 -25 113 38 2 19 -5
33 -26 51 -37 32 -43 31 -79 -4z"
      />
      <path
        d="M7235 1795 c-46 -45 -22 -115 40 -115 63 0 89 80 39 119 -34 27 -50
26 -79 -4z"
      />
      <path
        d="M7550 1807 c-62 -31 -47 -119 22 -125 28 -2 40 2 52 20 24 34 20 73
-10 97 -31 24 -31 25 -64 8z"
      />
      <path
        d="M7840 1807 c-14 -7 -31 -24 -39 -39 -14 -25 -14 -29 5 -55 30 -40 75
-43 103 -7 27 34 27 55 -1 81 -35 31 -42 33 -68 20z"
      />
      <path
        d="M8709 1806 c-34 -17 -47 -61 -28 -94 20 -37 80 -43 103 -10 42 59
-13 135 -75 104z"
      />
      <path
        d="M9869 1806 c-34 -16 -47 -60 -29 -93 30 -52 95 -46 115 10 19 55 -36
108 -86 83z"
      />
      <path
        d="M8101 1784 c-12 -15 -21 -32 -21 -39 0 -27 41 -65 70 -65 19 0 36 9
49 26 27 34 26 55 -2 82 -32 30 -71 28 -96 -4z"
      />
      <path
        d="M8392 1797 c-21 -25 -25 -66 -8 -92 22 -33 78 -35 100 -3 34 48 7
108 -48 108 -19 0 -38 -6 -44 -13z"
      />
      <path
        d="M8972 1783 c-11 -16 -20 -32 -20 -38 0 -26 41 -65 67 -65 59 0 87 57
51 103 -29 37 -72 37 -98 0z"
      />
      <path
        d="M9261 1784 c-12 -15 -21 -32 -21 -39 0 -27 41 -65 70 -65 29 0 70 38
70 65 0 27 -41 65 -70 65 -19 0 -36 -9 -49 -26z"
      />
      <path
        d="M9560 1788 c-25 -27 -26 -59 -2 -88 36 -45 112 -15 112 44 0 60 -69
88 -110 44z"
      />
      <path
        d="M10136 1788 c-42 -60 12 -134 75 -102 15 7 30 26 33 40 16 62 -73
113 -108 62z"
      />
      <path
        d="M10421 1784 c-12 -15 -21 -32 -21 -39 0 -27 41 -65 70 -65 29 0 70
38 70 65 0 27 -41 65 -70 65 -19 0 -36 -9 -49 -26z"
      />
      <path
        d="M10721 1784 c-26 -33 -26 -52 -2 -83 35 -46 111 -17 111 43 0 63 -71
89 -109 40z"
      />
      <path
        d="M11006 1788 c-9 -12 -16 -34 -16 -49 0 -56 77 -83 112 -39 38 47 11
110 -47 110 -24 0 -39 -7 -49 -22z"
      />
      <path
        d="M11302 1797 c-6 -7 -15 -25 -21 -40 -21 -55 64 -106 107 -63 21 20
21 79 0 100 -20 19 -71 21 -86 3z"
      />
      <path
        d="M11591 1784 c-27 -34 -26 -55 2 -82 32 -30 71 -28 96 4 12 15 21 32
21 39 0 27 -41 65 -70 65 -19 0 -36 -9 -49 -26z"
      />
      <path
        d="M11881 1784 c-26 -33 -27 -59 -2 -86 28 -29 70 -26 98 6 19 22 21 31
12 53 -15 41 -29 53 -59 53 -19 0 -36 -9 -49 -26z"
      />
      <path
        d="M12165 1786 c-22 -34 -15 -75 16 -99 24 -17 30 -18 55 -6 66 32 53
121 -18 127 -30 3 -40 -1 -53 -22z"
      />
      <path
        d="M12456 1788 c-24 -34 -20 -73 11 -97 64 -51 138 46 79 105 -23 22
-72 18 -90 -8z"
      />
      <path
        d="M12751 1784 c-27 -34 -27 -55 2 -82 39 -37 48 -29 45 40 -3 70 -16
81 -47 42z"
      />
      <path
        d="M0 1465 c0 -54 1 -55 29 -55 22 0 31 6 41 30 10 25 10 34 -4 55 -10
16 -26 25 -41 25 -24 0 -25 -3 -25 -55z"
      />
      <path
        d="M267 1502 c-20 -22 -22 -63 -5 -80 21 -21 75 -15 92 10 21 30 20 44
-4 68 -25 25 -62 26 -83 2z"
      />
      <path
        d="M562 1497 c-12 -13 -22 -31 -22 -39 0 -48 86 -67 114 -26 21 30 20
44 -4 68 -27 27 -62 25 -88 -3z"
      />
      <path
        d="M847 1502 c-19 -21 -21 -45 -7 -73 8 -14 21 -19 48 -19 45 0 62 14
62 51 0 51 -70 78 -103 41z"
      />
      <path
        d="M1137 1502 c-19 -21 -21 -45 -7 -73 8 -14 21 -19 50 -19 58 0 80 50
40 90 -25 25 -62 26 -83 2z"
      />
      <path
        d="M1437 1502 c-18 -20 -22 -59 -8 -81 15 -23 71 -18 93 8 23 30 23 46
-2 71 -25 25 -62 26 -83 2z"
      />
      <path
        d="M1721 1496 c-18 -19 -22 -32 -17 -51 12 -49 90 -53 115 -6 15 28 14
38 -9 61 -27 27 -62 25 -89 -4z"
      />
      <path
        d="M2007 1502 c-36 -40 -11 -95 43 -95 22 0 39 7 52 22 24 30 23 37 -7
66 -29 30 -65 32 -88 7z"
      />
      <path
        d="M2297 1502 c-50 -56 30 -135 83 -82 16 16 20 29 15 48 -12 51 -66 69
-98 34z"
      />
      <path
        d="M2595 1500 c-26 -28 -13 -86 20 -94 49 -12 93 32 76 77 -16 41 -66
50 -96 17z"
      />
      <path
        d="M2880 1495 c-20 -22 -21 -27 -11 -54 30 -70 139 -29 112 42 -17 43
-66 50 -101 12z"
      />
      <path
        d="M3167 1502 c-23 -25 -21 -58 3 -82 26 -26 56 -25 84 3 30 30 25 70
-10 86 -35 16 -58 14 -77 -7z"
      />
      <path
        d="M3467 1502 c-23 -25 -21 -58 3 -82 39 -39 93 -16 93 40 0 54 -61 81
-96 42z"
      />
      <path
        d="M3757 1502 c-10 -10 -17 -28 -17 -40 0 -25 33 -62 54 -62 26 0 59 35
59 65 0 50 -63 74 -96 37z"
      />
      <path
        d="M4042 1497 c-19 -21 -21 -28 -11 -55 25 -73 119 -40 109 38 -5 42
-65 52 -98 17z"
      />
      <path
        d="M4328 1501 c-10 -10 -18 -27 -18 -39 0 -27 33 -62 58 -62 59 0 88 85
37 108 -35 16 -57 14 -77 -7z"
      />
      <path
        d="M4627 1502 c-23 -25 -21 -58 3 -82 39 -39 93 -16 93 40 0 54 -61 81
-96 42z"
      />
      <path
        d="M4917 1502 c-23 -25 -21 -58 3 -82 38 -38 90 -15 90 40 0 54 -59 80
-93 42z"
      />
      <path
        d="M5207 1502 c-33 -36 -12 -102 31 -102 32 0 62 30 62 60 0 54 -59 80
-93 42z"
      />
      <path
        d="M5498 1498 c-23 -29 -23 -53 2 -78 24 -24 48 -25 79 -4 19 14 22 23
17 52 -3 19 -11 39 -18 44 -24 15 -63 8 -80 -14z"
      />
      <path
        d="M5786 1501 c-42 -46 11 -119 68 -93 21 9 26 19 26 46 0 60 -57 88
-94 47z"
      />
      <path
        d="M6076 1501 c-42 -46 11 -119 68 -93 21 9 26 19 26 46 0 60 -57 88
-94 47z"
      />
      <path
        d="M6372 1504 c-40 -28 -21 -104 26 -104 53 0 82 69 44 103 -23 21 -41
21 -70 1z"
      />
      <path
        d="M6663 1496 c-24 -21 -26 -27 -18 -52 14 -39 55 -53 89 -30 32 20 35
64 6 89 -27 23 -44 21 -77 -7z"
      />
      <path
        d="M6952 1504 c-28 -19 -29 -57 -2 -84 24 -24 48 -25 78 -4 28 20 28 55
-1 82 -28 26 -45 27 -75 6z"
      />
      <path
        d="M7242 1504 c-28 -19 -29 -57 -2 -84 22 -22 42 -25 71 -10 39 22 14
110 -32 110 -8 0 -25 -7 -37 -16z"
      />
      <path
        d="M7542 1504 c-40 -28 -21 -104 26 -104 53 0 82 69 44 103 -23 21 -41
21 -70 1z"
      />
      <path
        d="M7828 1501 c-33 -26 -31 -71 5 -91 24 -13 30 -13 55 0 37 21 43 71
10 94 -29 21 -40 20 -70 -3z"
      />
      <path
        d="M8112 1504 c-34 -24 -30 -70 10 -94 31 -19 32 -19 60 1 36 26 37 61
2 88 -32 25 -43 26 -72 5z"
      />
      <path
        d="M8402 1504 c-34 -24 -30 -70 9 -93 29 -18 34 -18 55 -4 32 21 33 75
2 97 -12 9 -27 16 -33 16 -6 0 -21 -7 -33 -16z"
      />
      <path
        d="M8702 1504 c-33 -23 -31 -80 4 -96 60 -28 116 58 62 96 -28 20 -38
20 -66 0z"
      />
      <path
        d="M8992 1504 c-34 -23 -31 -79 5 -98 25 -13 30 -12 55 6 35 25 38 70 6
92 -12 9 -27 16 -33 16 -6 0 -21 -7 -33 -16z"
      />
      <path
        d="M9272 1504 c-33 -23 -27 -73 11 -94 24 -13 30 -13 55 0 38 22 41 61
6 89 -32 25 -42 26 -72 5z"
      />
      <path
        d="M9572 1504 c-54 -38 2 -124 62 -96 35 16 37 73 4 96 -28 20 -38 20
-66 0z"
      />
      <path
        d="M9862 1504 c-15 -10 -22 -25 -22 -48 0 -28 5 -36 30 -46 41 -17 80 7
80 50 0 28 -29 60 -55 60 -6 0 -21 -7 -33 -16z"
      />
      <path
        d="M10152 1504 c-34 -23 -31 -79 5 -98 25 -13 30 -12 55 6 35 25 38 70
6 92 -12 9 -27 16 -33 16 -6 0 -21 -7 -33 -16z"
      />
      <path
        d="M10433 1503 c-35 -24 -31 -69 9 -93 31 -19 32 -19 60 1 34 25 37 60
6 89 -26 24 -45 25 -75 3z"
      />
      <path
        d="M10732 1504 c-34 -24 -30 -70 9 -93 29 -18 34 -18 55 -4 32 21 33 75
2 97 -12 9 -27 16 -33 16 -6 0 -21 -7 -33 -16z"
      />
      <path
        d="M11022 1504 c-43 -30 -16 -104 39 -104 23 0 49 33 49 63 0 25 -31 57
-55 57 -6 0 -21 -7 -33 -16z"
      />
      <path
        d="M11312 1504 c-33 -23 -31 -80 4 -96 60 -28 116 58 62 96 -28 20 -38
20 -66 0z"
      />
      <path
        d="M11613 1508 c-42 -20 -41 -74 0 -98 59 -33 115 43 66 89 -25 23 -34
25 -66 9z"
      />
      <path
        d="M11892 1504 c-27 -19 -29 -64 -4 -87 56 -51 127 23 76 78 -27 29 -41
31 -72 9z"
      />
      <path
        d="M12182 1504 c-41 -28 -18 -104 32 -104 54 0 76 60 36 100 -24 24 -38
25 -68 4z"
      />
      <path
        d="M12472 1504 c-25 -17 -31 -71 -10 -92 29 -29 98 1 98 44 0 26 -31 64
-53 64 -7 0 -23 -7 -35 -16z"
      />
      <path
        d="M12766 1499 c-30 -24 -34 -59 -8 -82 33 -30 42 -21 42 43 0 33 -2 60
-4 60 -2 0 -15 -9 -30 -21z"
      />
      <path
        d="M0 1170 c0 -54 10 -61 48 -34 40 28 23 84 -25 84 -21 0 -23 -4 -23
-50z"
      />
      <path
        d="M272 1208 c-26 -26 -3 -88 31 -88 43 0 73 60 45 88 -15 15 -61 15
-76 0z"
      />
      <path
        d="M562 1208 c-17 -17 -15 -42 7 -69 16 -20 23 -22 46 -14 39 14 52 37
37 69 -9 20 -19 26 -45 26 -18 0 -38 -5 -45 -12z"
      />
      <path
        d="M852 1208 c-28 -28 -3 -88 37 -88 15 0 61 34 61 45 0 2 -5 16 -12 30
-8 19 -19 25 -43 25 -17 0 -36 -5 -43 -12z"
      />
      <path
        d="M1142 1208 c-17 -17 -15 -53 4 -72 20 -21 37 -20 65 3 20 16 22 23
14 47 -8 22 -18 30 -40 32 -17 2 -36 -3 -43 -10z"
      />
      <path
        d="M1442 1208 c-17 -17 -15 -53 4 -72 20 -21 37 -20 65 3 20 16 22 23
14 47 -8 22 -18 30 -40 32 -17 2 -36 -3 -43 -10z"
      />
      <path
        d="M1722 1208 c-7 -7 -12 -24 -12 -38 0 -36 39 -58 75 -42 32 15 41 37
27 68 -9 18 -19 24 -45 24 -18 0 -38 -5 -45 -12z"
      />
      <path
        d="M2012 1208 c-20 -20 -15 -66 9 -78 49 -27 103 20 78 66 -14 27 -65
34 -87 12z"
      />
      <path
        d="M2302 1208 c-7 -7 -12 -25 -12 -40 0 -40 39 -58 75 -34 27 18 31 36
15 67 -12 22 -59 26 -78 7z"
      />
      <path
        d="M2601 1206 c-21 -26 -10 -69 22 -80 23 -8 31 -6 49 12 18 18 20 26
12 49 -7 21 -17 29 -40 31 -18 2 -36 -3 -43 -12z"
      />
      <path
        d="M2886 1204 c-26 -67 42 -117 80 -59 25 39 6 75 -40 75 -22 0 -36 -6
-40 -16z"
      />
      <path
        d="M3172 1208 c-19 -19 -15 -66 7 -78 32 -17 60 -11 77 15 24 37 3 75
-41 75 -17 0 -36 -5 -43 -12z"
      />
      <path
        d="M3472 1208 c-27 -27 -3 -88 34 -88 18 0 54 36 54 54 0 37 -61 61 -88
34z"
      />
      <path
        d="M3762 1208 c-27 -27 -3 -88 34 -88 18 0 54 36 54 54 0 37 -61 61 -88
34z"
      />
      <path
        d="M4052 1208 c-19 -19 -15 -66 7 -78 11 -5 24 -10 30 -10 16 0 51 38
51 54 0 37 -61 61 -88 34z"
      />
      <path
        d="M4332 1208 c-7 -7 -12 -24 -12 -40 0 -53 79 -63 102 -13 9 21 8 28
-7 45 -20 22 -64 27 -83 8z"
      />
      <path
        d="M4632 1208 c-22 -22 -15 -67 12 -80 31 -14 53 -5 68 27 9 21 8 28 -7
45 -20 22 -55 26 -73 8z"
      />
      <path
        d="M4922 1208 c-22 -22 -15 -67 12 -80 31 -14 53 -5 68 27 9 21 8 28 -7
45 -20 22 -55 26 -73 8z"
      />
      <path
        d="M5212 1208 c-19 -19 -15 -66 7 -78 31 -16 49 -12 67 15 15 23 15 27
0 50 -18 27 -54 33 -74 13z"
      />
      <path
        d="M5502 1208 c-19 -19 -15 -66 7 -78 32 -17 60 -11 77 15 15 23 15 27
0 50 -18 27 -63 34 -84 13z"
      />
      <path
        d="M5791 1206 c-22 -26 -9 -70 23 -81 24 -8 31 -6 47 14 20 24 18 59 -5
73 -18 12 -53 9 -65 -6z"
      />
      <path
        d="M6082 1208 c-19 -19 -15 -66 7 -78 31 -16 49 -12 67 15 15 23 15 27
0 50 -18 27 -54 33 -74 13z"
      />
      <path
        d="M6372 1208 c-7 -7 -12 -24 -12 -38 0 -53 57 -69 86 -25 15 23 15 27
0 50 -18 27 -54 33 -74 13z"
      />
      <path
        d="M6662 1208 c-7 -7 -12 -23 -12 -36 0 -53 67 -72 96 -27 15 23 15 27
0 50 -18 27 -63 34 -84 13z"
      />
      <path
        d="M6952 1208 c-7 -7 -12 -24 -12 -38 0 -14 5 -31 12 -38 34 -34 107 11
86 53 -19 36 -62 47 -86 23z"
      />
      <path
        d="M7242 1208 c-16 -16 -15 -63 2 -78 39 -32 100 23 72 65 -16 24 -56
31 -74 13z"
      />
      <path
        d="M7542 1208 c-16 -16 -15 -63 2 -78 39 -32 100 23 72 65 -16 24 -56
31 -74 13z"
      />
      <path
        d="M7820 1201 c-22 -42 -2 -81 42 -81 31 0 48 18 48 50 0 14 -5 31 -12
38 -19 19 -66 15 -78 -7z"
      />
      <path
        d="M8112 1208 c-15 -15 -16 -60 0 -76 16 -16 63 -15 77 2 8 10 9 25 3
48 -7 27 -14 34 -38 36 -16 2 -35 -3 -42 -10z"
      />
      <path
        d="M8402 1208 c-7 -7 -12 -24 -12 -38 0 -31 17 -50 45 -50 28 0 45 19
45 50 0 31 -17 50 -45 50 -12 0 -26 -5 -33 -12z"
      />
      <path
        d="M8702 1208 c-7 -7 -12 -24 -12 -38 0 -31 17 -50 45 -50 28 0 45 19
45 50 0 31 -17 50 -45 50 -12 0 -26 -5 -33 -12z"
      />
      <path
        d="M8985 1203 c-19 -44 -3 -83 34 -83 34 0 51 17 51 50 0 14 -5 31 -12
38 -18 18 -65 15 -73 -5z"
      />
      <path
        d="M9272 1208 c-7 -7 -12 -24 -12 -38 0 -33 17 -50 50 -50 33 0 50 17
50 50 0 14 -5 31 -12 38 -15 15 -61 15 -76 0z"
      />
      <path
        d="M9572 1208 c-7 -7 -12 -24 -12 -38 0 -31 17 -50 45 -50 28 0 45 19
45 50 0 31 -17 50 -45 50 -12 0 -26 -5 -33 -12z"
      />
      <path
        d="M9854 1195 c-15 -23 -15 -27 0 -50 29 -44 86 -28 86 25 0 53 -57 69
-86 25z"
      />
      <path
        d="M10144 1199 c-17 -19 -17 -23 -3 -47 27 -49 89 -37 89 17 0 51 -51
69 -86 30z"
      />
      <path
        d="M10424 1195 c-15 -23 -15 -27 0 -50 11 -17 26 -25 44 -25 35 0 52 16
52 50 0 34 -17 50 -52 50 -18 0 -33 -8 -44 -25z"
      />
      <path
        d="M10724 1195 c-15 -23 -15 -27 0 -50 29 -44 86 -28 86 25 0 53 -57 69
-86 25z"
      />
      <path
        d="M11014 1195 c-15 -23 -15 -27 0 -50 29 -44 86 -28 86 25 0 53 -57 69
-86 25z"
      />
      <path
        d="M11304 1195 c-15 -23 -15 -27 0 -50 29 -44 86 -28 86 25 0 53 -57 69
-86 25z"
      />
      <path
        d="M11594 1195 c-15 -23 -15 -27 0 -50 11 -17 26 -25 44 -25 35 0 52 16
52 50 0 34 -17 50 -52 50 -18 0 -33 -8 -44 -25z"
      />
      <path
        d="M11884 1195 c-15 -23 -15 -27 0 -50 31 -47 95 -22 87 34 -6 47 -60
57 -87 16z"
      />
      <path
        d="M12174 1195 c-15 -23 -15 -27 0 -50 29 -44 86 -28 86 25 0 53 -57 69
-86 25z"
      />
      <path
        d="M12464 1195 c-15 -23 -15 -27 0 -50 29 -44 86 -28 86 25 0 53 -57 69
-86 25z"
      />
      <path
        d="M12760 1201 c-14 -27 -13 -50 4 -67 26 -25 36 -15 36 36 0 54 -19 69
-40 31z"
      />
      <path d="M7 923 c-11 -11 -8 -83 4 -83 24 0 49 22 49 43 0 35 -33 60 -53 40z" />
      <path
        d="M276 914 c-20 -20 -20 -27 3 -55 16 -21 22 -22 45 -11 32 14 36 56 7
72 -27 14 -37 13 -55 -6z"
      />
      <path
        d="M578 919 c-39 -22 -4 -84 40 -72 31 8 41 52 17 70 -22 15 -34 16 -57
2z"
      />
      <path
        d="M859 909 c-18 -18 -19 -23 -6 -42 19 -29 44 -33 68 -11 13 12 17 24
13 38 -13 40 -43 47 -75 15z"
      />
      <path
        d="M1149 909 c-18 -18 -19 -23 -6 -42 28 -43 77 -28 77 23 0 25 -15 40
-41 40 -5 0 -18 -9 -30 -21z"
      />
      <path
        d="M1446 914 c-20 -19 -20 -24 0 -52 25 -36 74 -23 74 19 0 21 -26 49
-45 49 -7 0 -21 -7 -29 -16z"
      />
      <path
        d="M1735 914 c-16 -17 -12 -53 9 -66 45 -30 89 28 50 66 -20 20 -38 20
-59 0z"
      />
      <path
        d="M2021 911 c-41 -41 16 -95 59 -56 20 18 17 42 -8 63 -21 16 -29 15
-51 -7z"
      />
      <path
        d="M2311 911 c-25 -25 -17 -59 15 -67 14 -4 31 -1 39 6 21 18 19 57 -4
70 -26 13 -28 13 -50 -9z"
      />
      <path
        d="M2607 912 c-22 -24 -11 -61 19 -68 59 -15 76 62 18 80 -14 4 -26 0
-37 -12z"
      />
      <path
        d="M2896 914 c-27 -26 -5 -73 35 -74 31 0 50 39 31 66 -13 21 -49 25
-66 8z"
      />
      <path
        d="M3181 911 c-41 -41 16 -95 59 -56 26 23 25 40 -2 59 -29 20 -34 20
-57 -3z"
      />
      <path
        d="M3492 923 c-41 -16 -32 -83 11 -83 29 0 47 18 47 47 0 18 -28 46 -42
42 -2 0 -9 -3 -16 -6z"
      />
      <path
        d="M3765 906 c-21 -31 -5 -66 28 -66 47 0 63 48 25 74 -30 21 -35 20
-53 -8z"
      />
      <path
        d="M4056 914 c-9 -8 -16 -20 -16 -25 0 -24 22 -49 43 -49 47 0 63 48 25
74 -28 20 -33 20 -52 0z"
      />
      <path
        d="M4339 909 c-18 -18 -19 -23 -6 -42 19 -29 44 -33 68 -11 25 22 24 39
-3 58 -29 21 -34 20 -59 -5z"
      />
      <path
        d="M4652 923 c-41 -16 -32 -83 11 -83 29 0 47 18 47 47 0 18 -28 46 -42
42 -2 0 -9 -3 -16 -6z"
      />
      <path
        d="M4925 906 c-21 -31 -5 -66 28 -66 47 0 63 48 25 74 -30 21 -35 20
-53 -8z"
      />
      <path
        d="M5215 914 c-18 -19 -19 -35 -2 -58 27 -37 96 2 71 41 -16 26 -51 35
-69 17z"
      />
      <path
        d="M5506 908 c-13 -19 -14 -25 -2 -43 18 -27 43 -31 67 -9 31 28 21 53
-28 68 -16 5 -26 1 -37 -16z"
      />
      <path
        d="M7553 921 c-34 -22 -18 -81 22 -81 30 0 50 32 36 59 -11 20 -43 32
-58 22z"
      />
      <path
        d="M7827 906 c-12 -18 -12 -24 0 -43 19 -29 59 -30 73 -3 24 46 -45 89
-73 46z"
      />
      <path
        d="M8114 905 c-18 -28 -9 -53 24 -62 33 -8 67 29 52 57 -15 28 -59 32
-76 5z"
      />
      <path
        d="M8404 904 c-12 -17 -13 -27 -4 -43 13 -26 56 -28 69 -3 26 50 -35 93
-65 46z"
      />
      <path
        d="M8704 905 c-12 -18 -13 -28 -4 -44 14 -27 56 -28 70 -1 24 46 -38 88
-66 45z"
      />
      <path
        d="M9276 908 c-33 -47 29 -93 66 -49 22 28 10 56 -27 63 -18 4 -30 0
-39 -14z"
      />
      <path
        d="M9574 904 c-12 -17 -13 -27 -4 -43 14 -27 56 -27 70 -1 26 49 -36 91
-66 44z"
      />
      <path
        d="M9864 905 c-12 -18 -13 -28 -4 -44 14 -27 56 -28 70 -1 24 46 -38 88
-66 45z"
      />
      <path
        d="M10155 913 c-18 -17 -19 -28 -5 -54 14 -25 56 -25 70 1 22 41 -33 85
-65 53z"
      />
      <path
        d="M10435 907 c-12 -18 -13 -24 -1 -42 19 -28 51 -31 70 -4 19 28 7 55
-29 61 -18 4 -30 -1 -40 -15z"
      />
      <path
        d="M10734 904 c-12 -17 -13 -27 -4 -43 13 -26 56 -28 69 -3 26 50 -35
93 -65 46z"
      />
      <path
        d="M11023 903 c-20 -31 -8 -57 30 -61 48 -6 56 68 8 81 -16 4 -26 -2
-38 -20z"
      />
      <path
        d="M11315 914 c-32 -32 -8 -79 37 -72 19 2 23 9 23 37 0 41 -34 61 -60
35z"
      />
      <path
        d="M11603 904 c-14 -23 -14 -26 2 -44 21 -24 48 -26 65 -6 20 25 8 59
-23 67 -23 6 -30 3 -44 -17z"
      />
      <path
        d="M11893 903 c-20 -30 -8 -56 26 -61 32 -5 48 13 43 47 -6 41 -47 49
-69 14z"
      />
      <path
        d="M12186 911 c-31 -34 -10 -76 36 -69 19 2 23 9 23 37 0 42 -33 60 -59
32z"
      />
      <path
        d="M12477 918 c-21 -11 -22 -51 -3 -68 8 -6 25 -10 38 -8 19 2 23 9 23
37 0 38 -28 56 -58 39z"
      />
      <path
        d="M5790 900 c-15 -28 4 -60 36 -60 27 0 51 37 41 64 -9 24 -64 21 -77
-4z"
      />
      <path
        d="M6080 900 c-16 -31 4 -62 38 -58 33 4 56 53 32 68 -25 16 -59 11 -70
-10z"
      />
      <path
        d="M6365 900 c-17 -52 54 -85 77 -35 8 19 8 28 -2 40 -18 22 -67 19 -75
-5z"
      />
      <path
        d="M6664 903 c-16 -25 10 -63 42 -63 31 0 53 42 34 65 -17 20 -63 19
-76 -2z"
      />
      <path
        d="M6950 900 c-15 -28 4 -60 36 -60 27 0 51 37 41 64 -9 24 -64 21 -77
-4z"
      />
      <path
        d="M7240 900 c-16 -31 4 -62 38 -58 33 4 56 53 32 68 -25 16 -59 11 -70
-10z"
      />
      <path
        d="M8984 903 c-9 -14 -8 -23 4 -40 30 -47 98 -12 72 37 -13 25 -61 27
-76 3z"
      />
      <path
        d="M12762 908 c-17 -17 -15 -32 6 -51 23 -21 32 -13 32 30 0 34 -16 43
-38 21z"
      />
      <path
        d="M0 590 c0 -33 3 -41 16 -38 9 2 22 11 28 21 13 20 -2 50 -28 55 -13
3 -16 -5 -16 -38z"
      />
      <path
        d="M278 605 c-9 -21 -9 -29 4 -41 13 -13 19 -14 37 -2 24 15 27 34 7 54
-21 21 -35 17 -48 -11z"
      />
      <path
        d="M575 610 c-18 -20 -18 -21 2 -41 18 -18 23 -19 42 -8 26 17 27 31 3
52 -23 21 -25 21 -47 -3z"
      />
      <path
        d="M865 610 c-18 -20 -18 -21 3 -42 17 -18 23 -19 36 -8 19 15 21 41 4
58 -16 16 -22 15 -43 -8z"
      />
      <path
        d="M1152 618 c-18 -18 -14 -42 9 -56 36 -23 68 25 37 56 -15 15 -31 15
-46 0z"
      />
      <path
        d="M1452 618 c-30 -30 0 -78 37 -58 32 17 21 70 -14 70 -6 0 -16 -5 -23
-12z"
      />
      <path
        d="M1742 618 c-26 -26 0 -77 33 -64 25 9 29 35 10 56 -21 23 -27 24 -43
8z"
      />
      <path
        d="M2025 610 c-18 -20 -18 -21 3 -43 38 -37 78 5 44 46 -19 22 -25 21
-47 -3z"
      />
      <path
        d="M2318 616 c-10 -7 -18 -20 -18 -28 0 -18 44 -43 59 -34 15 9 14 49
-1 64 -15 15 -19 15 -40 -2z"
      />
      <path
        d="M2618 619 c-33 -19 -15 -69 24 -69 22 0 35 45 18 65 -15 17 -19 18
-42 4z"
      />
      <path
        d="M2908 619 c-35 -19 -9 -79 27 -65 25 9 29 36 9 56 -14 14 -23 16 -36
9z"
      />
      <path
        d="M3189 611 l-24 -20 22 -22 c13 -13 28 -19 37 -16 27 10 31 37 9 58
-19 20 -20 20 -44 0z"
      />
      <path
        d="M3488 616 c-10 -7 -18 -18 -18 -23 0 -18 39 -46 55 -40 18 7 20 48 3
65 -15 15 -19 15 -40 -2z"
      />
      <path
        d="M3778 619 c-33 -19 -15 -69 24 -69 22 0 35 45 18 65 -15 17 -19 18
-42 4z"
      />
      <path
        d="M4065 616 c-20 -15 -17 -45 7 -59 20 -13 48 6 48 32 0 26 -34 43 -55
27z"
      />
      <path
        d="M4349 611 l-24 -20 22 -22 c13 -13 28 -19 37 -16 27 10 31 37 9 58
-19 20 -20 20 -44 0z"
      />
      <path
        d="M4935 616 c-26 -19 -13 -66 19 -66 37 0 50 52 17 69 -13 7 -23 6 -36
-3z"
      />
      <path
        d="M6096 615 c-27 -20 -14 -65 18 -65 37 0 50 52 18 69 -12 6 -23 5 -36
-4z"
      />
      <path
        d="M4641 606 c-18 -22 4 -58 32 -54 31 4 32 62 1 66 -11 2 -26 -4 -33
-12z"
      />
      <path
        d="M5215 609 c-13 -20 4 -59 25 -59 27 0 50 47 29 61 -19 12 -46 11 -54
-2z"
      />
      <path
        d="M5511 606 c-16 -20 3 -56 29 -56 26 0 45 36 29 56 -6 8 -19 14 -29
14 -10 0 -23 -6 -29 -14z"
      />
      <path
        d="M5801 606 c-9 -11 -10 -20 -1 -35 13 -26 38 -27 55 -4 27 37 -26 73
-54 39z"
      />
      <path
        d="M6375 599 c-5 -14 -1 -27 9 -37 14 -14 17 -14 36 3 24 22 26 41 4 49
-28 11 -43 6 -49 -15z"
      />
      <path
        d="M6671 601 c-8 -14 -6 -22 8 -35 24 -21 38 -20 51 5 9 15 8 24 -1 35
-16 20 -46 18 -58 -5z"
      />
      <path
        d="M6961 601 c-8 -14 -6 -23 10 -37 21 -18 21 -18 40 5 15 18 17 27 8
37 -16 20 -47 17 -58 -5z"
      />
      <path
        d="M7245 599 c-7 -21 16 -48 41 -49 16 0 26 28 19 51 -9 27 -52 25 -60
-2z"
      />
      <path d="M7545 601 c-8 -26 4 -51 26 -51 22 0 38 26 32 51 -7 25 -50 25 -58 0z" />
      <path
        d="M7835 601 c-8 -26 4 -51 26 -51 24 0 40 30 28 53 -13 23 -46 22 -54
-2z"
      />
      <path
        d="M8121 602 c-21 -39 32 -72 55 -34 9 14 9 22 -1 35 -16 22 -42 22 -54
-1z"
      />
      <path
        d="M8411 606 c-9 -11 -10 -20 -1 -35 22 -42 69 -15 55 30 -7 22 -38 25
-54 5z"
      />
      <path
        d="M8705 601 c-9 -28 5 -51 30 -51 24 0 38 29 28 56 -8 21 -51 17 -58
-5z"
      />
      <path
        d="M8995 601 c-8 -26 4 -51 26 -51 24 0 40 30 28 53 -13 23 -46 22 -54
-2z"
      />
      <path
        d="M9281 602 c-21 -39 32 -72 55 -34 9 14 9 22 -1 35 -16 22 -42 22 -54
-1z"
      />
      <path
        d="M9581 602 c-12 -23 4 -52 28 -52 22 0 34 25 26 51 -8 24 -41 25 -54
1z"
      />
      <path
        d="M9865 601 c-9 -28 5 -51 30 -51 24 0 38 29 28 56 -8 21 -51 17 -58
-5z"
      />
      <path
        d="M10154 596 c-7 -31 1 -46 26 -46 25 0 41 30 29 53 -15 26 -48 21 -55
-7z"
      />
      <path
        d="M10440 599 c-21 -39 29 -68 55 -32 17 22 2 53 -25 53 -11 0 -24 -9
-30 -21z"
      />
      <path
        d="M10740 599 c-9 -15 -8 -24 1 -35 16 -19 45 -18 53 2 17 45 -32 75
-54 33z"
      />
      <path
        d="M11032 608 c-26 -26 -8 -58 33 -58 19 0 29 38 15 55 -15 18 -32 19
-48 3z"
      />
      <path
        d="M11314 606 c-16 -42 19 -71 50 -41 13 13 13 20 3 35 -14 23 -45 26
-53 6z"
      />
      <path
        d="M11609 604 c-28 -33 24 -69 55 -38 16 15 16 18 0 35 -21 23 -37 24
-55 3z"
      />
      <path
        d="M11899 604 c-17 -20 0 -49 29 -50 26 -1 39 31 22 51 -16 19 -34 19
-51 -1z"
      />
      <path
        d="M12192 611 c-18 -11 -11 -55 8 -59 9 -1 24 4 35 12 16 12 18 18 9 33
-13 21 -33 27 -52 14z"
      />
      <path
        d="M12477 613 c-11 -10 -8 -51 3 -58 15 -9 47 3 54 21 10 27 -37 58 -57
37z"
      />
      <path
        d="M12773 607 c-11 -14 -11 -27 0 -45 12 -18 27 -5 27 23 0 28 -14 40
-27 22z"
      />
      <path
        d="M0 305 c0 -20 4 -26 18 -23 9 2 17 12 17 23 0 11 -8 21 -17 23 -14 3
-18 -3 -18 -23z"
      />
      <path d="M286 314 c-4 -10 -2 -22 3 -27 14 -14 43 0 39 20 -4 24 -34 28 -42 7z" />
      <path
        d="M585 310 c-16 -18 -16 -20 -1 -26 22 -8 46 2 46 20 0 23 -27 26 -45
6z"
      />
      <path
        d="M871 316 c-16 -19 -6 -36 20 -36 15 0 20 6 17 22 -4 27 -21 33 -37
14z"
      />
      <path
        d="M1160 315 c-10 -11 -10 -19 -2 -27 15 -15 44 -2 40 19 -4 23 -22 27
-38 8z"
      />
      <path
        d="M1460 315 c-10 -11 -10 -19 -2 -27 15 -15 44 -2 40 19 -4 23 -22 27
-38 8z"
      />
      <path
        d="M1752 318 c-20 -20 -14 -40 11 -36 14 2 22 10 22 22 0 24 -16 31 -33
14z"
      />
      <path
        d="M2036 314 c-19 -19 -12 -34 15 -34 12 0 19 7 19 19 0 27 -15 34 -34
15z"
      />
      <path
        d="M2320 315 c-10 -11 -10 -19 -2 -27 15 -15 44 -2 40 19 -4 23 -22 27
-38 8z"
      />
      <path
        d="M2620 315 c-10 -11 -10 -19 -2 -27 15 -15 44 -2 40 19 -4 23 -22 27
-38 8z"
      />
      <path
        d="M2912 318 c-20 -20 -14 -40 11 -36 14 2 22 10 22 22 0 24 -16 31 -33
14z"
      />
      <path
        d="M3196 315 c-19 -19 -13 -35 14 -35 23 0 33 25 15 40 -10 8 -17 7 -29
-5z"
      />
      <path
        d="M3490 315 c-10 -11 -10 -19 -2 -27 15 -15 44 -2 40 19 -4 23 -22 27
-38 8z"
      />
      <path
        d="M3779 314 c-9 -11 -9 -18 -1 -26 14 -14 42 -3 42 16 0 20 -27 27 -41
10z"
      />
      <path
        d="M4073 323 c-7 -3 -13 -14 -13 -25 0 -14 6 -19 22 -16 13 2 24 10 26
19 3 18 -15 29 -35 22z"
      />
      <path
        d="M4650 320 c-18 -11 -4 -40 20 -40 13 0 20 7 20 19 0 22 -21 33 -40
21z"
      />
      <path
        d="M4940 320 c-18 -11 -4 -42 17 -38 10 2 19 10 21 19 4 19 -19 31 -38
19z"
      />
      <path
        d="M5230 320 c-18 -11 -4 -42 17 -38 10 2 19 10 21 19 4 19 -19 31 -38
19z"
      />
      <path
        d="M5523 315 c-13 -10 -15 -17 -7 -25 16 -16 48 -9 52 11 4 23 -22 31
-45 14z"
      />
      <path
        d="M5810 315 c-15 -18 -5 -35 21 -35 12 0 19 7 19 19 0 24 -25 34 -40
16z"
      />
      <path
        d="M4345 310 c-4 -7 -3 -16 3 -22 14 -14 47 -6 47 12 0 18 -40 26 -50
10z"
      />
      <path
        d="M6095 310 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"
      />
      <path
        d="M6385 310 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"
      />
      <path
        d="M6680 306 c-10 -13 -9 -17 4 -22 22 -8 49 3 44 19 -7 18 -33 20 -48
3z"
      />
      <path d="M6965 311 c-9 -16 3 -31 26 -31 14 0 19 6 17 17 -4 20 -33 29 -43 14z" />
      <path
        d="M7255 310 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"
      />
      <path
        d="M7555 310 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"
      />
      <path
        d="M7847 314 c-16 -16 -6 -35 16 -32 12 2 22 10 22 18 0 16 -26 25 -38
14z"
      />
      <path d="M8127 306 c-7 -18 1 -26 25 -26 13 0 18 6 16 17 -4 22 -34 29 -41 9z" />
      <path
        d="M8415 310 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"
      />
      <path
        d="M8715 310 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"
      />
      <path
        d="M9007 314 c-16 -16 -6 -35 16 -32 12 2 22 10 22 18 0 16 -26 25 -38
14z"
      />
      <path d="M9286 305 c-7 -18 16 -30 40 -21 13 5 14 9 4 21 -16 19 -36 19 -44 0z" />
      <path
        d="M9585 310 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"
      />
      <path
        d="M9875 310 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"
      />
      <path
        d="M10165 310 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"
      />
      <path
        d="M10446 305 c-7 -18 16 -30 40 -21 13 5 14 9 4 21 -16 19 -36 19 -44
0z"
      />
      <path
        d="M10745 311 c-9 -16 3 -31 26 -31 14 0 19 6 17 17 -4 20 -33 29 -43
14z"
      />
      <path
        d="M11035 310 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"
      />
      <path
        d="M11325 310 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"
      />
      <path
        d="M11620 305 c-10 -12 -9 -16 4 -21 9 -3 23 -3 32 0 13 5 14 9 4 21
-16 19 -24 19 -40 0z"
      />
      <path
        d="M11905 310 c-9 -15 4 -30 27 -30 14 0 18 5 14 16 -3 9 -6 18 -6 20 0
8 -29 4 -35 -6z"
      />
      <path
        d="M12194 309 c-9 -15 11 -33 30 -26 9 4 13 13 10 22 -7 17 -30 20 -40
4z"
      />
      <path
        d="M12484 309 c-9 -15 11 -33 30 -26 9 4 13 13 10 22 -7 17 -30 20 -40
4z"
      />
      <path
        d="M12782 307 c-9 -11 -10 -17 -2 -22 15 -9 23 -1 18 21 -3 15 -4 16
-16 1z"
      />
      <path d="M10 14 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
      <path d="M300 14 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
      <path
        d="M590 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
      />
      <path
        d="M880 9 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10 -5
-10 -11z"
      />
      <path
        d="M1170 9 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10 -5
-10 -11z"
      />
      <path
        d="M1470 9 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10 -5
-10 -11z"
      />
      <path
        d="M1755 10 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"
      />
      <path d="M2035 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path d="M2325 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path d="M2625 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path d="M2915 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path
        d="M3200 9 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10 -5
-10 -11z"
      />
      <path d="M3495 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path d="M3785 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path d="M4075 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path
        d="M4360 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path d="M4655 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path d="M4945 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path d="M5235 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path
        d="M5530 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path d="M5815 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path d="M6105 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path d="M6395 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path
        d="M6690 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path d="M6975 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path d="M7265 10 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
      <path
        d="M7560 10 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0 -16
-4 -16 -10z"
      />
      <path
        d="M7850 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M8130 10 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0 -16
-4 -16 -10z"
      />
      <path
        d="M8420 10 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0 -16
-4 -16 -10z"
      />
      <path
        d="M8720 10 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0 -16
-4 -16 -10z"
      />
      <path
        d="M9010 10 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0 -16
-4 -16 -10z"
      />
      <path
        d="M9290 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M9590 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M9880 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M10170 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M10450 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M10750 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M11040 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M11330 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M11630 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M11910 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M12200 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M12490 10 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
    </g>
  </svg>
</template>

<script setup></script>
